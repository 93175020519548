import React from 'react';
import ImgSrcset from '../../../../component/General/imgSrcset/imgSrcset'
import Intersection from '../../../../component/General/Intersection/Intersection'

import ORANGEWORK from '../../../../content/works/orange/orangeWorkPart3'

const OrangePart3 = () => {
    return (
        <div>
        <section className="orange-screen" id="orange-screen">
        <Intersection>
            <div className="orange-screen__wrapper">
                    <h3 className="orange-screen__wrapper__title" >{ORANGEWORK.screen.title}</h3>
                    <div className='orange-screen__wrapper__content'>
                    {ORANGEWORK.screen.img.images.map((item, index)=> {
                        return ( <ImgSrcset key={index}
                            conf={ORANGEWORK.screen.img.generalConf} 
                            specifiqueAnime='slide-bottom'
                            indexNum={index}
                            item={item} classLocation="orange-screen__wrapper__content__picture" />)
                    })}
                    </div>
            </div>
        </Intersection>
        </section>
        <section className="orange-errors" id="orange-errors">
        <Intersection>
            <div className="orange-errors__wrapper">
                <h3 className="orange-errors__wrapper__title" >{ORANGEWORK.errors.title}</h3>
                <div className='orange-errors__wrapper__content'>
                {ORANGEWORK.errors.img.images.map((item, index)=> {
                        return ( <ImgSrcset key={index}
                            conf={ORANGEWORK.errors.img.generalConf} 
                            item={item} classLocation="orange-errors__wrapper__content__picture" />)
                    })}
                </div>
            </div>
        </Intersection>
        </section>
        </div>
    )
}

export default OrangePart3;