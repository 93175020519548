


const ACCUEILINFOSTXT = {
    headerScreen: {
        words:['[portefolio de webdesigner] ', '[chantier d\'apprentissage JS] ', '[chantier d\'apprentissage JS]'],
        starItems: ['_ Ergonomie', '_ Design', '_ Interaction', '_ Animation'],
        title: 'Web <span></span><br/>design<span>.</span>',
        subTitle: {
            before:'Bienvenue sur mon ',
            starter: '[portefolio de webdesigner]',
            after:'Je vous souhaite une bonne visite.'
        },
        imgParallaxHome: {
            generalConf: {
                conf1: {
                    url: '/images/cover-brain/cover3iphone.png',
                    srcSetSize: '768w'
                },
                conf2: {
                    url: '/images/cover-brain/cover3ecran-large.png',
                    srcSetSize: '2365w'
                },
                conf3: {
                    url: '/images/cover-brain/cover3retina15.png',
                    srcSetSize: '2880w'
                }
            },
            img: '/images/cover-brain/cover3desktop.png',
            alt: ''
        }
    },
    presentation: {
        title:'présentation',
        subTitle:'Qui suis-je',
        words: {
            before:'Un intégrateur',
            allWords: ['Un webdesigner', 'Un peu intégrateur']
        },
        desc: "Passionnée par le design graphique, le numérique appliquées au design, je suis actuellement à la recherche de missions afin de mettre à profit mes connaissances. Curieuse de nature, je souhaite m’épanouir dans ce domaine qui me tient à coeur !",
        arrayItems : [
        //     {
        //     title: 'Content strategy', 
        //     component:'ContentStratIllu'
        // },
        // {
        //     title: 'Ergonomie', 
        //     component:'ErgonomieIllu'
        // },
        {
            title: 'Wireframing', 
            component:'WireframingIllu'
        },
        // {
        //     title: 'Animation & interaction', 
        //     component:'InteractionIllu'
        // },
        {
            title: 'Visual design', 
            component:'VisualIllu'
        },
        {
            title: 'Front Integration', 
            component:'IntegrationIllu'
        }
        ]
    },
    prestations: {
        title:'Prestation',
        subTitle:'Je propose...',
        desc: "J'interviens sur plusieurs phases de créations de projets : création et intégration.",
        descComment: 'intégration : HTML/CSS, Junior en JS - SASS - React.',
        img: '/images/icon-portofolio/steps.png',
        liPres: [
            {
                title: 'Design print',
                image: '/images/icon-portofolio/icon-print.png',
                comment:'Mettre en page, adapter des identités visuelles',
                list: ['Logotypes', 'Flyers', 'Kakémonos', 'Infographies']
            },{
                title: 'Design web',
                image: '/images/icon-portofolio/icon-web.png',
                comment:'Faire du design d\'interface, maquettes et bannières',
                list: ['Wireframes', 'Responsive design', 'Bannières']
            },{
                title: 'Integration web',
                image: '/images/icon-portofolio/icon-inteAnim.png',
                comment:'Inserer quelques ligne de code',
                list: ['HTML', 'CSS', 'JS', 'SASS']
            }
        ],
        callToAction: 'Me recruter :)'
    },
    works: {
        title:'In progress...',
        subTitle:'Mes travaux',
        liWorks : [
            {
                title: 'e_',
                title2: 'stories',
                linkTo: '/realisations-estories',
                image: {
                    large : '/images/icon-portofolio/estories/310X310-largeestories.png',
                    largex2 : '/images/icon-portofolio/estories/620X620-largeX2estories.png',
                    small : '/images/icon-portofolio/estories/216X216-smallestories.png',
                    smallx2 : '/images/icon-portofolio/estories/432X432-smallX2estories.png'
                },
                infos : 'Projet de design & gamification ayant pour but d\'apprendre à connaître Paris, son histoire et ses légendes, en se baladant grâce à la géolocalisation.', 
                infosPlus : 'Design UX & UI.'
            },{
                title: 'Projet ',
                title2: 'Orange',
                linkTo: '/realisations-orange',
                image: {
                    large : '/images/icon-portofolio/orange/310X310-largeorange.png',
                    largex2 : '/images/icon-portofolio/orange/620X620-largeX2orange.png',
                    small : '/images/icon-portofolio/orange/216X216-smallorange.png',
                    smallx2 : '/images/icon-portofolio/orange/432X432-smallX2orange.png'
                },
                infos : 'Participation à la création de Wireframes et design UI pour le site Orange Developer et le E-shop du groupe au sein de la division IMT (innovation/marketing/technologies)', 
                infosPlus : 'Design UX & UI.'
            },{
                title: 'Meow ',
                title2: 'Bio',
                linkTo: '/realisations-meowbio',
                image: {
                    large : '/images/icon-portofolio/meowbio/310X310-largemeowbio.png',
                    largex2 : '/images/icon-portofolio/meowbio/620X620-largeX2meowbio.png',
                    small : '/images/icon-portofolio/meowbio/216X216-smallmeowbio.png',
                    smallx2 : '/images/icon-portofolio/meowbio/432X432-smallX2meowbio.png'
                },
                infos : 'Projet de construction d\'un site e-commerce, proposant des produits bio pour les chats. À destination des fous de leur animal, le design du site se veut amusant.', 
                infosPlus : 'Design UX & UI.'
            }
            ]
        },
        skills: {
            title:'Skills',
            subTitle:'Mes compétences',
            itemsComp : ['Visual design', 'Responsive', 'Wireframing', 'Intégration'],
            itemsPercent : [
                {work: 'Illustrator', percent: 95},
                {work: 'Photoshop', percent: 95},
                {work: 'Sketch', percent: 75},
                {work: 'Indesign', percent: 60},
                {work: 'HTML/CSS', percent: 55},
                {work: 'invision', percent: 25},
                {work: 'JS', percent: 20},
                {work: 'After Effect', percent: 15}
            ]
        },
        cv: {
            title: 'CV',
            txt: 'Pour plus d\'informations vous pouvez consulter mon CV juste ici !',
            callToAction: 'Ouvrir le PDF',
            linkPDF: '/images/jeanne-l-CV-2020.pdf'
        }
    }

export default ACCUEILINFOSTXT;
