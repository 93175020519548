
import React from 'react';
import PropTypes from 'prop-types';

const SpanParser = ((props) => {
    const divStyle= {
        display:"inline"
    }

    const phraseText = props.phrase.split("<br>")
    /* eslint-disable no-alert, no-console*/
    const regex = /([a-zA-Zéçàèêôôöïîùë 0-9/!#$%&'*+\-/=?^_`{|}~]*)<span>(.*?)<\/span>([a-zA-Zéçàèêôôöïîùë0-9 /!#$%&'*+\-/=?^_`{|}~]*)|(^[a-zA-Zéçàèêôôöïîùë 0-9/!#$%&'*+\-/=?^_`{|}~]*)/g
    /* eslint-enable no-alert, no-console*/

    let arrText = []
    let arrTextTrim = []
    for(const line of phraseText) {
        const matches = line.matchAll(regex);
        for (const match of matches) {
          arrText.push(match)
        }
    }
    for (const arr of arrText) {
        arrTextTrim.push((arr.filter((el) => {
            return el !== undefined;
          })).slice(1, arr.length))
    }

    return (
        arrTextTrim.map((item, index)=> {
            return (
                <div key={index} style={divStyle}>
                    { 
                        item.map((phrase, index)=> {
                            return (index % 2 !== 0 ? (<span key={index}>{phrase}</span>) : (<div key={index} style={divStyle}>{phrase}</div>))
                        })
                    }
                    <br></br>
                </div>
            )
        })
    )
})

SpanParser.defaultProps = {
    // eslint-disable-next-line 
    phrase: "Phrase  avec<span> une span </span> <br> et\/ou <br> <span>br.</span>"
}

PropTypes.SpanParser = {
    phrase: PropTypes.string
}

export default SpanParser
