import React from 'react';
import PropTypes from 'prop-types'

class TextArea extends React.Component {

    render() {
        const {id, placeholder, locationName, required, type, label, error, value, minLen, maxLen} = this.props 
        return (
            <div className={`inputText ${locationName}`}>
                <label aria-label={label} htmlFor={id}>
                    <textarea {...(minLen ? {minLength: minLen} : {})} {...(maxLen ? {maxLength: maxLen} : {})} onChange={this.props.handleChange} required={required} type={type} id={id} name={id} placeholder={placeholder} className={`inputText__${id} ${locationName}__${id}`} value={value}></textarea>
                    {error && <div className={`inputText__error ${locationName}__error`}>{error}</div>}
                </label>
            </div>
        )
    }
}

TextArea.propTypes = {
    id: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    locationName: PropTypes.string,
    required: PropTypes.bool,
    type: PropTypes.string,
    label: PropTypes.string,
    error: PropTypes.string,
    value: PropTypes.string,
    minLen: PropTypes.number,
    maxLen: PropTypes.number
}

TextArea.defaultProps = {
    placeholder: "Ecrivez ici ",
    required : true,
    locationName: 'inputText',
    type: 'text',
    error: "Champ incorrect",
    maxLen: 0,
    minLen: 0
};

export default TextArea;
