// import Header from '../../component/General/Header/Header';
// import Competences from '../../component/Home/Competences/Competences'
// import Prestations from '../../component/Home/Prestations/Prestations';
// import Presentation from '../../component/Home/Presentation/Presentation';
import React, { Suspense }  from 'react';
import HomeScreen from '../../component/Home/HomeScreen/HomeScreen';
import Works from '../../component/Home/Works/Works';
import Cv from '../../component/General/CV/Cv'

import ACCUEILMENULINKS from '../../content/accueil/accueilMenuLinks'
import ACCUEILINFOSTXT from '../../content/accueil/accueilInfosTxt'

const Presentation = React.lazy(() => import('../../component/Home/Presentation/Presentation'));
const Prestations = React.lazy(() => import('../../component/Home/Prestations/Prestations'));
const Competences = React.lazy(()=> import('../../component/Home/Competences/Competences'));
const Header = React.lazy(()=> import('../../component/General/Header/Header'));

const Accueil = (props) => {
    const {generalSliderContent} = props
        return (
            <div id="home">
                <Suspense fallback={<div>Chargement...</div>}>
                    <Header menuLink={ACCUEILMENULINKS}></Header>
                </Suspense>
                <HomeScreen subTitleHome={ACCUEILINFOSTXT.headerScreen.subTitle} titleHome={ACCUEILINFOSTXT.headerScreen.title} imgParallaxHome={ACCUEILINFOSTXT.headerScreen.imgParallaxHome} wordsGsap={ACCUEILINFOSTXT.headerScreen.words} starItems={ACCUEILINFOSTXT.headerScreen.starItems}></HomeScreen>
                <Suspense fallback={<div>Chargement...</div>}>
                    <Presentation presentProp={ACCUEILINFOSTXT.presentation}/>
                </Suspense>
                <Suspense fallback={<div>Chargement...</div>}>
                    <Prestations prestaProp={ACCUEILINFOSTXT.prestations}/>
                </Suspense>
                <Works worksProp={ACCUEILINFOSTXT.works} generalSliderContent={generalSliderContent}></Works> 
                <Suspense fallback={<div>Chargement...</div>}>
                    <Competences skillsProp={ACCUEILINFOSTXT.skills}/>
                </Suspense>
                <Cv cvProp={ACCUEILINFOSTXT.cv}></Cv>
            </div>
        )
    }

export default Accueil;
