import React from 'react';
import PropTypes from 'prop-types';
import Intersection from "../../../component/General/Intersection/Intersection"
import Breadcrumb from "../Breadcrumb/BreadCrumb"
import ImgSrcset from '../../General/imgSrcset/imgSrcset'
// import Button from "../Button/Button"

class HeaderWork extends React.Component{
    render(){
        const {classLocation, workHeaderDesc, workName} = this.props;
        return (
            <section id={`header-work-${workName}`} className={`header-work ${classLocation}`}>
                <div className={`header-work__wrapper ${classLocation}__wrapper`}>
                        <Intersection classDiv={`header-work__wrapper__content ${classLocation}__wrapper__content`}>
                            <div className={`header-work__wrapper__content__infos ${classLocation}__wrapper__content__infos`} data-animate="slide-bottom" >
                                <h1 className={`header-work__wrapper__content__infos__title ${classLocation}__wrapper__content__infos__title`}><span>{workHeaderDesc.title.name1}</span>{workHeaderDesc.title.name2}</h1>
                                <p className={`header-work__wrapper__content__infos__text ${classLocation}__wrapper__content__infos__text`}>
                                    <strong>{workHeaderDesc.legend.title}{<br></br>}</strong>
                                    {/* <strong>{workHeaderDesc.legend.date}{<br></br>}</strong> */}
                                    {workHeaderDesc.legend.text}{<br></br>}
                                </p>
                                {/* <img src={workHeaderDesc.image.src} alt="" className={`header-work__wrapper__content__infos__image header-${classLocation}__wrapper__content__infos__image`}></img> */}
                                <ImgSrcset conf={workHeaderDesc.generalConf} item={workHeaderDesc.images[0]} classLocation={`header-work__wrapper__content__infos__image header-${classLocation}__wrapper__content__infos__image`} />
                            </div>
                        </Intersection>
                        <Intersection classDiv={`header-work__wrapper__breadcrumb ${classLocation}__wrapper__breadcrumb`}>
                            <Breadcrumb workName={workName}></Breadcrumb>
                        </Intersection>
                        <Intersection classDiv={`header-work__wrapper__subcontent ${classLocation}__wrapper__subcontent`}>
                        <div className={`header-work__wrapper__subcontent__infos ${classLocation}__wrapper__subcontent__infos`} data-animate="slide-bottom" >
                           { workHeaderDesc.description.map((item, index) => {
                               return(
                                    <div key={index} className={`header-work__wrapper__subcontent__infos__content ${classLocation}__wrapper__subcontent__infos__content`} data-animate="slide-bottom" >
                                        <h2 className={`header-work__wrapper__subcontent__infos__title ${classLocation}__wrapper__subcontent__infos__title`}>{item.title}</h2>
                                        <ul className={`header-work__wrapper__subcontent__infos__text ${classLocation}__wrapper__subcontent__infos__text`}>
                                        { item.text.map((item, index) => {
                                            return(
                                            <li key={index}>{item}</li>
                                            )
                                        })
                                    }
                                    </ul>

                                   </div>
                               )
                           })
                            }
                        </div>
                        </Intersection>
                        <Intersection classDiv={`header-work__wrapper__quote ${classLocation}__wrapper__quote`}>
                            <blockquote data-animate="slide-bottom">{workHeaderDesc.quote}</blockquote>
                        </Intersection>
                 </div>
            </section>
        )
    }
}

export default HeaderWork

HeaderWork.defaultProps = {
    classLocation:""
};

HeaderWork.propTypes = {
    classLocation: PropTypes.string,
    workHeaderDesc: PropTypes.object.isRequired,
};

