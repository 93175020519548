import React from 'react';
import SliderCard from '../SliderCard/SliderCard'
import PropTypes from 'prop-types';
import Intersection from '../Intersection/Intersection'


class Slider extends React.Component {
    constructor(props) {
        super(props)
        this.changeSlideBullet = this.changeSlideBullet.bind(this)
        this.slideRight = this.slideRight.bind(this)
        this.slideLeft = this.slideLeft.bind(this)
        this.startDrag = this.startDrag.bind(this)
        this.drag = this.drag.bind(this)
        this.endDrag = this.endDrag.bind(this)
        this.updateArrayWork = this.updateArrayWork.bind(this)
        this.state = {
            indexActive: 0,
            touchesX: 0,
            lastTranslate: 0,
            newGeneralSliderContent: this.props.generalSliderContent
        };
    }

    updateArrayWork(linkTo){
        // generalSliderContentCopy.splice(5,0, generalSliderContentCopy.splice(0,1)[0])
        const generalSliderContentCopy = [...this.props.generalSliderContent]
            const pathUrl = window.location.pathname
            generalSliderContentCopy.forEach((item, index, generalSliderContentCopy) => {
                if(item.linkTo === pathUrl) {
                    return generalSliderContentCopy.splice(index, 1)
                } else {
                    return 
                }
            })
            this.setState({
                newGeneralSliderContent: generalSliderContentCopy
            })
        }

    componentDidMount() {
        this.updateArrayWork()
    }

    startDrag(e){
        if(e.touches.length === 1) {
            e = e.touches[0];
        }
        this.setState({ touchesX: e.screenX});
    }
    drag(e){
        let point = e.touches ? e.touches[0] : e
        this.setState({ lastTranslate: point.screenX - this.state.touchesX});
    }
    endDrag(e){
        if(this.state.lastTranslate < -10){
            this.slideRight()
            this.setState({ lastTranslate:0});

        }else if(this.state.lastTranslate > 10){
            this.slideLeft()
            this.setState({ lastTranslate:0});
        }else {
            return
        }
    }
    changeSlideBullet(index){
        const slide = this.props.refSlide.current
        const allBullet = Array.from(slide.querySelectorAll("[data-bullet-name]"))
        this.setState({ indexActive: index});
        if(index >= allBullet.length){
            this.setState({ indexActive: 0});index = 0
        }
        if(index < 0){
            this.setState({ indexActive: allBullet.length - 1});
            index=allBullet.length - 1
        }
        this.props.refSlide.current.setAttribute("data-work", this.state.newGeneralSliderContent[index].attr )
    }
    slideLeft(){
        this.setState({ indexActive: this.state.indexActive-1});
        this.changeSlideBullet(this.state.indexActive-1)
    }
    slideRight(){
        this.setState({ indexActive: this.state.indexActive+1});
        this.changeSlideBullet(this.state.indexActive+1)
    }

    render(){
        const {sliderNameClass, sliderTitle, titleLevel, refSlide, idName} = this.props;
        const {indexActive, newGeneralSliderContent} = this.state;
        const CustomTagH = titleLevel;

        return(
            <Intersection>
                <div id={idName} ref={refSlide} data-work={newGeneralSliderContent[0].attr} className={`default-slider ${sliderNameClass}`}>
                    <div data-animate="slide-top" className={`default-slider__wrapper ${sliderNameClass}__wrapper`} onTouchEnd={this.endDrag} onTouchStart={this.startDrag} onTouchMove={this.drag}>
                        <CustomTagH className={`default-slider__wrapper__title ${sliderNameClass}__wrapper__title`}>{sliderTitle}</CustomTagH>
                        {newGeneralSliderContent.map((item, index) => {
                            return (<SliderCard handleClick={this.updateArrayWork} index={index} indexActive={indexActive} key={index} imageUrlCard={item.image} cardTitle={item.title} cardText={item.text} linkTo={item.linkTo} titleLevel='h4' sliderCardClassName={`${sliderNameClass}__wrapper`}></SliderCard>)
                        })}
                        <div className={`default-slider__wrapper__arrows ${sliderNameClass}__wrapper__arrows`}>
                            <div onClick={this.slideLeft} className={`default-slider__wrapper__arrows__left ${sliderNameClass}__wrapper__arrows__left`}>{"<"}</div>
                            <div onClick={this.slideRight} className={`default-slider__wrapper__arrows__right ${sliderNameClass}__wrapper__arrows__right`}>{">"}</div>
                        </div>
                        <nav className={`default-slider__wrapper__slider-nav ${sliderNameClass}__wrapper__slider-nav`}>
                            {newGeneralSliderContent.map((item, index) => {
                                return (<div key={index} onClick={()=> {this.changeSlideBullet(index)}} data-bullet-name={item.title} className={`default-slider__wrapper__slider-nav__bullet${indexActive === index ? "--active" : ""} ${sliderNameClass}__wrapper__slider-nav__bullet${indexActive === index ? "--active" : ""}`}></div>)
                            })}
                        </nav>
                    </div>
                </div>
            </Intersection>
        )
    }
}


export default Slider;

Slider.defaultProps = {
    sliderNameClass: 'default-slider',
    titleLevel: 'h2',
    idName:'default-slider'
};

Slider.propTypes = {
    sliderNameClass: PropTypes.string,
    idName: PropTypes.string,
    sliderTitle: PropTypes.string,
    titleLevel: PropTypes.string,
    attrwork: PropTypes.string,
    generalSliderContent: PropTypes.array.isRequired,
    refSlide: PropTypes.object
    // refSlide: PropTypes.oneOfType([
        // PropTypes.func, 
        // PropTypes.shape({ current: PropTypes.instanceOf(Slider) })])
  };
