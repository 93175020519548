import React from 'react';
import ThemeContext from '../../../ThemeContext'

function Footer({footerProp}) {
    return (
            <ThemeContext.Consumer>
            {
                ({theme}) => (
                <section className={`footer ${theme ? 'theme--dark' : 'theme--light' }`} id="footer">
                    <div className="footer__content">
                    <div className="footer__content__icon"><i className={footerProp.icon}></i></div>
                <p className="footer__content__text">{footerProp.txt}</p>
                    </div>
                </section>
            )}
        </ThemeContext.Consumer>
    )
}

export default Footer
