import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';

class SliderCard extends React.Component {
    // constructor(props) {
    //     super(props)
    // }

    render(){
        const {sliderCardClassName, titleLevel, imageUrlCard, cardTitle, cardText, linkTo, indexActive, index} = this.props;
        const CustomTagH = titleLevel;
        return(
            <div data-work-slide-card className={`default-slider__wrapper__default-slider-card${indexActive === index ? "--active" : ""} ${sliderCardClassName}__slider-Card${indexActive === index ? "--active" : ""}`}>
              <Link to={linkTo} onClick={()=>window.scroll(0,0)} className={`default-slider__wrapper__default-slider-card${indexActive === index ? "--active" : ""}__wrapper ${sliderCardClassName}__slider-card${indexActive === index ? "--active" : ""}__wrapper`}>
                <figure className={`default-slider__wrapper__default-slider-card${indexActive === index ? "--active" : ""}__wrapper__figure ${sliderCardClassName}__slider-card${indexActive === index ? "--active" : ""}__wrapper__figure`}>
                <picture>
                    <source media="(max-width: 800px)" sizes=""
                    srcSet={`${imageUrlCard.small} 180w, ${imageUrlCard.smallx2} 2x`}></source>
                    <source media="(min-width: 801px)" sizes=""
                    srcSet={`${imageUrlCard.large} 250w, ${imageUrlCard.largex2} 2x`}></source>
                    <img alt={cardTitle} src={imageUrlCard.large}
                    className={`default-slider__wrapper__default-slider-card${indexActive === index ? "--active" : ""}__wrapper__figure__image ${sliderCardClassName}__slider-card${indexActive === index ? "--active" : ""}__wrapper__figure__image`}/>
                </picture>
                    <figcaption  className={`default-slider__wrapper__default-slider-card${indexActive === index ? "--active" : ""}__wrapper__figure__figcaption ${sliderCardClassName}__slider-card${indexActive === index ? "--active" : ""}__wrapper__figure__figcaption`}>
                        <CustomTagH className={`default-slider__wrapper__default-slider-card${indexActive === index ? "--active" : ""}__wrapper__figure__figcaption__title ${sliderCardClassName}__slider-card${indexActive === index ? "--active" : ""}__wrapper__figure__figcaption__title`}>{cardTitle}</CustomTagH>
                        <p className={`default-slider__wrapper__default-slider-card${indexActive === index ? "--active" : ""}__wrapper__figure__figcaption__text ${sliderCardClassName}__slider-card${indexActive === index ? "--active" : ""}__wrapper__figure__figcaption__text`}>{cardText}</p>
                    </figcaption>
                </figure>
                </Link>
            </div>
        )
    }
}

export default SliderCard;

SliderCard.defaultProps = {
    sliderCardClassName: 'default-slider-card',
    titleLevel:'h3'
};

SliderCard.propTypes = {
    sliderCardClassName: PropTypes.string,
    titleLevel: PropTypes.string,
    imageUrlCard: PropTypes.object,
    cardTitle: PropTypes.string,
    cardText: PropTypes.string,
    linkTo:  PropTypes.string,
    indexActive: PropTypes.number,
    index: PropTypes.number
  };

