import React from 'react';
import ImgSrcset from '../../../../component/General/imgSrcset/imgSrcset'
import Intersection from '../../../../component/General/Intersection/Intersection'

import DOWNTRIGGERWORK from '../../../../content/works/downtrigger/downtriggerWorkPart3'

const downtriggerPart3 = () => {
    return (
        <div>
        <section className="downtrigger-album" id="downtrigger-album">
        <Intersection>
            <div className="downtrigger-album__wrapper">
                    <h3 className="downtrigger-album__wrapper__title" >{DOWNTRIGGERWORK.album.title}</h3>
                    <ImgSrcset conf={DOWNTRIGGERWORK.album.img.conf} item={DOWNTRIGGERWORK.album.img.images[0]} classLocation="downtrigger-album__wrapper__content" />
            </div>
        </Intersection>
        </section>
        <section className="downtrigger-video" id="downtrigger-video">
        <Intersection>
            <div className="downtrigger-video__wrapper">
                <h3 className="downtrigger-video__wrapper__title" >{DOWNTRIGGERWORK.video.title}</h3>
                <video width="50%" height="auto" controls="controls">
                    <source src={DOWNTRIGGERWORK.video.video} type="video/mp4" />
                </video>
            </div>
        </Intersection>
        </section>
        </div>
    )
}

export default downtriggerPart3;