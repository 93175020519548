import React from 'react';
import ImgSrcset from '../../../../component/General/imgSrcset/imgSrcset'
import Intersection from '../../../../component/General/Intersection/Intersection'
import SliderThumb from '../../../../component/General/SliderThumb/SliderThumb';

import ILLUSTRATIONSWORK from '../../../../content/works/illustrations/illustrationsWorkPart3'

const IllustrationsPart3 = () => {


    return (
        <div>
            {ILLUSTRATIONSWORK.sliderWork.map((item, index)=> {
                const itemName = item.title.toLowerCase()
                const refSlideThumb = React.createRef();
                return(
                    <section key={index} className={`illustrations-${itemName}`} id={`illustrations-${itemName}`}>
                     <Intersection>
                         <div className={`illustrations-${itemName}__wrapper`}>
                             <h3 className={`illustrations-${itemName}__wrapper__title`} >{item.title}</h3>
                             <div className={`illustrations-${itemName}__wrapper__presentation`}>
                                 <ImgSrcset
                                     conf={item.imgPresentation.generalConf} 
                                     specifiqueAnime='slide-bottom'
                                     item={item.imgPresentation.images[0]} 
                                     classLocation={`illustrations-${itemName}__wrapper__presentation__picture`} />
                             </div>
                         </div>
                     </Intersection>
                         <SliderThumb 
                             slideToScroll={item.imgSlide.generalConf.default.slideToScroll} 
                             slidesVisible={item.imgSlide.generalConf.default.slidesVisible} 
                             workItemSliderPic={item.imgSlide} 
                             sliderThumbNameClass={`illustrations-${itemName}__sliderthumb-mascotte`} 
                             refSlideThumb={refSlideThumb} 
                             idName={`sliderthumb-${itemName}-mascotte`}>
                         </SliderThumb>
                 </section>
                )
            })}
            <section className={`illustrations-blender`} id={`illustrations-blender`}>
                <Intersection>
                    <div className={`illustrations-blender__wrapper`}>
                        <h3 className={`illustrations-blender__wrapper__title`} >{ILLUSTRATIONSWORK.blender.title}</h3>
                            {ILLUSTRATIONSWORK.blender.img.images.map((item, index) => {
                                return( 
                                    <ImgSrcset
                                    key={index}
                                    conf={ILLUSTRATIONSWORK.blender.img.generalConf} 
                                    specifiqueAnime='slide-bottom'
                                    item={item}
                                    classLocation={`illustrations-blender__wrapper__content`} />
                                )
                            }
                            )}
                    </div>
                </Intersection>
            </section>
            <section className={`illustrations-vector`} id={`illustrations-vector`}>
                <Intersection>
                    <div className={`illustrations-vector__wrapper`}>
                        <h3 className={`illustrations-vector__wrapper__title`} >{ILLUSTRATIONSWORK.vector.title}</h3>
                            {ILLUSTRATIONSWORK.vector.img.images.map((item, index) => {
                                return( 
                                    <ImgSrcset
                                    key={index}
                                    conf={ILLUSTRATIONSWORK.vector.img.generalConf} 
                                    specifiqueAnime='slide-bottom'
                                    item={item}
                                    classLocation={`illustrations-vector__wrapper__content`} />
                                )
                            }
                            )}
                    </div>
                </Intersection>
            </section>
        </div>
    )
}

export default IllustrationsPart3;