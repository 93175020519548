import React from 'react';
import { SmoothScroll } from "../../General/SmoothScroll/SmoothScroll";
import { Link } from "react-router-dom";


class BreadCrumb extends React.Component {
    constructor(props){
        super(props)
        this.handleClick = this.handleClick.bind(this)
    }

    handleClick(e){
        SmoothScroll(e, e.target.innerHTML)
    }

    render(){
        const {workName} = this.props;
        return (
            <div data-animate="slide-bottom" className={`breadcrumb-default breadcrumb${workName}`}>
                <p><Link onClick={()=>window.scroll(0,0)} to="/">Accueil</Link> &gt; <a onClick={this.handleClick} href="#works">works</a> &gt; {workName.charAt(0).toUpperCase() + workName.slice(1)}</p>
            </div>
        )
    }
}

export default BreadCrumb;
