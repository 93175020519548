const FREELANCEWORK = {
    work : {
      title : "",
      img: {
        generalConf: {
            small: {
                media:'max-width: 800px',
                sizes:'',
                srcSetSize: '170w',
            },
            large: {
                media:'min-width: 801px',
                sizes:'',
                srcSetSize: '250w',
            },
            default: {
                animeNameAttr:'slide-bottom',
                animeTimeAttr:.2,
            }
        },
        images:[{
            small:   '/images/presentation-free-2.png',
            smallx2: '/images/presentation-free-2.png',
            large:   '/images/presentation-free-2.png',
            largex2: '/images/presentation-free-2.png',
            default: '/images/presentation-free-2.png',
            alt: '',
        }]
    }
}
}

export default FREELANCEWORK