import React from 'react';
import {generateWords} from "../../General/GenerateWords/GenerateWords"
import Parallax from '../../General/Parallax/Parallax';
import { SmoothScroll } from "../../General/SmoothScroll/SmoothScroll";
import SpanParser from '../../General/spanParser'
import PropTypes from 'prop-types';

class HomeScreen extends React.Component {
    constructor(props){
        super(props)
        this.handleClick = this.handleClick.bind(this)
    }

    componentDidMount() {
        generateWords('data-generate-word-home', this.props.wordsGsap)
    }

    handleClick(e){
        SmoothScroll(e, "presentation")
    }

    render() {
        const {starItems, imgParallaxHome,titleHome, subTitleHome} = this.props
        return (
            <section id="home-screen" className='home-screen'>
                <div id="accueil" data-parallax-wrap className="home-screen__wrapper">
                    <div className="home-screen__wrapper__elements">
                        {starItems.map((value, index) => {
                            return (
                                <div key={index} className='home-screen__wrapper__elements__element'>
                                    <div className="home-screen__wrapper__elements__element__bubble"></div>
                                    <div className="home-screen__wrapper__elements__element__ligne"></div>
                                    <div className="home-screen__wrapper__elements__element__infos-txt"><p>{value}</p></div>
                                 </div>
                            )
                        })}
                    </div>
                    <Parallax isHome={true} locationName="home-screen" imgParallaxHome={imgParallaxHome}></Parallax>
                </div>
                <div className="home-screen__intro">
                    <div className="home-screen__intro__title">
                        <div className="home-screen__intro__title__sep"></div>
                        <h1>
                            <SpanParser phrase={titleHome}></SpanParser>
                        </h1>
                    </div>
                    <div className="home-screen__intro__text">
                        {subTitleHome.before} <span data-generate-word-home>{subTitleHome.starter}</span><br/>{subTitleHome.after}
                    </div>
                </div>
                <a href="#presentation" className="home-screen__scroll-down" onClick={this.handleClick}>
                    <div className="home-screen__scroll-down__text">
                        <span className="home-screen__scroll-down__text__icon">
                            <i className="icon-arrow"></i></span>
                        <p className="home-screen__scroll-down__text__scroll">Scroll</p>
                    </div>
                </a>
            </section>
        )
    }
}

export default HomeScreen;

HomeScreen.propTypes = {
    starItems: PropTypes.array.isRequired,
    wordsGsap: PropTypes.array.isRequired,
    imgParallaxHome: PropTypes.object.isRequired
};

