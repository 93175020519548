const WORKSIMGPANEL = {
    scientifil: {
        display:true,
        imgPerLine: 3,
        generalConf: {
            small: {
                media:'max-width: 800px',
                sizes:'',
                srcSetSize: '336w',
            },
            large: {
                media:'min-width: 801px',
                sizes:'',
                srcSetSize: '537w',
            },
            default: {
                animeNameAttr:'slide-bottom',
                animeTimeAttr:.2,
            }
        },
        images:[{
            small: 'images/scientifil/screen/screen-1-small.png',
            smallx2:'images/scientifil/screen/screen-1-smallx2.png',
            large: 'images/scientifil/screen/screen-1-large.png',
            largex2:'images/scientifil/screen/screen-1-largex2.png',
            default:'images/scientifil/screen/screen-1-large.png',
            alt:''
        },{
            small: 'images/scientifil/screen/screen-2-small.png',
            smallx2:'images/scientifil/screen/screen-2-smallx2.png',
            large: 'images/scientifil/screen/screen-2-large.png',
            largex2:'images/scientifil/screen/screen-2-largex2.png',
            default:'images/scientifil/screen/screen-2-large.png',
            alt:''
        },{
            small: 'images/scientifil/screen/screen-3-small.png',
            smallx2:'images/scientifil/screen/screen-3-smallx2.png',
            large: 'images/scientifil/screen/screen-3-large.png',
            largex2:'images/scientifil/screen/screen-3-largex2.png',
            default:'images/scientifil/screen/screen-3-large.png',
            alt:''
        },{
            small: 'images/scientifil/screen/screen-4-small.png',
            smallx2:'images/scientifil/screen/screen-4-smallx2.png',
            large: 'images/scientifil/screen/screen-4-large.png',
            largex2:'images/scientifil/screen/screen-4-largex2.png',
            default:'images/scientifil/screen/screen-4-large.png',
            alt:''
        },{
            small: 'images/scientifil/screen/screen-5-small.png',
            smallx2:'images/scientifil/screen/screen-5-smallx2.png',
            large: 'images/scientifil/screen/screen-5-large.png',
            largex2:'images/scientifil/screen/screen-5-largex2.png',
            default:'images/scientifil/screen/screen-5-large.png',
            alt:''
        },{
            small: 'images/scientifil/screen/screen-6-small.png',
            smallx2:'images/scientifil/screen/screen-6-smallx2.png',
            large: 'images/scientifil/screen/screen-6-large.png',
            largex2:'images/scientifil/screen/screen-6-largex2.png',
            default:'images/scientifil/screen/screen-6-large.png',
            alt:''
        }
        ]
    },
    downtrigger: {
        display:true,
        imgPerLine: 1,
        generalConf: {
            small: {
                media:'max-width: 800px',
                sizes:'',
                srcSetSize: '336w',
            },
            large: {
                media:'min-width: 801px',
                sizes:'',
                srcSetSize: '537w',
            },
            default: {
                animeNameAttr:'slide-bottom',
                animeTimeAttr:.2,
            }
        },
        images:[{
            small: 'images/down_trigger/3000x3000-pochette.png',
            smallx2:'images/down_trigger/3000x3000-pochette.png',
            large: 'images/down_trigger/3000x3000-pochette.png',
            largex2:'images/down_trigger/3000x3000-pochette.png',
            default:'images/down_trigger/3000x3000-pochette.png',
            alt:'Down trigger'
        }
        ]
    },
    estories: {
        display:true,
        imgPerLine: 6,
        generalConf: {
            small: {
                media:'max-width: 800px',
                sizes:'',
                srcSetSize: '200w',
            },
            large: {
                media:'min-width: 801px',
                sizes:'',
                srcSetSize: '225w',
            },
            default: {
                animeNameAttr:'slide-bottom',
                animeTimeAttr:.2,
            }
        },
        images:[{
            small: '/images/estorie/e-stories-ui-2.png',
            smallx2:'/images/estorie/e-stories-ui-2.png',
            large: '/images/estorie/e-stories-ui-2.png',
            largex2:'/images/estorie/e-stories-ui-2.png',
            default:'/images/estorie/e-stories-ui-2.png',
            alt:''
        },{
            small: '/images/estorie/e-stories-ui-1.png',
            smallx2:'/images/estorie/e-stories-ui-1.png',
            large: '/images/estorie/e-stories-ui-1.png',
            largex2:'/images/estorie/e-stories-ui-1.png',
            default:'/images/estorie/e-stories-ui-1.png',
            alt:''
        },{
            small: '/images/estorie/e-stories-ui-2-bis.png',
            smallx2:'/images/estorie/e-stories-ui-2-bis.png',
            large: '/images/estorie/e-stories-ui-2-bis.png',
            largex2:'/images/estorie/e-stories-ui-2-bis.png',
            default:'/images/estorie/e-stories-ui-2-bis.png',
            alt:''
        },{
            small: '/images/estorie/e-stories-ui-3.png',
            smallx2:'/images/estorie/e-stories-ui-3.png',
            large: '/images/estorie/e-stories-ui-3.png',
            largex2:'/images/estorie/e-stories-ui-3.png',
            default:'/images/estorie/e-stories-ui-3.png',
            alt:''
        },{
            small: '/images/estorie/e-stories-ui-6.png',
            smallx2:'/images/estorie/e-stories-ui-6.png',
            large: '/images/estorie/e-stories-ui-6.png',
            largex2:'/images/estorie/e-stories-ui-6.png',
            default:'/images/estorie/e-stories-ui-6.png',
            alt:''
        },{
            small: '/images/estorie/e-stories-ui-5.png',
            smallx2:'/images/estorie/e-stories-ui-5.png',
            large: '/images/estorie/e-stories-ui-5.png',
            largex2:'/images/estorie/e-stories-ui-5.png',
            default:'/images/estorie/e-stories-ui-5.png',
            alt:''
        }
        ]
    },
    orange: {
        display:true,
        imgPerLine: 3,
        generalConf: {
            small: {
                media:'max-width: 800px',
                sizes:'',
                srcSetSize: '455w',
            },
            large: {
                media:'min-width: 801px',
                sizes:'',
                srcSetSize: '650w',
            },
            default: {
                animeNameAttr:'slide-bottom',
                animeTimeAttr:.2,
            }
        },
        images:[{
            small:  '/images/orange/orange-ui-1.png',
            smallx2:'/images/orange/orange-ui-1.png',
            large:  '/images/orange/orange-ui-1.png',
            largex2:'/images/orange/orange-ui-1.png',
            default:'/images/orange/orange-ui-1.png',
            alt:''
        },{
            small:  '/images/orange/orange-ui-2.png',
            smallx2:'/images/orange/orange-ui-2.png',
            large:  '/images/orange/orange-ui-2.png',
            largex2:'/images/orange/orange-ui-2.png',
            default:'/images/orange/orange-ui-2.png',
            alt:''
        },{
            small:  '/images/orange/orange-ui-3.png',
            smallx2:'/images/orange/orange-ui-3.png',
            large:  '/images/orange/orange-ui-3.png',
            largex2:'/images/orange/orange-ui-3.png',
            default:'/images/orange/orange-ui-3.png',
            alt:''
        },{
            small:  '/images/orange/orange-ui-4.png',
            smallx2:'/images/orange/orange-ui-4.png',
            large:  '/images/orange/orange-ui-4.png',
            largex2:'/images/orange/orange-ui-4.png',
            default:'/images/orange/orange-ui-4.png',
            alt:''
        },{
            small:  '/images/orange/orange-ui-5.png',
            smallx2:'/images/orange/orange-ui-5.png',
            large:  '/images/orange/orange-ui-5.png',
            largex2:'/images/orange/orange-ui-5.png',
            default:'/images/orange/orange-ui-5.png',
            alt:''
        },{
            small:  '/images/orange/orange-ui-6.png',
            smallx2:'/images/orange/orange-ui-6.png',
            large:  '/images/orange/orange-ui-6.png',
            largex2:'/images/orange/orange-ui-6.png',
            default:'/images/orange/orange-ui-6.png',
            alt:''
        }
        ]
    },
    meowbio: {
        display:true,
        imgPerLine: 3,
        generalConf: {
            small: {
                media:'max-width: 800px',
                sizes:'',
                srcSetSize: '455w',
            },
            large: {
                media:'min-width: 801px',
                sizes:'',
                srcSetSize: '650w',
            },
            default: {
                animeNameAttr:'slide-bottom',
                animeTimeAttr:.2,
            }
        },
        images:[{
            small:  '/images/meowbio/meow-ui-1.png',
            smallx2:'/images/meowbio/meow-ui-1.png',
            large:  '/images/meowbio/meow-ui-1.png',
            largex2:'/images/meowbio/meow-ui-1.png',
            default:'/images/meowbio/meow-ui-1.png',
            alt:''
        },{
            small:  '/images/meowbio/meow-ui-2.png',
            smallx2:'/images/meowbio/meow-ui-2.png',
            large:  '/images/meowbio/meow-ui-2.png',
            largex2:'/images/meowbio/meow-ui-2.png',
            default:'/images/meowbio/meow-ui-2.png',
            alt:''
        },{
            small:  '/images/meowbio/meow-ui-3.png',
            smallx2:'/images/meowbio/meow-ui-3.png',
            large:  '/images/meowbio/meow-ui-3.png',
            largex2:'/images/meowbio/meow-ui-3.png',
            default:'/images/meowbio/meow-ui-3.png',
            alt:''
        }
        ]
    },
    illustrations: {
        display:false,
        imgPerLine: 3,
        generalConf: {
            small: {
                media:'max-width: 800px',
                sizes:'',
                srcSetSize: '455w',
            },
            large: {
                media:'min-width: 801px',
                sizes:'',
                srcSetSize: '650w',
            },
            default: {
                animeNameAttr:'slide-bottom',
                animeTimeAttr:.2,
            }
        },
        images:[{
            small:  '',
            smallx2:'',
            large:  '',
            largex2:'',
            default:'',
            alt:''
        }
        ]
    },
    entreprises: {
        display:false,
        imgPerLine: 3,
        generalConf: {
            small: {
                media:'max-width: 800px',
                sizes:'',
                srcSetSize: '455w',
            },
            large: {
                media:'min-width: 801px',
                sizes:'',
                srcSetSize: '650w',
            },
            default: {
                animeNameAttr:'slide-bottom',
                animeTimeAttr:.2,
            }
        },
        images:[{
            small:  '',
            smallx2:'',
            large:  '',
            largex2:'',
            default:'',
            alt:''
        }
        ]
    },
    freelance: {
        display:false,
        imgPerLine: 3,
        generalConf: {
            small: {
                media:'max-width: 800px',
                sizes:'',
                srcSetSize: '455w',
            },
            large: {
                media:'min-width: 801px',
                sizes:'',
                srcSetSize: '650w',
            },
            default: {
                animeNameAttr:'slide-bottom',
                animeTimeAttr:.2,
            }
        },
        images:[{
            small:  '',
            smallx2:'',
            large:  '',
            largex2:'',
            default:'',
            alt:''
        }
        ]
    }
}

export default WORKSIMGPANEL;