import React from 'react';
import PropTypes from 'prop-types';
import { SmoothScroll } from "../../General/SmoothScroll/SmoothScroll";
import {Link} from 'react-router-dom'

class Button extends React.Component{
    constructor(props){
        super(props)
        this.handleClick= this.handleClick.bind(this)
    }

    handleClick(e){
        if(this.props.isAnchor){
            SmoothScroll(e, this.props.hrefLink)
        }
        if(this.props.isSameDomaine && !this.props.isAnchor) {
            window.scroll(0,0);
        }
    }

    render(){
        const {isButton, ariaLabel, classButtonName, inText, hrefLink, isAnchor, target, idButtonName, disabled, handleClickButton, isPrimary, isSameDomaine} = this.props;
        const btnStyle = isPrimary ? '-primary' : '-secondary';
        let button;
        if (isButton) {
            button = <button aria-label={ariaLabel} disabled={disabled} target={target ? "_blank" : '_self'} id={idButtonName} name={idButtonName} type="button" onClick={handleClickButton} className={`default-button ${classButtonName}`}>{inText}</button>
          } else {
              if(!isSameDomaine) {
                button = <a target={target ? "_blank" : '_self'} href={isAnchor ? `#${hrefLink}` : hrefLink} className={`default-button${btnStyle} ${classButtonName}`} onClick={this.handleClick}>{inText}
                {this.props.children}
                </a>
              }else {
                button = <Link target={target ? "_blank" : '_self'} to={isAnchor ? `#${hrefLink}` : hrefLink} className={`default-button${btnStyle} ${classButtonName}`} onClick={this.handleClick}>{inText}
                {this.props.children}
                </Link>
              }

          }

        return (
           <div>{button}</div>
        )
        
    }
}

export default Button

Button.defaultProps = {
    isSameDomaine:false,
    classButtonName: 'default-button',
    idButtonName: 'button-default',
    inText:'Cliquez-ici',
    srcLink:'/',
    isAnchor:false,
    target:false,
    typeButton: '',
    disabled: false,
    isButton: false,
    ariaLabel:"button"
};

Button.propTypes = {
    classButtonName: PropTypes.string,
    idButtonName: PropTypes.string,
    isButton: PropTypes.bool.isRequired,
    handleClickButton: PropTypes.func,
    inText: PropTypes.string.isRequired,
    hrefLink: PropTypes.string,
    isAnchor: PropTypes.bool,
    target: PropTypes.bool,
    typeButton: PropTypes.string,
    disabled: PropTypes.bool,
    isPrimary: PropTypes.bool,
};

