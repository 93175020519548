

import React from 'react';
import PropTypes from 'prop-types';
import ThemeContext from '../../../ThemeContext'

class ThemeButtonIllu extends React.Component {
    render() {
        const {classLocation} = this.props
        return (
            <ThemeContext.Consumer>
				{
					({theme, funcTheme}) => (
						<div className={`default-theme-button-illu ${classLocation} ${theme ? 'theme--dark' : 'theme--light'}`}>
            <svg version="1.1" x="0px" y="0px" viewBox="80 240 1000 550" width="80" height="35">
<g id="Chouette" className={`chouette${!theme ? '__inactive' : ''}`} onClick={funcTheme}>
	<g id="chouette-bg">
		<path className="st0" d="M845.8,350H562v292h283.8c80.3,0,146-65.7,146-146v0C991.8,415.7,926.1,350,845.8,350z"/>
	</g>
	<g id="chouette-moon">
		<path className="st11" d="M948.8,337.4c-12.4-32-2.7-67.2,21.7-88.6c-9,0.2-18.1,1.9-27,5.4c-40.9,15.9-61.2,61.9-45.3,102.8
			c15.9,40.9,61.9,61.2,102.8,45.3c8.9-3.5,16.8-8.3,23.6-14.3C992.2,388.8,961.2,369.4,948.8,337.4z"/>
	</g>
	<g id="chouette-face">
		<g id="chouette-face__head-bg__1">
			<g>
				<path className="st2" d="M963,627.6c-3.3-32.5-24.2-41-12.1-83.8c12.1-42.8,23.1-106-9.9-138.5c-16-15.8-84.3-18.7-150.2-17.4
					c-65.9-1.2-134.2,1.6-150.2,17.4c-33,32.5-22,95.8-9.9,138.5c12.1,42.8-8.8,51.3-12.1,83.8c-3,29.7,63.1,79.5,172.2,90.4
					C899.9,707.2,966,657.4,963,627.6z"/>
				<path className="st2" d="M790.8,718.6L790.8,718.6c-87.5-8.7-142.3-40.9-162.7-65.9c-7.4-9-10.9-17.7-10.1-25.2
					c1.2-12.1,4.8-20.8,8.3-29.2c5.9-14.2,11.4-27.5,3.8-54.3c-7.8-27.7-11.9-52.4-12-73.3c-0.2-29.1,7.3-51.3,22-65.8
					c13.5-13.3,64.2-19.2,150.6-17.6c86.4-1.6,137.1,4.3,150.6,17.6c14.8,14.6,22.2,36.7,22,65.8c-0.1,20.9-4.1,45.6-12,73.3
					c-7.6,26.8-2.1,40.2,3.8,54.3c3.5,8.4,7.1,17.2,8.3,29.2l0,0c0.8,7.5-2.7,16.2-10.1,25.2C933.1,677.7,878.3,709.9,790.8,718.6
					L790.8,718.6z M765.5,388.2c-53.4,0-110.3,3.5-124.5,17.5c-14.6,14.3-21.9,36.2-21.7,65c0.1,20.8,4.1,45.4,11.9,73
					c7.7,27.2,2.1,40.8-3.8,55.1c-3.5,8.4-7,17-8.2,28.9c-0.7,7.1,2.7,15.6,9.8,24.3c11.7,14.3,32.5,28.5,58.4,39.8
					c29.9,13,65.6,21.9,103.4,25.6c37.8-3.8,73.5-12.6,103.4-25.6c25.9-11.3,46.7-25.4,58.4-39.8c7.2-8.8,10.6-17.2,9.8-24.3
					c-1.2-11.9-4.8-20.5-8.2-28.9c-5.9-14.4-11.5-27.9-3.8-55.1c7.8-27.6,11.8-52.1,11.9-73c0.2-28.8-7.1-50.7-21.7-65
					c-16.5-16.3-90.6-18.4-149.8-17.3C782.6,388.3,774.1,388.2,765.5,388.2z"/>
			</g>
		</g>
		<g id="chouette-face__head-bg__2">
			<g>
				<path className="st3" d="M962.8,516.3c-17.5-84.8-62.3-83.5-62.3-83.5c-8.3-76.7-212-76.7-220.2,0c0,0-44.9-1.2-62.3,83.5
					c-17.5,84.8,103.5,125.9,133.4,123.4c14,12.9,31.8,13.8,37.9,15.4c0.1,0.6,0.2,0.9,0.3,0.9c0.2-0.2,0.5-0.3,0.9-0.5
					c0.4,0.2,0.7,0.3,0.9,0.5c0.1,0.1,0.2-0.2,0.3-0.9c6.1-1.6,23.9-2.4,37.9-15.4C859.3,642.3,980.3,601.1,962.8,516.3z"/>
				<path className="st3" d="M791.1,656.7l-0.3-0.3c-0.1-0.1-0.2-0.2-0.4-0.3c-0.3,0.1-0.4,0.2-0.4,0.3l-0.3,0.3l-0.4-0.1
					c-0.3-0.1-0.5-0.4-0.6-0.9c-1.1-0.2-2.4-0.5-4-0.8c-8.3-1.5-22.1-3.9-33.6-14.5c-0.7,0.1-1.5,0.1-2.4,0.1
					c-21.1,0-78.3-16.5-111.1-53.4c-18.8-21.2-25.7-45-20.4-70.8c5-24.3,15.6-56.8,37.9-73.9c11.9-9.1,22-10,24.5-10.1
					c2.1-17.8,14.9-32.7,37-43.1c19.8-9.3,45.9-14.4,73.6-14.4c0,0,0,0,0,0c27.7,0,53.8,5.1,73.6,14.4c22.1,10.4,34.9,25.3,37,43.1
					c2.4,0.1,12.5,0.9,24.5,10.1c22.3,17.1,32.9,49.6,37.9,73.9c5.3,25.8-1.5,49.6-20.4,70.8c-34.1,38.4-94.6,54.7-113.4,53.4
					c-11.6,10.6-25.4,13-33.6,14.5c-1.6,0.3-2.9,0.5-4,0.8c-0.1,0.5-0.2,0.8-0.5,0.9L791.1,656.7z M751.5,639.2l0.2,0.2
					c11.4,10.5,25,12.9,33.2,14.3c1.8,0.3,3.3,0.6,4.4,0.9l0.4,0.1l0,0.4c0,0,0,0,0,0c0.1,0,0.2-0.1,0.3-0.1l0.2-0.1l0.2,0.1
					c0.1,0,0.2,0.1,0.3,0.1c0,0,0,0,0,0l0-0.4l0.4-0.1c1.1-0.3,2.7-0.6,4.4-0.9c8.2-1.4,21.9-3.9,33.2-14.3l0.2-0.2l0.3,0
					c18.3,1.5,78.7-14.6,112.7-52.9c18.6-20.9,25.3-44.4,20.1-69.8l0,0c-9-43.6-25.3-63.9-37.5-73.2c-13.1-10-24.1-9.9-24.2-9.9
					l-0.5,0l-0.1-0.5c-4.2-39.2-59.7-57-109.5-57c-49.8,0-105.3,17.8-109.5,57l-0.1,0.5l-0.5,0c-0.1,0-11.2-0.2-24.3,9.9
					c-12.1,9.3-28.4,29.6-37.4,73.2c-5.2,25.4,1.5,48.9,20.1,69.8c34,38.3,94.4,54.5,112.7,52.9L751.5,639.2z"/>
			</g>
		</g>
		<g id="chouette-face__eye">
			<g id="chouette-face__eye__color">
				<path className="st4" d="M746.4,544c0,0-30.8-19-34.4-42.7c-11.8-2.4-26.1,0-26.1,0s-9.5,28.4,13,42.7
					C721.5,558.2,739.2,536.9,746.4,544z"/>
				<path className="st4" d="M830.5,536.9c0,0,28.4-22.5,28.4-27.3c11.8-2.4,26.1,0,26.1,0s9.5,23.7-11.8,37.9S835.2,549.9,830.5,536.9z
					"/>
			</g>
			<g id="chouette-face__eye__pupilles">
				<path d="M853,538.1c7.2,0,13-5.8,13-13c0-5.7-3.7-10.5-8.8-12.3c-3.2,4.1-10.6,10.7-16.8,15.9
					C842.1,534.1,847.1,538.1,853,538.1z"/>
				<path d="M702.5,521.5c0,7.2,5.8,13,13,13c4.9,0,9.1-2.7,11.3-6.6c-5.2-5.5-10.2-12.1-12.9-19.4
					C707.5,509.4,702.5,514.8,702.5,521.5z"/>
			</g>
			<g id="chouette-face__eye__light">
				<circle className="st5" cx="702.5" cy="516.7" r="7.1"/>
				<circle className="st5" cx="866" cy="523.9" r="7.1"/>
			</g>
		</g>
		<g id="chouette-face__front">
			<path className="st6" d="M962.6,367.7c-0.5-2.2-2.2-4-4.6-4.7c-3.9-1.2-10.6-0.2-17.7,6.4c-1.6,1.5-3.9,3.6-6.8,6.2
				c-38.5,34.7-77.8,71.6-77.8,81.4c0,8.8-10.1,27.8-16.6,28.8c-0.7,0.1-2.7,0.4-4.3-4.4c-3.4-10.2-27.7-15.2-48.8-15.5
				c-21.1,0.3-45.3,5.2-48.7,15.5c-1.6,4.8-3.6,4.5-4.3,4.4c-6.5-1.1-16.6-20-16.6-28.8c0-9.8-39.3-46.7-77.8-81.4
				c-2.9-2.6-5.2-4.7-6.8-6.2c-7.2-6.6-13.8-7.6-17.7-6.4c-2.4,0.8-4.1,2.5-4.6,4.7c-0.8,3.4,1.2,7.5,5.6,11.4
				c8.9,8.1,16.2,23.7,23.9,40.2c3.3,7,6.6,14.2,10.2,21c5.8,11,16.4,17.6,25.8,23.4c10.2,6.3,19,11.8,19,21
				c0,15.7,20.9,33.7,34.7,45.7c3.5,3,6.4,5.6,8.4,7.5c2.8,2.8,5.9,7.5,9.2,12.5c6.3,9.4,12.2,18.4,18,18.4c0,0,0,0,0.1,0
				c2.2,0,4.2-1.3,5.8-3.8c3.3-5.3,8.7-7.4,15.8-6.5c0.1,0,0.3,0,0.4,0c7.1-1,12.4,1.2,15.8,6.5c1.6,2.5,3.5,3.8,5.8,3.8
				c0,0,0,0,0.1,0c5.9,0,11.8-8.9,18-18.4c3.3-5,6.4-9.7,9.2-12.5c1.9-1.9,4.9-4.5,8.4-7.5c13.8-11.9,34.7-30,34.7-45.7
				c0-9.2,8.8-14.7,19-21c9.4-5.8,20.1-12.4,25.8-23.4c3.6-6.8,6.9-14,10.2-21c7.7-16.5,15-32.1,23.9-40.2
				C961.4,375.2,963.4,371.2,962.6,367.7z"/>
		</g>
		<g id="chouette-face__nose">
			<path className="st1" d="M769.5,574.2c2.6-13,26.1-23.7,29.6,0c3.6,23.7-11.8,40.3-15.4,68.7C781.3,620.4,765.9,592,769.5,574.2z"/>
		</g>
	</g>
</g>
<g id="Coq" className={`coq${theme ? '__inactive' : ''}`} onClick={funcTheme}>
	<g id="coq-bg">
		<path className="st7" d="M278.2,350c-80.3,0-146,65.7-146,146v0c0,80.3,65.7,146,146,146H562V350H278.2z"/>
	</g>
	<g id="coq-sun">
		<circle className="st8" cx="256.5" cy="403.5" r="136.5"/>
	</g>
	<g id="coq-face">
		<g id="coq-face__red-part-2">
			<path className="st9" d="M227.6,584.6c-9.6,24.2-10,51.9-1,76.4c1.7,4.5,3.7,9,7,12.5c3.3,3.5,8.2,5.8,12.9,5c4.5-0.7,8.1-3.9,11.4-7
				c7.5-6.9,15.1-13.8,22.6-20.7c2.5-2.3,5.2-4.9,5.4-8.3c0.3-3.6-2.5-6.9-5.7-8.6c-3.2-1.7-6.9-2.2-10.5-3
				c-21.1-5-37.9-24.5-39.7-46.1"/>
			<path className="st9" d="M430.4,639.6c12,5.2,21.2,16.4,24.1,29.1c1.1,4.8,1.9,10.6,6.4,12.6c3.4,1.5,7.5-0.1,9.9-2.9
				c2.4-2.8,3.5-6.4,4.4-10c1.8-7.4,2.8-15.2,1.4-22.7s-5.4-14.8-11.9-18.8c-6.5-4-15.6-4.1-21.4,0.9c-2.3,2-4,4.6-6.1,6.8
				s-4.9,4.2-7.9,4.1"/>
			<path className="st9" d="M305.4,618.2c-9.4,7.6-12.9,20.1-15.4,31.8c-6.4,30.6-9.1,62-7.9,93.2c0.3,7,0.8,14.3,4.4,20.3
				c3.6,6,11.2,10.2,17.7,7.6c6.8-2.7,9.4-10.8,11.1-17.9c3.6-15.2,7.3-30.5,10.9-45.7c2.6-11.1,5.3-22.5,4.2-33.8
				c-2-20-15.4-36.9-28.2-52.4"/>
			<path className="st9" d="M406.5,637.7c-13.9,36.3-15,77.4-2.9,114.4c1.4,4.4,3.1,8.9,6.1,12.4c3,3.5,7.6,6.1,12.2,5.5
				c4.8-0.6,8.7-4.5,10.7-9c1.9-4.5,2.2-9.5,2.2-14.4c0.5-32.9-5.6-65.8-17.9-96.3c-1.9-4.8-4.9-10.2-10-10.7
				c-4.6-0.5-8.7,3.6-9.9,8.1c-1.2,4.5-0.2,9.2,0.8,13.8"/>
		</g>
		<g id="coq-face__red-part-2__effect">
			<path className="st10" d="M330.5,673.8c-2-20-15.4-36.9-28.2-52.4l3.1-3.2c-5.1,4.1-8.5,9.7-10.9,15.9c11.6,14.3,22.8,29.9,24.6,48.1
				c1.1,11.4-1.5,22.7-4.2,33.8c-3.6,15.2-7.3,30.5-10.9,45.7c-0.8,3.4-1.8,7.1-3.4,10.3c1.2,0,2.5-0.3,3.7-0.7
				c6.8-2.7,9.4-10.8,11.1-17.9c3.6-15.2,7.3-30.5,10.9-45.7C328.9,696.5,331.6,685.1,330.5,673.8z"/>
			<path className="st10" d="M416,650l0.3,1.9c0.4,0,0.8,0.1,1.2,0.2c-0.2-0.6-0.5-1.2-0.7-1.8c-1.9-4.8-4.9-10.2-10-10.7l-0.3-1.9
				c-13.9,36.3-15,77.4-2.9,114.4c1.4,4.4,3.1,8.9,6.1,12.4c1.4,1.6,3,3,4.9,4c-0.5-1.3-1-2.7-1.4-4.1
				C401,727.4,402.1,686.3,416,650z"/>
		</g>
		<g id="coq-face__head-bg">
			<path className="st5" d="M480.7,531.2c-1-15.7-5.1-31.3-9.1-46.3c-4.7-18.1-9.7-36.8-9.1-55.7c0-0.8-0.6-1.5-1.4-1.6
				c-0.8-0.1-1.5,0.5-1.7,1.3c-1.1,5.5-5.2,10.4-10.5,12.4l-8.3-12.4c-0.3-0.4-0.7-0.6-1.2-0.7c-0.5,0-0.9,0.1-1.3,0.5l-3.8,3.9
				c-3.4-8.9-11.4-16-20.6-18.3l-8.8-5.8c-0.5-0.3-1.2-0.3-1.7,0c-0.5,0.4-0.8,1-0.7,1.6c0.4,1.8-0.2,3.5-1.6,5.1
				c-2.3,2.6-6.7,4.2-10.5,3.8c-2.9-0.3-5.7-1.2-8.7-2.1c-3.6-1.1-7.3-2.3-11.2-2.3c-2.5,0-4.9,0.4-7.3,0.8
				c-2.7,0.4-5.2,0.9-7.7,0.7c-2.7-0.2-5.5-1.8-6.9-3.8c-0.9-1.2-1.1-2.5-0.8-3.8c0.2-0.8-0.2-1.7-1-1.9c-0.8-0.2-1.7,0.2-1.9,1
				c-1.4,4.2-5.6,6.1-9.1,6.5c-1.2,0.1-2.6,0.1-3.9-0.1c0.3-0.5,0.3-1.1,0-1.6l-6.6-11.6c-0.3-0.5-0.7-0.8-1.3-0.8
				c-0.5,0-1,0.2-1.3,0.7l-8.8,12.6l-6.7-6.8c-0.3-0.3-0.8-0.5-1.3-0.5c-0.5,0.1-0.9,0.3-1.2,0.8l-6.7,11.5
				c-3.9-2.3-9.2-2.1-12.8,0.7c-3.4,2.6-4.9,7.2-4.1,11.3l-20.2-3.4c-0.4-0.1-0.9,0-1.2,0.3c-0.3,0.3-0.6,0.7-0.6,1.1l-1,16.7
				c-1.1-0.4-2.2-0.6-3.5-0.5c-2.9,0.2-5.8,1.7-7.7,3.9c-3.1,3.7-3.9,8.7-4.6,12.8l-13.9,86.7c-4.8,30.2-8.2,61.7,8,85.6
				c8.6,12.7,21.8,20.9,35.4,22.1c14.5,1.2,29-6.4,35.2-18.3c2.9,1.7,3.6,4.8,4.4,8.1c1,4.1,2.2,9.2,8.9,9.1c0.1,0,0.1,0,0.2,0
				c10.6,0,19.3,17.7,27.1,33.3c7.1,14.5,13.3,27,21,27c0.2,0,0.3,0,0.5,0c14.6-1.1,21-23.4,25.6-39.6c1.5-5.4,2.9-10,4.1-12.6
				c4.9-9.9,15.4-18.5,25.9-15.7c4.6,1.2,8.6,4.6,12.6,7.8c5.3,4.4,10.8,8.9,17.9,8.5c6.3-0.3,12.4-5,15.6-11.9
				c2.8-6.1,3.3-12.9,3.8-19l4.9-66.2C480.6,554.6,481.4,542.8,480.7,531.2z M328.7,412.8c-0.5-0.3-1-0.6-1.4-1l1.1,0.4L328.7,412.8
				z"/>
		</g>
		<g id="coq-face__eyes">
			<g id="coq-face__eyes__color">
				<g>
					<path className="st4" d="M254.6,489.8c0,0,25.5,23,27.2,32.6c1.8,9.6,1.9,25.8-9.9,27.8C260.2,552.2,238.4,535.3,254.6,489.8z"/>
					<path className="st4" d="M460.7,497.6L446.9,517c0,0-10.6,33.2,5.6,33.1c16.2-0.1,23.7-21.1,21.6-32.9
						C472.1,505.5,460.7,497.6,460.7,497.6z"/>
				</g>
				<g>
					<path className="st4" d="M254.6,489.8c0,0,25.5,23,27.2,32.6c1.8,9.6,1.9,25.8-9.9,27.8C260.2,552.2,238.4,535.3,254.6,489.8z"/>
					<path className="st4" d="M460.7,497.6L446.9,517c0,0-10.6,33.2,5.6,33.1c16.2-0.1,23.7-21.1,21.6-32.9
						C472.1,505.5,460.7,497.6,460.7,497.6z"/>
				</g>
			</g>
			<g id="coq-face__eyes__pupilles">
				<path className="st6" d="M470.7,524c-0.5-6.8-6.4-12-13.3-11.5c-3.3,0.2-6.3,1.8-8.5,4.3s-3.2,5.7-3,9c0.5,6.5,5.9,11.5,12.4,11.5
					c0.3,0,0.6,0,0.9,0c3.3-0.2,6.3-1.8,8.5-4.3S470.9,527.3,470.7,524z"/>
				<path className="st6" d="M277,522.8c-0.2-3.3-1.8-6.3-4.3-8.5c-2.5-2.2-5.7-3.2-9-3c-6.8,0.5-12,6.4-11.5,13.3
					c0.2,3.3,1.8,6.3,4.3,8.5c2.3,2,5.1,3,8.1,3c0.3,0,0.6,0,0.9,0C272.3,535.6,277.5,529.6,277,522.8z"/>
			</g>
			<g id="coq-face__eyes__light">
				<circle className="st5" cx="263" cy="515.7" r="6.5"/>
				<circle className="st5" cx="459.9" cy="516.3" r="6.5"/>
			</g>
		</g>
		<g id="coq-face__red-part">
			<path className="st9" d="M351.3,613.2c-17.1-9.9-28.2-29.4-28.1-49.1c0-5.6,2-12.4,7.5-13.4c6.2-1.1,10.3,5.9,12.6,11.7
				c3.1,8,6.2,16.1,9.2,24.1c1.8,4.7,4,9.8,8.7,11.6c3.8,1.5,8.2,0.3,11.5-2.1c3.3-2.4,5.7-5.8,8-9.1c4.8-6.9,9.5-13.8,14.3-20.7
				c2.1-3,4.6-6.3,8.2-6.8c3.7-0.6,7.4,2.2,8.9,5.7s1.2,7.5,0.4,11.2c-2.9,13.9-12.5,26.3-25.2,32.6s-28.4,6.3-41.2,0.1"/>
			<path className="st9" d="M437,347.2c-4.5-10.6-18.7-10.3-27-5.1c-6.2,3.8-10.9,9.7-15.6,15c-4.8,5.5-9.6,11-14.5,16.5
				c1.7-14.1,6.8-27.5,15.2-39c6.4-8.7,21.5-18.1,17.7-30.8c-1.3-4.2-4.8-7.2-9.2-7.2c-5.5-0.1-9.8,3.8-12.8,8.1
				c-5,7.2-9.1,15.1-13.6,22.6c-2.8,4.6-5.6,9.3-8.3,13.9c3.9-15.2,7.8-30.4,11.7-45.6c1-3.9,1.9-8.1,3.9-11.7
				c2.5-4.2,6.5-6.5,10.8-8.3c6.3-2.6,13.5-4.3,18.2-9.6c3.3-3.7,4.9-9.3,2.8-14c-2.2-4.9-8-6.2-13-6.4c-12.8-0.5-24.7,8.3-31,18.9
				c-8.3,14.1-9.8,30.9-13.8,46.4c-4.5,17.7-11.1,34.9-19.6,51.1c-6.8,12.9-19.7,27.6-17.8,43.1c1.7,14.5,18,20.9,25.1,32.5
				c9.1,14.9,10.1,33.4,10.2,50.5c0.1,21.1-2.6,42.3-0.6,63.4c1,9.1,3.5,22.2,14.1,24.8c4.2,1,9,0,12.7-2.2c0.7-0.4,1-1.5,0.6-2.2
				c-5-8.1-8.7-18.8-7-28.4c1.8-10.9,4.7-22.3,8.4-32.8c3.8-10.9,9.6-20.8,15.8-30.5c5.6-8.8,12.8-20.3,7.5-30.9
				c-2.3-4.7-6.8-7.8-9.3-12.4c-4.8-8.8,5.4-12.4,11.1-16.3c3.4-2.3,6.8-5.7,6.9-10.1c0.1-4.5-3.2-7.4-6.6-9.9c-2.6-2-5.7-3.8-6.9-7
				c-1.2-3.4-0.1-7.2,1.7-10.1c3.7-6.2,10.7-8.9,16.9-11.9C430.2,367.2,441.7,358.4,437,347.2z"/>
		</g>
		<g id="coq-face__nose">
			<path className="st1" d="M416.8,625.6c3.5-4.5,10.4-7,15.1-3.8c1.4-4.2,0.2-9.1-2.9-12.1c-3.2-3.1-8.1-4.1-12.2-2.5
				c-6.4,2.4-10.1,10.2-16.9,11.4c-8.5,1.5-14.5-8.1-17.4-16.3c-2.8-8.2-6.9-18.1-15.6-18.9c-8.4-0.8-14.3,8.1-17.4,16
				c-3,7.9-6.2,17.2-14.2,20c-6.1,2.2-13.2-0.7-17.8-5.3c-4.6-4.6-7.3-10.7-9.9-16.7c-11.5,6.5-26.3,10-26,18.8
				c0.1,4.2,18.2-2.4,22.2,5.6c3.4,6.8,5.2,14.1,5.7,21.5c4.2,5.5,1.5,15,10.1,14.8c21.6-0.5,33.6,61.4,48.7,60.3
				c18.3-1.3,23.3-41,28.5-51.4c2.7-5.5,7.2-10.7,12.5-13.9c0.6-3.9,1.3-7.7,1.9-11.6C412.2,636,413.3,630.1,416.8,625.6z"/>
		</g>
	</g>
</g>
</svg>
            </div>

					)
				}
            </ThemeContext.Consumer>
        )
    }
}



ThemeButtonIllu.defaultProps = {
    classLocation: 'default-theme-button-illu'
};

ThemeButtonIllu.propTypes = {
   classLocation: PropTypes.string,
};

export default ThemeButtonIllu;
