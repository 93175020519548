import React from 'react';
import ImgSrcset from '../../../../component/General/imgSrcset/imgSrcset'
import Intersection from '../../../../component/General/Intersection/Intersection'

import ESTORIESWORK from '../../../../content/works/estories/estoriesWorkPart3'

const EstoriesPart3 = () => {
    return (
        <div>
        <section className="estories-wireframes" id="estories-wireframes">
        <Intersection>
            <div className="estories-wireframes__wrapper">
                    <h3 className="estories-wireframes__wrapper__title" >{ESTORIESWORK.wireframes.title}</h3>
                    <div className='estories-wireframes__wrapper__content'>
                    {ESTORIESWORK.wireframes.img.images.map((item, index)=> {
                        return ( <ImgSrcset key={index}
                            conf={ESTORIESWORK.wireframes.img.generalConf} 
                            specifiqueAnime='slide-bottom'
                            indexNum={index}
                            item={item} classLocation="estories-wireframes__wrapper__content__picture" />)
                    })}
                    </div>
            </div>
        </Intersection>
        </section>
        <section className="estories-icones" id="estories-icones">
        <Intersection>
            <div className="estories-icones__wrapper">
                <h3 className="estories-icones__wrapper__title" >{ESTORIESWORK.icones.title}</h3>
                <div className='estories-icones__wrapper__content'>
                {ESTORIESWORK.icones.img.images.map((item, index)=> {
                        return ( <ImgSrcset key={index}
                            specifiqueAnime={index === 0 ? 'slide-left' : index === 2 ? 'slide-right' : 'slide-bottom'}
                            conf={ESTORIESWORK.icones.img.generalConf} 
                            item={item} classLocation="estories-icones__wrapper__content__picture" />)
                    })}
                </div>
            </div>
        </Intersection>
        </section>
        <section className="estories-gamification" id="estories-gamification">
        <Intersection>
            <div className="estories-gamification__wrapper">
                <h3 className="estories-gamification__wrapper__title" >{ESTORIESWORK.gamification.title}</h3>
                <div className="estories-gamification__wrapper__content">
                {ESTORIESWORK.gamification.img.images.map((item, index)=> {
                        return ( <ImgSrcset key={index}
                            specifiqueAnime={index === 0 ? 'slide-left' : index === 2 ? 'slide-right' : 'slide-bottom'}
                            conf={ESTORIESWORK.gamification.img.generalConf} 
                            item={item} classLocation="estories-gamification__wrapper__content__picture" />)
                    })}
                </div>
            </div>
        </Intersection>
        </section>
        </div>
    )
}

export default EstoriesPart3;