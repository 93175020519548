import React from 'react';
import PropTypes from 'prop-types';
import Intersection from '../Intersection/Intersection';


const operators = {
    '/': function(a, b) { return a / b },
    '*': function(a, b) { return a * b },
};

class Parallax extends React.Component {
    constructor(props){
        super(props)
        this.parallaxHome = this.parallax.bind(this)
        this.refParallax = React.createRef();
        this.state = {
            x: 0,
            y: 0
        }
    }

    parallax(e) {
        const width = this.refParallax.current.clientWidth;
        const height = this.refParallax.current.clientHeight;
        const offX = `${this.props.offsetType}X`
        const offY = `${this.props.offsetType}Y`
        const oX = operators[this.props.sign](Math.floor(e.nativeEvent[offX]/width * 100), this.props.movement)
        const oY = operators[this.props.sign](Math.floor(e.nativeEvent[offY]/height * 100), this.props.movement)
        this.setState({
            x: `${oX}px`,
            y: `${oY}px`
        })
    }


    render() {
    const {x, y} = this.state
    const {imageParallax, locationName, isHome, animate, animeName, animeDelay, imgParallaxHome} = this.props
    // const variableAttribute = { 'attr': value };
    const variableAttribute = animeDelay ? {'data-animated-delay': true } : {}
    const srcsetImagePar = typeof imageParallax === 'object'
    const moves = {
        '--moveX':`${x}`,
        '--moveY':`${y}`
        }
        return (
            <div className={`parallax__wrapper__img-parallax ${locationName}__wrapper__img-parallax`} onMouseMove={(e)=>this.parallax(e)}>
                <div data-parallax-img className={`parallax__wrapper__img-parallax__1 ${locationName}__wrapper__img-parallax__1`} ref={this.refParallax} style={moves}>
                    {isHome ? ( <img alt={imgParallaxHome.alt} src={imgParallaxHome.img} 
                    srcSet={`${imgParallaxHome.generalConf.conf3.url} ${imgParallaxHome.generalConf.conf3.srcSetSize},
                    ${imgParallaxHome.generalConf.conf1.url} ${imgParallaxHome.generalConf.conf1.srcSetSize}, 
                    ${imgParallaxHome.generalConf.conf2.url} ${imgParallaxHome.generalConf.conf2.srcSetSize}`}/>
                    ) : 
                    srcsetImagePar ? 
                    animate ? 
                    <Intersection>
                    <picture>
                            <source media="(max-width: 800px)" sizes=""
                            srcSet={`${imageParallax.small} 216w, ${imageParallax.smallx2} 2x`}></source>
                            <source media="(min-width: 801px)" sizes=""
                            srcSet={`${imageParallax.large} 310w, ${imageParallax.largex2} 2x`}></source>
                            <img { ...variableAttribute } { ...animeName } alt="" src={imageParallax.large}/>
                    </picture>
                    </Intersection>
                    :
                    <picture>
                        <source media="(max-width: 800px)" sizes=""
                        srcSet={`${imageParallax.small} 216w, ${imageParallax.smallx2} 2x`}></source>
                        <source media="(min-width: 801px)" sizes=""
                        srcSet={`${imageParallax.large} 310w, ${imageParallax.largex2} 2x`}></source>
                        <img { ...variableAttribute } { ...animeName } alt="" src={imageParallax.large}/>
                    </picture>
                    :
                    animate ? 
                    <Intersection><img className={`${locationName}__wrapper__img-parallax__1__img`} { ...variableAttribute } { ...animeName } alt="" src={imageParallax}/></Intersection> :
                    <div className={`${locationName}__wrapper__img-parallax__1__img`}><img alt="" src={imageParallax}/></div>
                    }
                </div>
                {this.props.children}
            </div>
        )
    }
}

Parallax.defaultProps = {
    locationName: 'parallax',
    movement: 1,
    sign: '/',
    animate: false,
    animeDelay: false,
    offsetType: 'offset',
    isHome: false
};

Parallax.propTypes = {
    locationName: PropTypes.string,
    isHome: PropTypes.bool.isRequired,
    movement: PropTypes.number,
    sign: PropTypes.string,
    animate: PropTypes.bool,
    animeName: PropTypes.object,
    animeDelay: PropTypes.bool,
    offsetType: PropTypes.string,
    imgParallaxHome: PropTypes.object

  };

export default Parallax
