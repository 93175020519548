import React, { useEffect, useContext } from 'react';
import MenuContext from '../../../MenuContext'

function Overlay() {
    const contextMenu = useContext(MenuContext);
    const isToggleOn = contextMenu.toggle;
    // const handleClickToggle = contextMenu.funcMenu; //in consumer or here
    useEffect(()=>{
        const overlay =  document.querySelector("[data-overlay-menu]");
        if(isToggleOn){
            overlay.classList.replace('overlay-menu--invisible', 'overlay-menu')
        }else {
            overlay.classList.replace('overlay-menu', 'overlay-menu--invisible')
        }
    })

    return (
        <MenuContext.Consumer>
        {
        ({funcMenu}) => (
        <div aria-hidden={true} className="overlay-menu--invisible" id="overlay-menu" data-overlay-menu onClick={funcMenu}></div>
        )
        }
        </MenuContext.Consumer>
    )
}

export default Overlay
