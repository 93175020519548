import React from 'react';
import Clavier from '../Pendu/Clavier'
import ResultsPlayers from './Results'
import Intersection from "../Intersection/Intersection"

import PENDUGAME from '../../../content/error/errorPenduGame'

class Pendu extends React.Component {
    constructor(props){
        super(props)
        this.onClickLetter = this.onClickLetter.bind(this);
        this.refresh = this.refresh.bind(this);
        this.generateWord = this.generateWord.bind(this)
        this.state = {
            letterAlreadyUsed:[],
            guesses:[0],
            playerScore:[0],
            phrase : this.generateWord(),
            game : "begin",
            currentAllLetterUsed:[],
            winner:"no winner yet",
            resultPlayer: 'no result yet'
        }
    }

    generateWord(){
        let result = []
        result = PENDUGAME.configGame.words[Math.floor(Math.random() * Math.floor(PENDUGAME.configGame.words.length))]
        return result;
    }

    refresh(){
        const {phrase} = this.state
        let newWord = this.generateWord()
        while(phrase === newWord){
            newWord = this.generateWord()
        }
        this.setState({phrase : newWord, game : "begin", letterAlreadyUsed:[], currentAllLetterUsed:[], guesses: [0], playerScore: [0]})
    }

    computeDisplay(phrase, usedLetters) {
        return phrase.replace(/\w/g,
          (letter) => (usedLetters.includes(letter) ? letter : '_')
        )
    }

    guessesUpdate(){
        const {guesses} = this.state
        let newGuesses = guesses
        newGuesses = [guesses[0]+1] 
       this.setState({guesses : newGuesses})
        if (guesses[0] >= PENDUGAME.configGame.nbTurn - 1) {
            this.setState({game:'lose'})
            const RESULTSPLAYERS = [
                { id: 0, score: PENDUGAME.configGame.result.loose.score, player: PENDUGAME.configGame.playerName}
              ]
            this.setState({resultPlayer : RESULTSPLAYERS})
        }
    }

    ptsAndRoleUpdate(currentLetter){
        const {playerScore, currentAllLetterUsed, phrase} = this.state
        const verifPhrase = this.verifPhrase(phrase.split(""), currentLetter)
        const verifExistingLetter = this.verifExistingLetter(currentAllLetterUsed , currentLetter)
        let newPlayerScore = playerScore
        if(verifPhrase && verifExistingLetter)
        {
            // plus 10 si bonne lettre
            newPlayerScore = [playerScore[0]+ PENDUGAME.configGame.sysPts.good.pts] 
            this.setState({playerScore : newPlayerScore})
        }else if(!verifPhrase && verifExistingLetter){
            // -5 pour une lettre qui se trouve pas dans la phrase et n'a pas été tapé
            newPlayerScore = [playerScore[0]- PENDUGAME.configGame.sysPts.bad.pts] 
            this.setState({playerScore : newPlayerScore})
        }else if(!verifPhrase && !verifExistingLetter){
            // -15 pour une lettre qui se trouve pas dans la phrase et deja tapé
            newPlayerScore = [playerScore[0]- PENDUGAME.configGame.sysPts.alreadyNot.pts] 
            this.setState({playerScore : newPlayerScore})
        }else{
            // -10 pour une lettre qui se trouve dans la phrase et deja tapé
            newPlayerScore = [playerScore[0]- PENDUGAME.configGame.sysPts.already.pts] 
            this.setState({playerScore : newPlayerScore})
        }
    }

    verifPhrase(phrase, currentLetter){
        let result = false;
        for(let i = 0; i < phrase.length; i++){
            if(result){
                break
            }
            if(phrase[i].includes(currentLetter)){
                result = true
            }
        }
        return result
    }

    verifExistingLetter(phrase, currentLetter){
        let result = true;
        for(let i = 0; i < phrase.length; i++){
            if(!result){
                break
            }
            if(phrase[i].includes(currentLetter)){
                result = false
            }
        }
        return result
    }

    trimCompareArray(array){
        return array.sort().filter((item, pos, ary) => {
            return !pos || item !== ary[pos - 1];
        })
    }

    determinateWinner(newLetterUsed){
        const {phrase, playerScore} = this.state

        let array1Compare = phrase.replace(/ /g,"").split("")
        let array2Compare = newLetterUsed
        array1Compare = this.trimCompareArray(array1Compare)
        array2Compare = this.trimCompareArray(array2Compare)

        if(array1Compare.length === array2Compare.length){
            this.setState({game:'win'})
            const RESULTSPLAYERS = [
                { id: 0, score: playerScore[0], player: PENDUGAME.configGame.playerName }
              ]
            RESULTSPLAYERS[0].score = playerScore[0]+ PENDUGAME.configGame.sysPts.good.pts
            this.setState({resultPlayer : RESULTSPLAYERS})
            this.setState({winner : PENDUGAME.configGame.result.win.text})
        }
    }

    onClickLetter(e) {
        const {currentAllLetterUsed, phrase, letterAlreadyUsed} = this.state

       // find the letters already used and path
        let newLetterUsed = []
        let currentLetter = e.target.innerHTML
        let allLetterUsed = []
       
        // letter cliked
        allLetterUsed = [...currentAllLetterUsed, ...currentLetter]
        this.setState({currentAllLetterUsed : allLetterUsed})

        // Use for comparing array
        phrase.split("").includes(currentLetter) ? currentLetter = e.target.innerHTML : currentLetter = ''
        newLetterUsed = [...letterAlreadyUsed, ...currentLetter]
        this.setState({letterAlreadyUsed : newLetterUsed})
        
        // player pts and roles
        this.ptsAndRoleUpdate(e.target.innerHTML)

        // win - All Letters find
        this.determinateWinner(newLetterUsed)

        // Lose - Guesses and retry - player
        this.guessesUpdate()
    }

    render(){
        const {guesses, playerScore, game, phrase, letterAlreadyUsed, currentAllLetterUsed, winner, resultPlayer}= this.state

        return ( <div className ="pendu" id="pendu">
                    <div className="pendu__wrapper">
                        <h2 className="pendu__wrapper__title">{PENDUGAME.title}</h2>
                        <Intersection>
                            <div className="pendu__wrapper__player" data-animate="slide-bottom">
                                <h3 className="pendu__wrapper__player__title">{PENDUGAME.subTitle}</h3>
                                <p className="pendu__wrapper__player__score">
                                    <strong>{guesses[0]}</strong>/{PENDUGAME.configGame.nbTurn} trs 
                                    <span> || </span>
                                    <strong>{playerScore[0]}</strong> pts
                                </p>
                            </div>
                        </Intersection>
                        <Intersection classDiv='pendu__wrapper__anime'>
                            {game === "begin" &&
                            <div data-animate="slide-bottom">
                                <h2 className="pendu__wrapper__find-word">{this.computeDisplay(phrase, letterAlreadyUsed)}</h2>
                                <Clavier boardTitle={PENDUGAME.boardTitle} onClick={this.onClickLetter} checked={currentAllLetterUsed}></Clavier>
                            </div>}
                        </Intersection>
                        <Intersection classDiv='pendu__wrapper__anime'>
                            {game === "win" && 
                        <div data-animate="slide-bottom" className="pendu__wrapper__win">
                            <h4>{PENDUGAME.configGame.result.win.isFind}</h4>
                            <p className="pendu__wrapper__win__message">{phrase}</p>
                            <p>{winner}</p>
                            <button className="pendu__wrapper__win__button" onClick={this.refresh}>
                                {PENDUGAME.configGame.replayButText}
                            </button>
                            <ResultsPlayers scoreTitle={PENDUGAME.tabScoreRes} entries={resultPlayer}/>
                        </div>}
                        </Intersection>
                        <Intersection classDiv='pendu__wrapper__anime'>
                        {game === "lose" && 
                        <div className="pendu__wrapper__lose" data-animate="slide-bottom">
                            <h4>{PENDUGAME.configGame.result.loose.isFind}</h4>
                            <p>{PENDUGAME.configGame.result.loose.word}</p>
                            <p className="pendu__wrapper__lose__message">{phrase}</p>
                            <button className="pendu__wrapper__lose__button" onClick={this.refresh}>
                                {PENDUGAME.configGame.replayButText}
                            </button>
                            <ResultsPlayers scoreTitle={PENDUGAME.tabScoreRes} entries={resultPlayer}/>
                        </div>}
                        </Intersection>
                        <Intersection classDiv='pendu__wrapper__anime'>
                            <div className='pendu__wrapper__comment' data-animate="slide-bottom">
                                +{PENDUGAME.configGame.sysPts.good.pts} {PENDUGAME.configGame.sysPts.good.txt} {<br></br>}
                                -{PENDUGAME.configGame.sysPts.bad.pts} {PENDUGAME.configGame.sysPts.bad.txt}{<br></br>}
                                -{PENDUGAME.configGame.sysPts.already.pts} {PENDUGAME.configGame.sysPts.already.txt}{<br></br>}
                                -{PENDUGAME.configGame.sysPts.alreadyNot.pts} {PENDUGAME.configGame.sysPts.alreadyNot.txt}{<br></br>}
                            </div>
                        </Intersection>
                    </div>
                </div>
            )
    }
 }

export default Pendu;
