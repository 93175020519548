import React, {Suspense} from 'react';
import {Title} from '../../General/Title/Title'
// import Button from '../../General/Button/Button'
// import Parallax from '../../General/Parallax/Parallax';
// import Card from '../Works/Card/Card';
// import Slider from '../../General/Slider/Slider';

const Card = React.lazy(() => import('../Works/Card/Card'));
const Slider = React.lazy(() => import('../../General/Slider/Slider'));


class Works extends React.Component {
    constructor(props){
        super(props)
        this.refSlide = React.createRef();
    }

    render() {
        const {generalSliderContent, worksProp} = this.props
        return (
            <section id="works" className="works">
                <div className="works__wrapper">
                    <Title classTitle='works' titleName={worksProp.title} subTitleName={worksProp.subTitle}></Title>
                    <div className="works__wrapper__items">
                        {
                            worksProp.liWorks.map((item, index)=> {
                                return(
                                    <Suspense key={index} fallback={<div>Chargement...</div>}>
                                        <Card classLocation="works__wrapper__items" 
                                        index={index} title={item.title} title2={item.title2} linkTo={item.linkTo} 
                                        image={item.image} infos={item.infos} infosPlus={item.infosPlus}>
                                        </Card>
                                    </Suspense>
                                )
                            })
                        }
                    </div>
                 </div>
                 <Suspense fallback={<div>Chargement...</div>}>
                    <Slider generalSliderContent={generalSliderContent.liWorks} refSlide={this.refSlide} titleLevel='h3' sliderTitle={generalSliderContent.title} sliderNameClass="works__slider">
                    </Slider>
                </Suspense>
            </section>
        )
    }
}

export default Works;
