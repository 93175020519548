const GENERALCONTACTFORM = {
    infosGen: {
        title: 'plus d\'infos ?',
        subTitle: 'Prenons contact !',
        txt: "N'hésitez pas à me contacter <br>si vous souhaitez plus d'informations !",
        error: 'Veuillez remplir correctement les champs obligatoires'
    },
    input: {
        name: {
            placeholder: 'Nom * (et Prénom)',
            label: 'Nom',
            error: "Ecrivez votre nom",
            id: 'name'
        },
        agence: {
            placeholder: 'Agence/Entreprise',
            label: 'Agence/Entreprise',
            error: 'Pas plus de 40 charactères',
            id: 'agence'
        },
        email: {
            placeholder: 'Email *',
            label: 'Email',
            error: "email invalid",
            id: 'email'
        },
        subject: {
            placeholder: 'Sujet *',
            label: 'Sujet',
            error: "Votre sujet doit être plus précis",
            id: 'subject'
        },
        message: {
            placeholder:'Message *',
            label: 'Message',
            error: "Votre message doit être plus complet",
            error2 : 'Votre message doit être plus concis',
            id: 'message'
        },
        button: {
            txt: 'Envoyer le message',
            id: 'contact-btn'
        }
    }
}

export default GENERALCONTACTFORM;