const WORKSMENULINKS = {
    scientifil: {
        anchor: ['scientifil', 'works', 'contact'],
        page: [{name: 'accueil', link:'/', target:'_self', externe:false}],
        id:'scientifil'
    },
    downtrigger: {
        anchor: ['downtrigger', 'works', 'contact'],
        page: [{name: 'accueil', link:'/', target:'_self', externe:false}],
        id:'downtrigger'
    },
    estories: {
        anchor: ['estories', 'works', 'contact'],
        page: [{name: 'accueil', link:'/', target:'_self', externe:false}],
        id:'estories'
    },
    orange: {
        anchor: ['orange', 'works', 'contact'],
        page: [{name: 'accueil', link:'/', target:'_self', externe:false}],
        id:'orange'
    },
    meowbio: {
        anchor: ['meowbio', 'works', 'contact'],
        page: [{name: 'accueil', link:'/', target:'_self', externe:false}],
        id:'meowbio'
    },
    illustrations: {
        anchor: ['illustration', 'works', 'contact'],
        page: [{name: 'accueil', link:'/', target:'_self', externe:false}],
        id:'illustration'
    },
    entreprises: {
        anchor: ['works', 'contact'],
        page: [{name: 'accueil', link:'/', target:'_self', externe:false}],
        id:'entreprises'
    },
    freelance: {
        anchor: ['works', 'contact'],
        page: [{name: 'accueil', link:'/', target:'_self', externe:false}],
        id:'freelance'
    }
}

export default WORKSMENULINKS;