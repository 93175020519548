const MEOWBIOWORK = {
    screen : {
      title : "Exemple d'écrans",
      img: {
        generalConf: {
            small: {
                media:'max-width: 800px',
                sizes:'',
                srcSetSize: '450w',
            },
            large: {
                media:'min-width: 801px',
                sizes:'',
                srcSetSize: '1200w',
            },
            default: {
                animeNameAttr:'slide-bottom',
                animeTimeAttr:.2,
            }
        },
        images:[{
            small:   '/images/meowbio/meow-ui-scren-1.png',
            smallx2: '/images/meowbio/meow-ui-scren-1.png',
            large:   '/images/meowbio/meow-ui-scren-1.png',
            largex2: '/images/meowbio/meow-ui-scren-1.png',
            default: '/images/meowbio/meow-ui-scren-1.png',
            alt: '',
        },{
            small:   '/images/meowbio/meow-ui-scren-2.png',
            smallx2: '/images/meowbio/meow-ui-scren-2.png',
            large:   '/images/meowbio/meow-ui-scren-2.png',
            largex2: '/images/meowbio/meow-ui-scren-2.png',
            default: '/images/meowbio/meow-ui-scren-2.png',
            alt: ''
        }
    ]
    }
    },
    wireframe : {
        title : "wireframe -> design",
        img: {
            generalConf: {
                small: {
                    media:'max-width: 800px',
                    sizes:'',
                    srcSetSize: '450w',
                },
                large: {
                    media:'min-width: 801px',
                    sizes:'',
                    srcSetSize: '800w',
                },
                default: {
                    animeNameAttr:'slide-bottom',
                    animeTimeAttr:.2,
                }
            },
            images:[{
                small:   '/images/meowbio/meow-wireframe.gif',
                smallx2: '/images/meowbio/meow-wireframe.gif',
                large:   '/images/meowbio/meow-wireframe.gif',
                largex2: '/images/meowbio/meow-wireframe.gif',
                default: '/images/meowbio/meow-wireframe.gif',
                alt: '',
            }
        ]}
    },
    seo : {
        title : "Concurrence & SEO",
        img: {
            generalConf: {
                small: {
                    media:'max-width: 800px',
                    sizes:'',
                    srcSetSize: '450w',
                },
                large: {
                    media:'min-width: 801px',
                    sizes:'',
                    srcSetSize: '800w',
                },
                default: {
                    animeNameAttr:'slide-bottom',
                    animeTimeAttr:.2,
                }
            },
            images:[{
                small:   '/images/meowbio/concurrence-meow.png',
                smallx2: '/images/meowbio/concurrence-meow.png',
                large:   '/images/meowbio/concurrence-meow.png',
                largex2: '/images/meowbio/concurrence-meow.png',
                default: '/images/meowbio/concurrence-meow.png',
                alt: '',
            },{
                small:   '/images/meowbio/seo-meow.png',
                smallx2: '/images/meowbio/seo-meow.png',
                large:   '/images/meowbio/seo-meow.png',
                largex2: '/images/meowbio/seo-meow.png',
                default: '/images/meowbio/seo-meow.png',
                alt: '',
            }
        ]
    }
}
}

export default MEOWBIOWORK;