import React, {useEffect, useState} from 'react';
import Parallax from '../../../../component/General/Parallax/Parallax'
import anime from '../../../../js/anime.es';

import SCIENTIFILWORKORAGNES from '../../../../content/works/scientifil/scientifilWorkPart3Organes'

const ScientifilPart3 = () => {
    const styleDiv = {
        display:'none'
    }
    const timeline = anime.timeline({
        autoplay: false,
        direction: "normal",
        loop:false
      });
      const timeline2 = anime.timeline({
        autoplay: false,
        direction: "normal",
        loop:false
      });

      const from = 'M447.96616,0.0183556638 C565.322053,-1.26807663 624,65.0639143 624,199.014328 C624,332.964742 565.322053,568.959966 447.96616,907 L0,907 L0,0.0183556638 L447.96616,0.0183556638 Z'
      const to = 'M624,0.0183556638 C624,-1.26807663 624,65.0639143 624,199.014328 C624,332.964742 624,568.959966 624,907 L0,907 L0,0.0183556638 L624,0.0183556638 Z'

      timeline.add({
        targets: "#sidebar-wave path",
        d: {
          value: [
            from, to
          ],
          duration: 800,
          easing: 'cubicBezier(.8, .4, .2, 2)'
        },
    })
      timeline2.add({
        targets: "#sidebar-wave path",
        d: {
          value: [
            to, from
          ],
          duration: 500,
          easing: 'cubicBezier(.8, .4, .2, 2)'
        },
    })


    const [sidebarOpened, setSidebarOpened] = useState(false);

    const afficheText = (e) => {
        const modal = document.querySelector("#modal1")
        const infosModal = modal.querySelector("[data-modal-infos]")
        const titleModal = modal.querySelector("[data-modal-title]")
        const organe = e.target.id
        infosModal.innerHTML = SCIENTIFILWORKORAGNES[organe].text
        titleModal.innerHTML = SCIENTIFILWORKORAGNES[organe].title
        modal.setAttribute("aria-hidden","false")
        modal.setAttribute('aria-modal','true')
        setSidebarOpened(true)
        e.preventDefault()
        modal.style.display=""
   }

    const closeModal = (e) => {
        const modal = document.querySelector("#modal1")
        const buttonClose = modal.querySelector("[data-modal-close]")
        modal.setAttribute("aria-hidden","true")
        modal.setAttribute('aria-modal','false')

        buttonClose.removeEventListener("click", closeModal)
        modal.removeEventListener("click", closeModal)
        modal.addEventListener("animationend", hideAnim)

        if (sidebarOpened) {
            e.stopPropagation();
            setSidebarOpened(false)
          }
    }

    const hideAnim = () =>{
        const modal = document.querySelector("#modal1")
        modal.style.display="none"
        // Eviter le bug des plusieurs appels (lancement du Hide a chaque animationend)
        modal.removeEventListener("animationend", hideAnim)
    }

    useEffect(() => {
        if (sidebarOpened) {
            timeline.play()
        } else {
            timeline2.play()
        }
    })

    return (
        <section className="scientifil-part3" id="scientifil-part3">
            <div className="scientifil-part3__wrapper">
                <h3 className="scientifil-part3__wrapper__title" >Interaction</h3>
                    <Parallax movement={10} sign="/" isHome={false} locationName="scientifil-part3__wrapper__organe-tablette-parallax" imageParallax="/images/scientifil/organes-scientifil-body.png">
                        <div className="scientifil-part3__wrapper__organe-tablette-parallax__wrapper__interactions">
                            <div onClick={(e)=> afficheText(e)}  data-organe="foie" id="foie" className="scientifil-part3__wrapper__organe-tablette-parallax__wrapper__interactions__button">+</div>
                            <div onClick={(e)=> afficheText(e)} data-organe="vesBi" id="vesBi" className="scientifil-part3__wrapper__organe-tablette-parallax__wrapper__interactions__button">+</div>
                            <div onClick={(e)=> afficheText(e)} data-organe="pancreas" id="pancreas" className="scientifil-part3__wrapper__organe-tablette-parallax__wrapper__interactions__button">+</div>
                            <div onClick={(e)=> afficheText(e)} data-organe="salive" id="salive" className="scientifil-part3__wrapper__organe-tablette-parallax__wrapper__interactions__button">+</div>
                            <img alt="corps humain" className="scientifil-part3__wrapper__organe-tablette-parallax__wrapper__interactions__img" src="/images/scientifil/organes-scientifil.png"/>
                        </div>
                    </Parallax>
            </div>
            <aside onClick={(e)=>closeModal(e)} style={styleDiv} id="modal1" className="scientifil-part3__modal" aria-hidden="true" role="dialog" aria-modal="false" aria-labelledby="information corps">
                <div data-modal-wrapper='true' className="scientifil-part3__modal__wrapper">
                <svg className="scientifil-part3__modal__wrapper__sidebar-wave" width="100%" height="100%" id="sidebar-wave" preserveAspectRatio="none" viewBox="0 0 800 900">
                    <path d="M447.96616,0.0183556638 C565.322053,-1.26807663 624,65.0639143 624,199.014328 C624,332.964742 565.322053,568.959966 447.96616,907 L0,907 L0,0.0183556638 L447.96616,0.0183556638 Z" 
                    data-to="M624,0.0183556638 C624,-1.26807663 624,65.0639143 624,199.014328 C624,332.964742 624,568.959966 624,907 L0,907 L0,0.0183556638 L624,0.0183556638 Z"/>
                </svg>
                <button onClick={(e)=>closeModal(e)} data-modal-close="true" className="scientifil-part3__modal__wrapper__modal-close">
                    <div className="scientifil-part3__modal__wrapper__modal-close__icon-bar"></div>
                    <div className="scientifil-part3__modal__wrapper__modal-close__icon-bar"></div>
                </button>
                <h1 data-modal-title="true" className="scientifil-part3__modal__wrapper__title-modal">Le titre</h1>
                <p data-modal-infos="true" className="scientifil-part3__modal__wrapper__infos-modal">test</p>
                </div>
            </aside>
        </section>
    )
}

export default ScientifilPart3;