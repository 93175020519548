const ILLUSTRATIONSWORK = {
    sliderWork: [
        {
            title : "Hybrides",
            imgPresentation: {
                generalConf: {
                    small: {
                        media:'max-width: 800px',
                        sizes:'',
                        srcSetSize: '372w',
                    },
                    large: {
                        media:'min-width: 801px',
                        sizes:'',
                        srcSetSize: '750w',
                    },
                    default: {
                        animeNameAttr:'slide-bottom',
                        animeTimeAttr:.2,
                    }
                },
                images:[{
                    small:   '/images/illu/hybrides/illu-hybrides-small.png',
                    smallx2: '/images/illu/hybrides/illu-hybrides-large.png',
                    large:   '/images/illu/hybrides/illu-hybrides-large.png',
                    largex2: '/images/illu/hybrides/illu-hybrides-large.png',
                    default: '/images/illu/hybrides/illu-hybrides-large.png',
                    alt: '',
                }
            ]},
            imgSlide: {
                generalConf: {
                    small: {
                        media:'max-width: 800px',
                        sizes:'',
                        srcSetSize: '140w',
                    },
                    large: {
                        media:'min-width: 801px',
                        sizes:'',
                        srcSetSize: '320w',
                    },
                    default: {
                        animeNameAttr:'slide-bottom',
                        animeTimeAttr:.2,
                        slidesVisible: 2,
                        slideToScroll:1,
                        refSlide: 'hybrides'
                    }
                },
                images:[{
                    small:   '/images/illu/hybrides/illu-process-hybrides-small-1.png',
                    smallx2: '/images/illu/hybrides/illu-process-hybrides-large-1.png',
                    large:   '/images/illu/hybrides/illu-process-hybrides-large-1.png',
                    largex2: '/images/illu/hybrides/illu-process-hybrides-large-1.png',
                    default: '/images/illu/hybrides/illu-process-hybrides-large-1.png',
                    alt: '',
                },{
                    small:   '/images/illu/hybrides/illu-process-hybrides-small-2.png',
                    smallx2: '/images/illu/hybrides/illu-process-hybrides-large-2.png',
                    large:   '/images/illu/hybrides/illu-process-hybrides-large-2.png',
                    largex2: '/images/illu/hybrides/illu-process-hybrides-large-2.png',
                    default: '/images/illu/hybrides/illu-process-hybrides-large-2.png',
                    alt: ''
                },{
                    small:   '/images/illu/hybrides/illu-process-hybrides-small-3.png',
                    smallx2: '/images/illu/hybrides/illu-process-hybrides-large-3.png',
                    large:   '/images/illu/hybrides/illu-process-hybrides-large-3.png',
                    largex2: '/images/illu/hybrides/illu-process-hybrides-large-3.png',
                    default: '/images/illu/hybrides/illu-process-hybrides-large-3.png',
                    alt: ''
                },{
                    small:   '/images/illu/hybrides/illu-process-hybrides-small-4.png',
                    smallx2: '/images/illu/hybrides/illu-process-hybrides-large-4.png',
                    large:   '/images/illu/hybrides/illu-process-hybrides-large-4.png',
                    largex2: '/images/illu/hybrides/illu-process-hybrides-large-4.png',
                    default: '/images/illu/hybrides/illu-process-hybrides-large-4.png',
                    alt: ''
                }]
            }
        },
        {
            title : "3y8D006",
            imgPresentation: {
                generalConf: {
                    small: {
                        media:'max-width: 800px',
                        sizes:'',
                        srcSetSize: '375w',
                    },
                    large: {
                        media:'min-width: 801px',
                        sizes:'',
                        srcSetSize: '850w',
                    },
                    default: {
                        animeNameAttr:'slide-bottom',
                        animeTimeAttr:.2,
                    }
                },
                images:[{
                    small:   '/images/illu/3y8/illu-3y8D006-small.png',
                    smallx2: '/images/illu/3y8/illu-3y8D006-large.png',
                    large:   '/images/illu/3y8/illu-3y8D006-large.png',
                    largex2: '/images/illu/3y8/illu-3y8D006-large.png',
                    default: '/images/illu/3y8/illu-3y8D006-large.png',
                    alt: '',
                }
            ]},
            imgSlide: {
                generalConf: {
                    small: {
                        media:'max-width: 800px',
                        sizes:'',
                        srcSetSize: '200w',
                    },
                    large: {
                        media:'min-width: 801px',
                        sizes:'',
                        srcSetSize: '330w',
                    },
                    default: {
                        animeNameAttr:'slide-bottom',
                        animeTimeAttr:.2,
                        slidesVisible: 2,
                        slideToScroll:1,
                        refSlide: '3y8D006'
                    }
                },
                images:[{
                    small:   '/images/illu/3y8/illu-process-3y8D006-small-1.png',
                    smallx2: '/images/illu/3y8/illu-process-3y8D006-large-1.png',
                    large:   '/images/illu/3y8/illu-process-3y8D006-large-1.png',
                    largex2: '/images/illu/3y8/illu-process-3y8D006-large-1.png',
                    default: '/images/illu/3y8/illu-process-3y8D006-large-1.png',
                    alt: '',
                },{
                    small:   '/images/illu/3y8/illu-process-3y8D006-small-2.png',
                    smallx2: '/images/illu/3y8/illu-process-3y8D006-large-2.png',
                    large:   '/images/illu/3y8/illu-process-3y8D006-large-2.png',
                    largex2: '/images/illu/3y8/illu-process-3y8D006-large-2.png',
                    default: '/images/illu/3y8/illu-process-3y8D006-large-2.png',
                    alt: ''
                },{
                    small:   '/images/illu/3y8/illu-process-3y8D006-small-3.png',
                    smallx2: '/images/illu/3y8/illu-process-3y8D006-large-3.png',
                    large:   '/images/illu/3y8/illu-process-3y8D006-large-3.png',
                    largex2: '/images/illu/3y8/illu-process-3y8D006-large-3.png',
                    default: '/images/illu/3y8/illu-process-3y8D006-large-3.png',
                    alt: ''
                },{
                    small:   '/images/illu/3y8/illu-process-3y8D006-small-4.png',
                    smallx2: '/images/illu/3y8/illu-process-3y8D006-large-4.png',
                    large:   '/images/illu/3y8/illu-process-3y8D006-large-4.png',
                    largex2: '/images/illu/3y8/illu-process-3y8D006-large-4.png',
                    default: '/images/illu/3y8/illu-process-3y8D006-large-4.png',
                    alt: ''
                }]
            }
        },
        {
            title : "Nornes",
            imgPresentation: {
                generalConf: {
                    small: {
                        media:'max-width: 800px',
                        sizes:'',
                        srcSetSize: '372w',
                    },
                    large: {
                        media:'min-width: 801px',
                        sizes:'',
                        srcSetSize: '480w',
                    },
                    default: {
                        animeNameAttr:'slide-bottom',
                        animeTimeAttr:.2,
                    }
                },
                images:[{
                    small:   '/images/illu/nornes/illu-nornes-small.png',
                    smallx2: '/images/illu/nornes/illu-nornes-large.png',
                    large:   '/images/illu/nornes/illu-nornes-large.png',
                    largex2: '/images/illu/nornes/illu-nornes-large.png',
                    default: '/images/illu/nornes/illu-nornes-large.png',
                    alt: '',
                }
            ]},
            imgSlide: {
                generalConf: {
                    small: {
                        media:'max-width: 800px',
                        sizes:'',
                        srcSetSize: '140w',
                    },
                    large: {
                        media:'min-width: 801px',
                        sizes:'',
                        srcSetSize: '320w',
                    },
                    default: {
                        animeNameAttr:'slide-bottom',
                        animeTimeAttr:.2,
                        slidesVisible: 2,
                        slideToScroll:1,
                        refSlide: 'Nornes'
                    }
                },
                images:[{
                    small:   '/images/illu/nornes/illu-process-nornes-small-1.png',
                    smallx2: '/images/illu/nornes/illu-process-nornes-large-1.png',
                    large:   '/images/illu/nornes/illu-process-nornes-large-1.png',
                    largex2: '/images/illu/nornes/illu-process-nornes-large-1.png',
                    default: '/images/illu/nornes/illu-process-nornes-large-1.png',
                    alt: '',
                },{
                    small:   '/images/illu/nornes/illu-process-nornes-small-2.png',
                    smallx2: '/images/illu/nornes/illu-process-nornes-large-2.png',
                    large:   '/images/illu/nornes/illu-process-nornes-large-2.png',
                    largex2: '/images/illu/nornes/illu-process-nornes-large-2.png',
                    default: '/images/illu/nornes/illu-process-nornes-large-2.png',
                    alt: ''
                },{
                    small:   '/images/illu/nornes/illu-process-nornes-small-3.png',
                    smallx2: '/images/illu/nornes/illu-process-nornes-large-3.png',
                    large:   '/images/illu/nornes/illu-process-nornes-large-3.png',
                    largex2: '/images/illu/nornes/illu-process-nornes-large-3.png',
                    default: '/images/illu/nornes/illu-process-nornes-large-3.png',
                    alt: ''
                },{
                    small:   '/images/illu/nornes/illu-process-nornes-small-4.png',
                    smallx2: '/images/illu/nornes/illu-process-nornes-large-4.png',
                    large:   '/images/illu/nornes/illu-process-nornes-large-4.png',
                    largex2: '/images/illu/nornes/illu-process-nornes-large-4.png',
                    default: '/images/illu/nornes/illu-process-nornes-large-4.png',
                    alt: ''
                }
                ]
            }
        },
        {
            title : "Art",
            imgPresentation: {
                generalConf: {
                    small: {
                        media:'max-width: 800px',
                        sizes:'',
                        srcSetSize: '372',
                    },
                    large: {
                        media:'min-width: 801px',
                        sizes:'',
                        srcSetSize: '480w',
                    },
                    default: {
                        animeNameAttr:'slide-bottom',
                        animeTimeAttr:.2,
                    }
                },
                images:[{
                    small:   '/images/illu/art/illu-art-small.png',
                    smallx2: '/images/illu/art/illu-art-large.png',
                    large:   '/images/illu/art/illu-art-large.png',
                    largex2: '/images/illu/art/illu-art-large.png',
                    default: '/images/illu/art/illu-art-large.png',
                    alt: '',
                }
            ]},
            imgSlide: {
                generalConf: {
                    small: {
                        media:'max-width: 800px',
                        sizes:'',
                        srcSetSize: '140w',
                    },
                    large: {
                        media:'min-width: 801px',
                        sizes:'',
                        srcSetSize: '320w',
                    },
                    default: {
                        animeNameAttr:'slide-bottom',
                        animeTimeAttr:.2,
                        slidesVisible: 2,
                        slideToScroll:1,
                        refSlide: 'Nornes'
                    }
                },
                images:[{
                    small:   '/images/illu/art/illu-process-art-small-1.png',
                    smallx2: '/images/illu/art/illu-process-art-large-1.png',
                    large:   '/images/illu/art/illu-process-art-large-1.png',
                    largex2: '/images/illu/art/illu-process-art-large-1.png',
                    default: '/images/illu/art/illu-process-art-large-1.png',
                    alt: '',
                },{
                    small:   '/images/illu/art/illu-process-art-small-2.png',
                    smallx2: '/images/illu/art/illu-process-art-large-2.png',
                    large:   '/images/illu/art/illu-process-art-large-2.png',
                    largex2: '/images/illu/art/illu-process-art-large-2.png',
                    default: '/images/illu/art/illu-process-art-large-2.png',
                    alt: ''
                },{
                    small:   '/images/illu/art/illu-process-art-small-3.png',
                    smallx2: '/images/illu/art/illu-process-art-large-3.png',
                    large:   '/images/illu/art/illu-process-art-large-3.png',
                    largex2: '/images/illu/art/illu-process-art-large-3.png',
                    default: '/images/illu/art/illu-process-art-large-3.png',
                    alt: ''
                },{
                    small:   '/images/illu/art/illu-process-art-small-4.png',
                    smallx2: '/images/illu/art/illu-process-art-large-4.png',
                    large:   '/images/illu/art/illu-process-art-large-4.png',
                    largex2: '/images/illu/art/illu-process-art-large-4.png',
                    default: '/images/illu/art/illu-process-art-large-4.png',
                    alt: ''
                }]
            }
        }
    ],
    blender: {
        title: 'Blender',
        img: {
            generalConf: {
                small: {
                    media:'max-width: 800px',
                    sizes:'',
                    srcSetSize: '372w',
                },
                large: {
                    media:'min-width: 801px',
                    sizes:'',
                    srcSetSize: '480w',
                },
                default: {
                    animeNameAttr:'slide-bottom',
                    animeTimeAttr:.2,
                }
            },
            images:[{
                small:   '/images/illu/blender.png',
                smallx2: '/images/illu/blender.png',
                large:   '/images/illu/blender.png',
                largex2: '/images/illu/blender.png',
                default: '/images/illu/blender.png',
                alt:     ''
            },{
                small:  '/images/illu/blender-2.png',
                smallx2:'/images/illu/blender-2.png',
                large:  '/images/illu/blender-2.png',
                largex2:'/images/illu/blender-2.png',
                default:'/images/illu/blender-2.png',
                alt: ''
            }
            ]
        }
    },
    vector: {
        title: 'Illu vectorielles',
        img: {
            generalConf: {
                small: {
                    media:'max-width: 800px',
                    sizes:'',
                    srcSetSize: '140w',
                },
                large: {
                    media:'min-width: 801px',
                    sizes:'',
                    srcSetSize: '320w',
                },
                default: {
                    animeNameAttr:'slide-bottom',
                    animeTimeAttr:.2,
                }
            },
            images:[{
                small:   '/images/illu/illu-vecto.png',
                smallx2: '/images/illu/illu-vecto.png',
                large:   '/images/illu/illu-vecto.png',
                largex2: '/images/illu/illu-vecto.png',
                default: '/images/illu/illu-vecto.png',
                alt:     ''
            }
            ]
        }
    }
}

export default ILLUSTRATIONSWORK;