import React from 'react'
import { SmoothScroll } from '../SmoothScroll/SmoothScroll';
import PropTypes from 'prop-types';
import ThemeButton from '../ThemeButton/ThemeButton';
import { Link } from 'react-router-dom';
import MenuContext from '../../../MenuContext'

class Nav extends React.Component {
    constructor(props){
        super(props)
        this.handleClick = this.handleClick.bind(this)
        this.handleClickScroll = this.handleClickScroll.bind(this)
        this.handleClickScrollLogo = this.handleClickScrollLogo.bind(this)
        this.state = {
            isActive: null
        };
    }

    handleClick(e){
        this.setState({ isActive: e.target.textContent});
        const IsInterneLink = e.target.getAttribute("data-interne")
        if(IsInterneLink) {
            window.scroll(0,0)
        }
    }

    handleClickScroll(e){
        SmoothScroll(e, e.target.textContent)
        this.setState({ isActive: e.target.textContent});
    }


    handleClickScrollLogo(e){
        if(window.location.pathname === '/') {
            SmoothScroll(e, 'accueil')
        }else {
            window.scroll(0,0)
        }
    }

    render(){
        const {isActive} = this.state
        const {menuLink, displayfirst} = this.props

        const anchorMenu = menuLink.anchor.map((value, index) => {
            return <li key={index} className="header__nav__main__li"> 
                <a data-anchor className={isActive === value ?
                    'header__nav__main__li__link--active' 
                    : "header__nav__main__li__link"} 
                    href={"#"+value} onClick={this.handleClickScroll}>{value}
                    </a>
            </li>
        })
        const pageMenu = menuLink.page ?
            menuLink.page.map((value, index) => {
            const blank = value.target === '_blank' ? "noreferrer noopener" : ""
            return <li key={index} className="header__nav__main__li"> 
                {!value.externe && <Link target={value.target} data-interne='true' className="header__nav__main__li__link" to={value.link} onClick={(e, value)=>this.handleClick(e, value)}>{value.name}</Link>}
                {value.externe && <a target={value.target} rel={blank} className="header__nav__main__li__link" href={value.link} onClick={(e, value)=>this.handleClick(e, value)}>{value.name}</a>}
            </li>
        })
        : ''

        return (
            <MenuContext.Consumer>
                {
                    ({toggle, funcMenu}) => (
                        <nav className='header__nav' role="navigation">
                            <div className="header__nav__logo">
                                <Link onClick={this.handleClickScrollLogo} id={`Logo-${menuLink.id}`} to="/">j<span>34</span>nn<span>3</span> Laloyau
                                    <p>Web Designer<span>.</span></p>
                                </Link>
                            </div>
                            <div className='header__nav__wrapper'>
                                <button aria-label='menu' onClick={funcMenu} type="button" className="header__nav__wrapper__toggle" data-toggle>
                                    <span className="sr-only">Toggle navigation</span>
                                    <span className={toggle ? 'header__nav__wrapper__toggle__icon-bar--open' : 'header__nav__wrapper__toggle__icon-bar'}></span>
                                    <span className={toggle ? 'header__nav__wrapper__toggle__icon-bar--open' : 'header__nav__wrapper__toggle__icon-bar'}></span>
                                    <span className={toggle ? 'header__nav__wrapper__toggle__icon-bar--open' : 'header__nav__wrapper__toggle__icon-bar'}></span>
                                </button>
                            </div>
                            <ul className={toggle ? 'header__nav__main--active' : 'header__nav__main'} id='header__nav__main' data-toggle-menu>
                               {displayfirst && anchorMenu}
                               {displayfirst && pageMenu}
                               {!displayfirst && pageMenu}
                               {!displayfirst && anchorMenu}
                                <li className={`header__nav__main__li`}>
                                    <ThemeButton classLocation={`header__nav__main__li__theme`}></ThemeButton>
                                </li>
                            </ul>
                        </nav>
                    )
                }
            </MenuContext.Consumer>
        )
    }
}

Nav.defaultProps = {
    displayfirst: true
};

Nav.propTypes = {
    displayfirst: PropTypes.bool,
    menuLink: PropTypes.object.isRequired,
};


export default Nav;