import { gsap } from "gsap";
// import { TextPlugin } from "gsap/TextPlugin";
import { TextPlugin, Linear, TimelineMax } from "gsap/all";

gsap.registerPlugin(TextPlugin);

export function generateWords(attr, array) {
    let terme = null;
    terme = document.querySelector(`[${attr}]`)
    var tm = new TimelineMax({ repeat: -1 });
    if (terme){
        let tabWords = array
        for (const word of tabWords) {
            tm.to(terme, 4, {text:word, ease:Linear.easeNone});
        }
    }
}
