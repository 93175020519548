import Nav from '../Nav/Nav'
import React from 'react';
import PropTypes from 'prop-types';
import MenuContext from '../../../MenuContext'

class Header extends React.Component{
    constructor(props){
        super(props)
        this.handleScroll = this.handleScroll.bind(this)
        this.state = {
            isScrolled : false,
            prevScrollY : 0,
            menuDesappear : false
        }
    }
    // useContext hook if func not class : useContext(MyContext);
    static contextType = MenuContext

    componentDidMount(){
        window.addEventListener('scroll', this.handleScroll)
    }

    componentWillUnmount(){
        window.removeEventListener('scroll', this.handleScroll)
    }

        handleScroll(e){
        (window.scrollY < 80) ? 
            this.setState({
                isScrolled: false,
            }) :
            this.setState({
                isScrolled: true,
            })
            if (window.scrollY > 80 && this.state.prevScrollY < window.scrollY) {
            this.setState({
                menuDesappear: true
            })
            }else {
                this.setState({
                    menuDesappear: false
                })
            }
        this.setState({
            prevScrollY: window.scrollY
        })
    }

    render(){
        const {isScrolled, menuDesappear} = this.state
        const {classLocation, displayfirst} = this.props
        const isToggleOn = this.context.toggle
        const scrolledClass = isScrolled || isToggleOn ? "--active" : ""
        const desappearClass = menuDesappear && !isToggleOn ? "--hidden" : ""

        return (
            <header className={`header${scrolledClass}${desappearClass} ${classLocation}${scrolledClass}${desappearClass}`}>
                <Nav menuLink={this.props.menuLink} displayfirst={displayfirst}/>
            </header>
        )
    }
}

export default Header

Header.defaultProps = {
    classLocation: "header-default",
}

Header.propTypes = {
    menuLink: PropTypes.object.isRequired
};