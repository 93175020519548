import React from 'react';
import ImgSrcset from '../../../../component/General/imgSrcset/imgSrcset'
import Intersection from '../../../../component/General/Intersection/Intersection'

import ENTREPRISESWORK from '../../../../content/works/entreprises/entreprisesWorkPart3'

const EntreprisesPart3 = () => {
    return (
        <section className={`entreprises-img`} id={`entreprises-img`}>
             <Intersection>
                 <div className={`entreprises-img__wrapper`}>
                     <div className={`entreprises-img__wrapper__content`}>
                         <ImgSrcset
                             noAnime={true}
                             conf={ENTREPRISESWORK.work.img.generalConf} 
                             specifiqueAnime='-'
                             item={ENTREPRISESWORK.work.img.images[0]} 
                             classLocation={`entreprises-img__wrapper__content__picture`} />
                     </div>
                 </div>
             </Intersection>
         </section>
    )
}

export default EntreprisesPart3;