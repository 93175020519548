import React from 'react'
import PropTypes from 'prop-types';

export const Title = (props) => {
    const {classTitle, titleName, subTitleName, color} = props
    return (
        <div className={`title-default-${color}__wrapper__title ${classTitle}__wrapper__title`}>
            <h2 className={`title-default-${color}__wrapper__title__main ${classTitle}__wrapper__title__main`}>{titleName}</h2>
            <h3 className={`title-default-${color}__wrapper__title__subtitle ${classTitle}__wrapper__title__subtitle`}>{subTitleName}</h3>
        </div>
    )
}

Title.defaultProps = {
    classTitle: 'title-default-primary',
    color: 'primary'
};

Title.propTypes = {
    classTitle: PropTypes.string.isRequired,
    titleName: PropTypes.string.isRequired,
    subTitleName: PropTypes.string.isRequired,
    color: PropTypes.string
};

