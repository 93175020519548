import React from 'react';
import ImgSrcset from '../../General/imgSrcset/imgSrcset';
import ThemeContext from '../../../ThemeContext'
import PropTypes from 'prop-types';

const PanelWorks = (props) => {
    const {locationName, imgPanel} = props
    return (
    <ThemeContext.Consumer>
    {
        ({theme}) => (
            <section className={`${theme ? `default-panel-works--theme-dark ${locationName}--theme-dark` : `default-panel-works--theme-light ${locationName}--theme-light`}`} id={`${locationName}`}>
            <div data-img-line={imgPanel.imgPerLine} className={`default-panel-works__wrapper ${locationName}__wrapper`}>
                {
                imgPanel.images.map((item, index ) => {
                    return(
                    <ImgSrcset key={index} indexNum={index} conf={imgPanel.generalConf} item={item} classLocation2={`default-panel-works__wrapper__content`}classLocation={`${locationName}__wrapper__content`} />
                    )
                })
                }
                </div>
            </section>
        )}
    </ThemeContext.Consumer>
    )
}

PanelWorks.defaultProps = {
    locationName: 'default-panel-works',
};

PanelWorks.propTypes = {
    locationName: PropTypes.string,
    imgPanel: PropTypes.object.isRequired
  };

export default PanelWorks;