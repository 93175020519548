import React from 'react';
import Intersection from '../../General/Intersection/Intersection';
import PropTypes from 'prop-types';
import ImgSrcset from '../../General/imgSrcset/imgSrcset';

const LogoBlock = (props) => {
    const {locationName, title, animeType, imgLogo, descriptionLogo} = props;
    const displayTxt = descriptionLogo.displayTxt
    const displayColor = descriptionLogo.displayColor
    const displayMoreLogos = descriptionLogo.displayMoreLogos
    const arrImgLogo = descriptionLogo.img
    const arrTxtLogo = descriptionLogo.txt

    return (
        <section className={`default-logo-work ${locationName}`} id={`${locationName}`}>
            <div className={`default-logo-work__wrapper ${locationName}__wrapper`}>
                <h3 className={`default-logo-work__wrapper__title ${locationName}__wrapper__title`}>{title}</h3>
                {
                    displayColor && 
                    <Intersection classDiv={`default-logo-work__wrapper__color ${locationName}__wrapper__color`}>
                    <div data-animate={animeType} className={`default-logo-work__wrapper__color ${locationName}__wrapper__color`}>
                        <div className={`default-logo-work__wrapper__color__item ${locationName}__wrapper__color__item`}></div>
                        <div className={`default-logo-work__wrapper__color__item ${locationName}__wrapper__color__item`}></div>
                        <div className={`default-logo-work__wrapper__color__item ${locationName}__wrapper__color__item`}></div>
                        <div className={`default-logo-work__wrapper__color__item ${locationName}__wrapper__color__item`}></div>
                    </div>
                    </Intersection>
                }

                <div className={`default-logo-work__wrapper__logo ${locationName}__wrapper__logo`}>
                <Intersection classDiv={`default-logo-work__wrapper__logo ${locationName}__wrapper__logo`}>
                        {
                        displayTxt && 
                        <div data-animate={animeType} className={`default-logo-work__wrapper__logo__text ${locationName}__wrapper__logo__text`}> 
                        {arrTxtLogo.map((item, index)=> {
                            return(
                                <p key={index}><span>{item.span}</span>{<br></br>}{item.desc}</p>
                            )
                        })}
                        </div>
                        }
                        <ImgSrcset  conf={imgLogo.generalConf} item={imgLogo.images[0]} classLocation={`default-logo-work__wrapper__logo__img ${locationName}__wrapper__logo__img`} />
                    </Intersection>
                    {
                        displayMoreLogos &&
                        <Intersection>
                            <div className={`default-logo-work__wrapper__logo__more ${locationName}__wrapper__logo__more`}> 
                            {arrImgLogo.images.map((item, index)=> {
                                return(
                                    <ImgSrcset key={index} specifiqueAnime={arrImgLogo.images.length===1 ? 'slide-bottom' : index % 2 === 0 ? 'slide-right' : 'slide-left'} conf={arrImgLogo.generalConf} item={item} classLocation={`default-logo-work__wrapper__logo__more__img ${locationName}__wrapper__logo__more__img`} />
                                )
                            })}
                        </div>
                        </Intersection>
                    }
                </div>
            </div>
        </section>
    )
}

LogoBlock.defaultProps = {
    
    locationName: 'default-logo-work',
    title: "title here",
    animeType: 'slide-bottom',
    descriptionLogo: '',
    imgLogo: '',
};

LogoBlock.propTypes = {
    locationName: PropTypes.string,
    title: PropTypes.string.isRequired,
    animeType: PropTypes.string,
    descriptionLogo: PropTypes.object.isRequired,
    imgLogo: PropTypes.object.isRequired,
};

export default LogoBlock;




// const WOKSDESCTEXTLOGO = {
//     scientifil: {
//         displayTxt: true,
//         displayColor: true,
//         txt:[{
//             span: 'Mélange d’images et de lettres : ',
//             desc:'représenter l’apprentissage des enfants qui passent du visuel à l’écriture pour comprendre des concepts.',
//         },{
//             span: 'Trames (croix, points, cercles…) sur les lettres : ',
//             desc:'rappels aux schémas scientifiques utilisés pour exprimer la matière, le volume…',
//         }
//         ],
//         img: {
//             generalConf: {
//                 small: {
//                     media:'max-width: 800px',
//                     sizes:'',
//                     srcSetSize: '336w',
//                 },
//                 large: {
//                     media:'min-width: 801px',
//                     sizes:'',
//                     srcSetSize: '537w',
//                 },
//                 default: {
//                     animeNameAttr:'slide-bottom',
//                     animeTimeAttr:.2,
//                 }
//             },
//             images:[{
//                 small: '',
//                 smallx2: '',
//                 large: '',
//                 largex2: '',
//                 default: '',
//                 alt:''
//             }
//             ]
//         }
//     },
//     downtrigger: {
//         displayTxt: false,
//         displayColor: false,
//         txt:[{
//             span: '/',
//             desc:'/',
//         }
//         ],
//         img: {
//             generalConf: {
//                 small: {
//                     media:'max-width: 800px',
//                     sizes:'',
//                     srcSetSize: '336w',
//                 },
//                 large: {
//                     media:'min-width: 801px',
//                     sizes:'',
//                     srcSetSize: '537w',
//                 },
//                 default: {
//                     animeNameAttr:'slide-bottom',
//                     animeTimeAttr:.2,
//                 }
//             },
//             images:[{
//                 small: '/images/down_trigger/downtrigger-2.png',
//                 smallx2: '/images/down_trigger/downtrigger-2.png',
//                 large: '/images/down_trigger/downtrigger-2.png',
//                 largex2: '/images/down_trigger/downtrigger-2.png',
//                 default: '/images/down_trigger/downtrigger-2.png',
//                 alt:'Down trigger logo 2'
//             },{
//                 small: '/images/down_trigger/downtrigger-3.png',
//                 smallx2:'/images/down_trigger/downtrigger-3.png',
//                 large: '/images/down_trigger/downtrigger-3.png',
//                 largex2:'/images/down_trigger/downtrigger-3.png',
//                 default:'/images/down_trigger/downtrigger-3.png',
//                 alt:'Down trigger logo 3'
//             }
//             ]
//         }
//     }
// }

// export default WOKSDESCTEXTLOGO;
