import React from 'react';
// import PropTypes from 'prop-types';
import Intersection from "../Intersection/Intersection"
import Button from "../Button/Button"
import SpanParser from '../spanParser'

import ERRORGENERAL from '../../../content/error/errorGeneral'

class Error extends React.Component{
    render(){
        return (
            <section id="error" className="error">
                <div className="error__wrapper">
                        <Intersection classDiv="error__wrapper__content">
                            <div className="error__wrapper__content__infos" data-animate="slide-bottom" >
                                <h1 className="error__wrapper__content__infos__title">{ERRORGENERAL.title}</h1>
                                <h2 className="error__wrapper__content__infos__text">
                                    <SpanParser phrase={ERRORGENERAL.text}></SpanParser>
                                </h2>
                                <Button  isSameDomaine={true} isPrimary={true} target={false} hrefLink="/" isAnchor={false} isButton={false} classButtonName="error__wrapper__content__infos__link" idButtonName="button-accueil" inText={ERRORGENERAL.button}></Button>
                            </div>
                        </Intersection>
                 </div>
            </section>
        )
        
    }
}

export default Error

// Error.defaultProps = {

// };

// Error.propTypes = {

// };

