
import React from 'react';

const Loader = () => 
(
        <div className="loader-spin">
          <div className="loader-spin__text-1">{"{"}</div>
            <div className="loader-spin__bubbles">
              <div className="loader-spin__bubbles__bubble-move"></div>
              <div className="loader-spin__bubbles__bubble"></div>
              <div className="loader-spin__bubbles__bubble"></div>
            </div>
          <div className="loader-spin__text-2">{"}"}</div>
        </div>
)

export default Loader
