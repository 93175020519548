
const WOKSDESCTEXTLOGO = {
    scientifil: {
        displayGen: true,
        displayTxt: true,
        displayColor: true,
        displayMoreLogos: false,
        txt:[{
            span: 'Mélange d’images et de lettres : ',
            desc:'représenter l’apprentissage des enfants qui passent du visuel à l’écriture pour comprendre des concepts.',
        },{
            span: 'Trames (croix, points, cercles…) sur les lettres : ',
            desc:'rappels aux schémas scientifiques utilisés pour exprimer la matière, le volume…',
        }
        ],
        img: {
            generalConf: {
                small: {
                    media:'max-width: 800px',
                    sizes:'',
                    srcSetSize: '336w',
                },
                large: {
                    media:'min-width: 801px',
                    sizes:'',
                    srcSetSize: '537w',
                },
                default: {
                    animeNameAttr:'slide-bottom',
                    animeTimeAttr:.2,
                }
            },
            images:[{
                small: '',
                smallx2: '',
                large: '',
                largex2: '',
                default: '',
                alt:''
            }
            ]
        }
    },
    downtrigger: {
        displayGen: true,
        displayTxt: false,
        displayColor: false,
        displayMoreLogos: true,
        txt:[{
            span: '/',
            desc:'/',
        }
        ],
        img: {
            generalConf: {
                small: {
                    media:'max-width: 800px',
                    sizes:'',
                    srcSetSize: '336w',
                },
                large: {
                    media:'min-width: 801px',
                    sizes:'',
                    srcSetSize: '537w',
                },
                default: {
                    animeNameAttr:'slide-bottom',
                    animeTimeAttr:.2,
                }
            },
            images:[{
                small: '/images/down_trigger/downtrigger-2.png',
                smallx2: '/images/down_trigger/downtrigger-2.png',
                large: '/images/down_trigger/downtrigger-2.png',
                largex2: '/images/down_trigger/downtrigger-2.png',
                default: '/images/down_trigger/downtrigger-2.png',
                alt:'Down trigger logo 2'
            },{
                small: '/images/down_trigger/downtrigger-3.png',
                smallx2:'/images/down_trigger/downtrigger-3.png',
                large: '/images/down_trigger/downtrigger-3.png',
                largex2:'/images/down_trigger/downtrigger-3.png',
                default:'/images/down_trigger/downtrigger-3.png',
                alt:'Down trigger logo 3'
            }
            ]
        }
    },
    estories: {
        displayGen: true,
        displayTxt: true,
        displayColor: true,
        displayMoreLogos: true,
        txt:[{
            span: 'Mélange de symboles :',
            desc:'Le livre, la localisation et le chemin, pour constituer le e de e-stories',
        }
        ],
        img: {
            generalConf: {
                small: {
                    media:'max-width: 800px',
                    sizes:'',
                    srcSetSize: '336w',
                },
                large: {
                    media:'min-width: 801px',
                    sizes:'',
                    srcSetSize: '537w',
                },
                default: {
                    animeNameAttr:'slide-bottom',
                    animeTimeAttr:.2,
                }
            },
            images:[{
                small: '/images/estorie/e-stories-logo-3.png',
                smallx2:'/images/estorie/e-stories-logo-3.png',
                large: '/images/estorie/e-stories-logo-3.png',
                largex2: '/images/estorie/e-stories-logo-3.png',
                default:'/images/estorie/e-stories-logo-3.png',
                alt:''
            }
            ]
        }
    },
    orange: {
        displayGen: false,
        displayTxt: false,
        displayColor: false,
        displayMoreLogos: false,
        txt:[{
            span: '',
            desc:'',
        }
        ],
        img: {
            generalConf: {
                small: {
                    media:'max-width: 800px',
                    sizes:'',
                    srcSetSize: '336w',
                },
                large: {
                    media:'min-width: 801px',
                    sizes:'',
                    srcSetSize: '537w',
                },
                default: {
                    animeNameAttr:'slide-bottom',
                    animeTimeAttr:.2,
                }
            },
            images:[{
                small:   '',
                smallx2: '',
                large:   '',
                largex2: '',
                default: '',
                alt:''
            }
            ]
        }
    },
    meowbio: {
        displayGen: true,
        displayTxt: false,
        displayColor: false,
        displayMoreLogos: true,
        txt:[{
            span: 'Miaou :',
            desc:'Miaou, miaou.',
        }
        ],
        img: {
            generalConf: {
                small: {
                    media:'max-width: 800px',
                    sizes:'',
                    srcSetSize: '336w',
                },
                large: {
                    media:'min-width: 801px',
                    sizes:'',
                    srcSetSize: '537w',
                },
                default: {
                    animeNameAttr:'slide-bottom',
                    animeTimeAttr:.2,
                }
            },
            images:[{
                small:   '/images/meowbio/cat-color.png',
                smallx2: '/images/meowbio/cat-color.png',
                large:   '/images/meowbio/cat-color.png',
                largex2: '/images/meowbio/cat-color.png',
                default: '/images/meowbio/cat-color.png',
                alt:''
            },{
                small:   '/images/meowbio/cat-color-2.png',
                smallx2: '/images/meowbio/cat-color-2.png',
                large:   '/images/meowbio/cat-color-2.png',
                largex2: '/images/meowbio/cat-color-2.png',
                default: '/images/meowbio/cat-color-2.png',
            }
            ]
        }
    },
    illustrations: {
        displayGen: false,
        displayTxt: false,
        displayColor: false,
        displayMoreLogos: false,
        txt:[{
            span: '',
            desc:'',
        }
        ],
        img: {
            generalConf: {
                small: {
                    media:'max-width: 800px',
                    sizes:'',
                    srcSetSize: '336w',
                },
                large: {
                    media:'min-width: 801px',
                    sizes:'',
                    srcSetSize: '537w',
                },
                default: {
                    animeNameAttr:'slide-bottom',
                    animeTimeAttr:.2,
                }
            },
            images:[{
                small:   '',
                smallx2: '',
                large:   '',
                largex2: '',
                default: '',
                alt:''
            }
            ]
        }
    },
    entreprises: {
        displayGen: false,
        displayTxt: false,
        displayColor: false,
        displayMoreLogos: false,
        txt:[{
            span: '',
            desc:'',
        }
        ],
        img: {
            generalConf: {
                small: {
                    media:'max-width: 800px',
                    sizes:'',
                    srcSetSize: '336w',
                },
                large: {
                    media:'min-width: 801px',
                    sizes:'',
                    srcSetSize: '537w',
                },
                default: {
                    animeNameAttr:'slide-bottom',
                    animeTimeAttr:.2,
                }
            },
            images:[{
                small:   '',
                smallx2: '',
                large:   '',
                largex2: '',
                default: '',
                alt:''
            }
            ]
        }
    },
    freelance: {
        displayGen: false,
        displayTxt: false,
        displayColor: false,
        displayMoreLogos: false,
        txt:[{
            span: '',
            desc:'',
        }
        ],
        img: {
            generalConf: {
                small: {
                    media:'max-width: 800px',
                    sizes:'',
                    srcSetSize: '336w',
                },
                large: {
                    media:'min-width: 801px',
                    sizes:'',
                    srcSetSize: '537w',
                },
                default: {
                    animeNameAttr:'slide-bottom',
                    animeTimeAttr:.2,
                }
            },
            images:[{
                small:   '',
                smallx2: '',
                large:   '',
                largex2: '',
                default: '',
                alt:''
            }
            ]
        }
    },
}

export default WOKSDESCTEXTLOGO;
