import React from 'react';
import ImgSrcset from '../../../../component/General/imgSrcset/imgSrcset'
import Intersection from '../../../../component/General/Intersection/Intersection'

import MEOWBIOWORK from '../../../../content/works/meowbio/meowbioWorkPart3'

const MeowbioPart3 = () => {

    return (
        <div>
        <section className="meowbio-screen" id="meowbio-screen">
        <Intersection>
            <div className="meowbio-screen__wrapper">
                    <h3 className="meowbio-screen__wrapper__title" >{MEOWBIOWORK.screen.title}</h3>
                    <div className='meowbio-screen__wrapper__content'>
                    {MEOWBIOWORK.screen.img.images.map((item, index)=> {
                        return ( <ImgSrcset key={index}
                            conf={MEOWBIOWORK.screen.img.generalConf} 
                            specifiqueAnime='slide-bottom'
                            indexNum={index}
                            item={item} classLocation="meowbio-screen__wrapper__content__picture" />)
                    })}
                    </div>
            </div>
        </Intersection>
        </section>
        <section className="meowbio-wireframe" id="meowbio-wireframe">
        <Intersection>
            <div className="meowbio-wireframe__wrapper">
                <h3 className="meowbio-wireframe__wrapper__title" >{MEOWBIOWORK.wireframe.title}</h3>
                <div className='meowbio-wireframe__wrapper__content'>
                {MEOWBIOWORK.wireframe.img.images.map((item, index)=> {
                        return ( <ImgSrcset key={index}
                            conf={MEOWBIOWORK.wireframe.img.generalConf} 
                            item={item} classLocation="meowbio-wireframe__wrapper__content__picture" />)
                    })}
                </div>
            </div>
        </Intersection>
        </section>
        <section className="meowbio-seo" id="meowbio-seo">
        <Intersection>
            <div className="meowbio-seo__wrapper">
                <h3 className="meowbio-seo__wrapper__title" >{MEOWBIOWORK.seo.title}</h3>
                <div className='meowbio-seo__wrapper__content'>
                {MEOWBIOWORK.seo.img.images.map((item, index)=> {
                        return ( <ImgSrcset key={index}
                            conf={MEOWBIOWORK.seo.img.generalConf} 
                            item={item} classLocation="meowbio-seo__wrapper__content__picture" />)
                    })}
                </div>
            </div>
        </Intersection>
        </section>
        </div>
    )
}

export default MeowbioPart3;