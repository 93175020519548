import React from 'react';
import {Title} from '../Title/Title'
import Intersection from '../Intersection/Intersection'
import Input from './Input/Input'
import TextArea from './Input/TextArea'
import Loading from '../Loader/Loader'
import SpanParser from '../spanParser'

class Contact extends React.Component {
    constructor(props) {
        super(props)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.updateState = this.updateState.bind(this)
        this.updateName = this.updateName.bind(this)
        this.updateEmail = this.updateEmail.bind(this)
        this.updateEmail2 = this.updateEmail2.bind(this)
        this.updateMessage = this.updateMessage.bind(this)
        this.updateSubject = this.updateSubject.bind(this)
        this.updateAgence = this.updateAgence.bind(this)
        this.isValidEmailAddress = this.isValidEmailAddress.bind(this)
        this.isValidName = this.isValidName.bind(this)
        this.onSubmit = this.onSubmit.bind(this)
        this.ajaxPost = this.ajaxPost.bind(this)
        this.onLoad = this.onLoad.bind(this)
        this.refemail2 = React.createRef()
        // this.onSubmit = this.onSubmit.bind(this)

        this.state = {
            errors: {
                name: '',
                email2:'',
                agence:'',
                email: '',
                subject: '',
                message: '',
              },
            agence:'',
            subject: '',
            email: '',
            email2: '',
            message:'',
            name:'',
            loadingrep:false
        }
    }
    componentDidMount(){
        const script = document.createElement("script");
        script.src = "https://www.google.com/recaptcha/api.js?onload=onloadCallback&render=explicit";
        script.async = true;
        script.defer = true;
        document.body.appendChild(script);
        const script2 = document.createElement('script');
        script2.text = `
        var onloadCallback = function() {
            // console.log('grecaptcha is ready');
        };
        `;
        document.body.appendChild(script2);
        window.addEventListener("load", this.onLoad);
    }

    ajaxPost(url, data, callback, isJson){
        var req = new XMLHttpRequest();
        req.open("POST", url);
        req.addEventListener("readystatechange", function(){
            if(req.readyState === XMLHttpRequest.DONE && (req.status >= 200 && req.status < 400)){
                callback(req.responseText);
            }else if(req.readyState === XMLHttpRequest.DONE && !(req.status >= 200 && req.status < 400)){
                console.error(req.status + " " + req.statusText + " " + url);
            }
        });
        req.addEventListener("error", function(){
            console.error("erreur de chargement "+ url);
        })
        if (isJson){
            req.setRequestHeader("Content-Type", "application/json");
            data = JSON.stringify(data);
        }
        req.send(data);
    }

    onLoad = () => {
        if (window.grecaptcha) {
            window.grecaptcha.render("recaptcha", {
                sitekey: "6LcHgNcUAAAAABL_dNpzmRAZEzCYeg97IPF9a9DN",
                size: 'invisible',
                callback: this.onSubmit
            });
        }
    };

    onSubmit(token){
        const {name, email, errors, agence, message, subject, email2 } = this.state
        const ajaxPost = this.ajaxPost
        // const self = this
        this.setState({
            loadingrep : false
        })
        if(errors.email2 || this.refemail2.current.value || email2) {
            return
        } else {
            return new Promise(function(resolve, reject) {
                    if (window.grecaptcha === undefined) {
                        const responseAjax =  document.querySelector('#contact-form .ajax-response');
                        responseAjax.innerHTML('Problème recaptcha.');
                    //return;
                    reject();
                    }
                    
                    var recapResponse = window.grecaptcha.getResponse();
                    if (!recapResponse) {
                        const responseAjax =  document.querySelector('#contact-form .ajax-response');
                        responseAjax.innerHTML('Problème recaptcha.');
                    //return;
                    reject();
                    }else {
                        var formData = {
                            'name': name,
                            'email': email,
                            'email2': errors.email2,
                            'agence': agence,
                            'message': message,
                            'subject': subject,
                            'g-recaptcha-response' : recapResponse
                        };
                        const API_PATH = '/api/contact.php';
                        ajaxPost(API_PATH, formData, function(response){
                            const responseAjax =  document.querySelector('#contact-form .ajax-response');
                            const stringResponse = JSON.parse(response);
                            if(stringResponse.sendstatus) {
                                responseAjax.classList.add('active')
                                responseAjax.textContent = stringResponse.message
                                setTimeout(()=> {
                                    responseAjax.classList.remove('active')
                                    responseAjax.textContent = ""
                                }, 5000)
                            } else {
                                responseAjax.classList.add('active-error')
                                responseAjax.textContent = stringResponse.message
                                setTimeout(()=> {
                                    responseAjax.classList.remove('active-error')
                                    responseAjax.textContent = ""
                                }, 5000)
                            }
                        }, true);
                    window.grecaptcha.reset();
                    }
            }); //end promise
        }
    }

    isValidEmailAddress(emailAddress) {
        // eslint-disable-next-line
        const pattern = new RegExp(/^((([a-z]|\d|[!#$%&'*+\-/=?^_`{|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#$%&'*+\-/=?^_`{|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))){2}\.?$/i);
        return pattern.test(emailAddress);
    };

    isValidName(name) {
        const pattern = new RegExp(/^[a-zA-Zéçàèêôôöïîùë]+(([' -][a-zA-Zéçàèêôôöïîùë])?[a-zA-Zéçàèêôôöïîùë]*)*$/)
        return pattern.test(name)
    };

    updateName(value, error){
        const isValid = this.isValidName(value);
        if(value.length < 3 || value.length >= 30 || !isValid) {
            error.name = this.props.contactFormProp.input.name.error
        } else {
            error.name = ""
        }
    }

    updateEmail(value, error){
        const isValid = this.isValidEmailAddress(value);
        if(isValid) {
            error.email = ""
        } else {
            error.email = this.props.contactFormProp.input.email.error
        }
    }

    updateEmail2(value, error){
        if(value !== '') {
            error.email2 = "error"
        } else {
            error.email2 = ""
        }
    }

    updateMessage(value, error){
        if(value.length < 70) {
            error.message = this.props.contactFormProp.input.message.error
        } else if(value.length > 800) {
            error.message = this.props.contactFormProp.input.message.error2
        } else {
            error.message = ""
        }
    }

    updateSubject(value, error){
        if(value.length < 3 || value.length > 80) {
            error.subject = this.props.contactFormProp.input.subject.error
        } else {
            error.subject = ""
        }
    }

    updateAgence(value, error){
        if(value.length > 40) {
            error.agence = this.props.contactFormProp.input.agence.error
        } else {
            error.agence = ""
        }
    }

    updateState(e){
        const { name, value } = e.target; // const name = e.target.name; const value = e.target.value;
        let error = this.state.errors;
        switch(name){
            case'name':
                this.updateName(value, error);
            break
            case'email2':
                this.updateEmail2(value, error);
            break
            case'agence':
            this.updateAgence(value, error);
            break
            case'email':
                this.updateEmail(value, error);
            break
            case'subject':
            this.updateSubject(value, error);
        break
            case'message':
                this.updateMessage(value, error);
            break
            default:
            break
        }
        this.setState({
            errors : error,
            [name] : value
        })
    }

    handleSubmit(e) {
        this.setState({
            loadingrep : true
        })
        e.preventDefault();
        const {name, email, errors, message, subject } = this.state
        if(!errors.email && !errors.message && !errors.name && !errors.subject && email && name && subject && message) {
          window.grecaptcha.execute();
        }else{
            const responseAjax =  document.querySelector('#contact-form .ajax-response');
            responseAjax.classList.add('active-error')
            responseAjax.textContent = this.props.contactFormProp.infosGen.error
            this.setState({
                loadingrep : false
            })
            setTimeout(()=> {
                responseAjax.classList.remove('active-error')
                responseAjax.textContent = ""
            }, 5000)
        }
    }

    render() {
        const {errors, email, message, name, subject, agence, response, email2, loadingrep} = this.state
        const {contactFormProp} = this.props
        return (
            <section id="contact" className="contact">
                <div className="contact__wrapper">
                    <Title classTitle='contact' titleName={contactFormProp.infosGen.title} subTitleName={contactFormProp.infosGen.subTitle}></Title>
                    <Intersection classDiv="contact__wrapper__intro">
                        <h3 data-animate="slide-bottom" className="contact__wrapper__intro__text">
                            <SpanParser phrase={contactFormProp.infosGen.txt}></SpanParser>
                        </h3>
                    </Intersection>
                    <Intersection classDiv="contact__wrapper__form">
                        <form action="" data-animate="slide-bottom" method="POST" className="contact__wrapper__form__content" id="contact-form">
                            <Input minLen={3} maxLen={30} patternReg={"^[a-zA-Zéçàèêôôöïîùë]+(([' -][a-zA-Zéçàèêôôöïîùë])?[a-zA-Zéçàèêôôöïîùë]*)*$"} value={name} handleChange={this.updateState} error={errors.name} type="text" label={contactFormProp.input.name.label} placeholder={contactFormProp.input.name.placeholder} locationName="contact__wrapper__form__content__input" id={contactFormProp.input.name.id}></Input>
                            <Input maxLen={40} value={agence} handleChange={this.updateState} required={false} error={errors.agence} type="text" label={contactFormProp.input.agence.label} placeholder={contactFormProp.input.agence.placeholder} locationName="contact__wrapper__form__content__input" id={contactFormProp.input.agence.id}></Input>
                            <Input value={email2} ref={this.refemail2} handleChange={this.updateState} required={false} error="" type="hidden" label="Email2" placeholder="Email" locationName="contact__wrapper__form__content__input" id="email2"></Input>
                            <Input maxLen={100} minLen={5} value={email} handleChange={this.updateState} error={errors.email} type="email" label={contactFormProp.input.email.label} placeholder={contactFormProp.input.email.placeholder} locationName="contact__wrapper__form__content__input" id={contactFormProp.input.email.id}></Input>
                            <Input minLen={3} maxLen={80} value={subject} handleChange={this.updateState} error={errors.subject} type="text" label={contactFormProp.input.subject.label} placeholder={contactFormProp.input.subject.placeholder} locationName="contact__wrapper__form__content__input" id={contactFormProp.input.subject.id}></Input>
                            <TextArea minLen={70} maxLen={800} value={message} handleChange={this.updateState} error={errors.message} type="Message" label={contactFormProp.input.message.label} placeholder={contactFormProp.input.message.placeholder} locationName="contact__wrapper__form__content__input" id={contactFormProp.input.message.id}></TextArea>
                            {loadingrep && <Loading></Loading>}
                            <button onClick={this.handleSubmit} id={contactFormProp.input.button.id} type="submit" className="contact__wrapper__form__content__button">{contactFormProp.input.button.txt}</button>
                            <div className="g-recaptcha contact__wrapper__form__content__recaptcha" id="recaptcha"></div>
                            <div className="ajax-response">{response}</div>
                        </form>
                    </Intersection>
                </div>
            </section>
        )
    }
}

export default Contact;
