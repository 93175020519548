const GENERALSLIDERCONTENT = {
    title: 'Autres travaux',
    titlePage: 'Mes réalisations',
    liWorks : [
      {title: "Scientifil Mag", 
      image: {
        large: "/images/slider-work/scientifil/250x250-large-scientifil.png",
        largex2: "/images/slider-work/scientifil/500x500-large2x-scientifil.png",
        small: "/images/slider-work/scientifil/180x180-small-scientifil.png",
        smallx2: "/images/slider-work/scientifil/360x360-small2x-scientifil.png"
      },
      text: "Le trajet des aliments dans notre corps.",linkTo: "/realisations-scientifil", attr:'scientifil'},
      {title: "Down Trigger",
      image: {
        large: "/images/slider-work/downtrigger/250x250-large-downtrigger.png",
        largex2: "/images/slider-work/downtrigger/500x500-large2x-downtrigger.png",
        small: "/images/slider-work/downtrigger/180x180-small-downtrigger.png",
        smallx2: "/images/slider-work/downtrigger/360x360-small2x-downtrigger.png"
      },
      text: "Création d'une pochette d'album.",linkTo: "/realisations-downtrigger", attr:'downtrigger'},
      {title: "Freelance",
      image: {
        large: "/images/slider-work/free/250x250-large-free.png",
        largex2: "/images/slider-work/free/500x500-large2x-free.png",
        small: "/images/slider-work/free/180x180-small-free.png",
        smallx2: "/images/slider-work/free/360x360-small2x-free.png"
      },
      text: "Divers projets réalisés en freelance.",linkTo: "/realisations-free", attr:'freelance'},
      {title: "Wamiz/ONPC",
      image: {
        large: "/images/slider-work/wamiz/250x250-large-wamiz.png",
        largex2: "/images/slider-work/wamiz/500x500-large2x-wamiz.png",
        small: "/images/slider-work/wamiz/180x180-small-wamiz.png",
        smallx2: "/images/slider-work/wamiz/360x360-small2x-wamiz.png"
      },
      text: "Diverses réalisations effectuées en entreprises.",linkTo: "/realisations-entreprises", attr:'work'},
      {title: "Illustrations",
      image: {
        large: "/images/slider-work/illustrations/250x250-large-illustrations.png",
        largex2: "/images/slider-work/illustrations/500x500-large2x-illustrations.png",
        small: "/images/slider-work/illustrations/180x180-small-illustrations.png",
        smallx2: "/images/slider-work/illustrations/360x360-small2x-illustrations.png"
      },
      text: "Digital painting, dessins vectoriels...",linkTo: "/realisations-illu", attr:'illustrations'},
      {title: "e_storie",
      image: {
        large: "/images/slider-work/estories/250x250-large-estories.png",
        largex2: "/images/slider-work/estories/500x500-large2x-estories.png",
        small: "/images/slider-work/estories/180x180-small-estories.png",
        smallx2: "/images/slider-work/estories/360x360-small2x-estories.png"
      },
      text: "App pour découvrir les légendes des villes.",linkTo: "/realisations-estories", attr:'estories'},
      {title: "orange",
      image: {
        large: "/images/slider-work/orange/250x250-large-orange.png",
        largex2: "/images/slider-work/orange/500x500-large2x-orange.png",
        small: "/images/slider-work/orange/180x180-small-orange.png",
        smallx2: "/images/slider-work/orange/360x360-small2x-orange.png"
      },
      text: "Participation à la création de l'espace Orange Developer.",linkTo: "/realisations-orange", attr:'orange'},
      {title: "Meow Bio",
      image: {
        large: "/images/slider-work/meowbio/250x250-large-meowbio.png",
        largex2: "/images/slider-work/meowbio/500x500-large2x-meowbio.png",
        small: "/images/slider-work/meowbio/180x180-small-meowbio.png",
        smallx2: "/images/slider-work/meowbio/360x360-small2x-meowbio.png"
      },
      text: "Vente d'alimentation bio pour chats.",linkTo: "/realisations-meowbio", attr:'meowbio'}
    ]
}
export default GENERALSLIDERCONTENT;