
const WORKSHEADERDESC = {
    scientifil: {
      title: {name1: "Projet ", name2:"Scientifil"},
      legend: {title: "Design UI/UX", date:"2014",  text:"Projet d'adaptation interactive d'un magazine (Les docs des Incollables - Playbac) pour apprendre la science aux enfants"},
      generalConf: {
        small: {
            media:'max-width: 800px',
            sizes:'',
            srcSetSize: '336w',
        },
        large: {
            media:'min-width: 801px',
            sizes:'',
            srcSetSize: '537w',
        },
        default: {
            animeNameAttr:'slide-bottom',
            animeTimeAttr:.2,
        }
    },
        images:[{
            small: '/images/scientifil/scientifil-accueil.png',
            smallx2:'/images/scientifil/scientifil-accueil.png',
            large: '/images/scientifil/scientifil-accueil.png',
            largex2:'/images/scientifil/scientifil-accueil.png',
            default:'/images/scientifil/scientifil-accueil.png',
            alt:''
        }],
      quote: "Utiliser les différentes possibilités d’interaction sur une tablette afin de donner aux enfants une expérience à la fois éducative et amusante !",
      description: [
        {
          title: "Rôle", 
          text:["UX designer","UI designer"]
        },
        {
          title: "Date", 
          text:["2014", "(projet scolaire)"]
        },
        {
          title: "Outils", 
          text:["PS","AI", "Dessin"]
        },
        {
          title: "Platforme", 
          text:["App tablette"]
        },
        {
          title: "Process", 
          text:["Expression des besoins","Benchmark et Préconisations","Wireframing","User Experience","User Interface Design"]
        }
      ]
    },
    downtrigger: {
      title: {name1: "Down ", name2:"Trigger"},
      legend: {title: "Illustration", date:"2019",  text:"Illustration, logo, graphisme pour un album de Métal"},
      generalConf: {
        small: {
            media:'max-width: 800px',
            sizes:'',
            srcSetSize: '300w',
        },
        large: {
            media:'min-width: 801px',
            sizes:'',
            srcSetSize: '650w',
        },
        default: {
            animeNameAttr:'slide-bottom',
            animeTimeAttr:.2,
        }
    },
        images:[{
          // large: "/images/slider-work/downtrigger/250x250-large-downtrigger.png",
          // largex2: "/images/slider-work/downtrigger/500x500-large2x-downtrigger.png",
          // small: "/images/slider-work/downtrigger/180x180-small-downtrigger.png",
          // smallx2: "/images/slider-work/downtrigger/360x360-small2x-downtrigger.png",
          // default:'/images/slider-work/downtrigger/500x500-large2x-downtrigger.png',
          // alt:'
          large: '/images/estorie/estorie-perso-empty.png',
          largex2: '/images/estorie/estorie-perso-empty.png',
          small: '/images/estorie/estorie-perso-empty.png',
          smallx2: '/images/estorie/estorie-perso-empty.png',
          default:'/images/estorie/estorie-perso-empty.png',
          alt:''
        }],
      quote: "Gribouiller au rythme du son pour faire une belle promotion, Et à l'instar de Dédale, scultons l'image du Métal.",
      description: [
        {
          title: "Rôle", 
          text:["Illustrateur","Graphiste"]
        },
        {
          title: "Date", 
          text:["2019", "(projet freelance)"]
        },
        {
          title: "Outils", 
          text:["PS", "digital painting"]
        },
        {
          title: "Support", 
          text:["CD + Livret"]
        },
        {
          title: "Process", 
          text:["Brainstorming","Dessin traditionnel","Digital Painting","Mise en page Graphique"]
        }
      ]
    },
    estories: {
      title: {name1: "e- ", name2:"stories"},
      legend: {title: "Design UI/UX", date:"2016",  text:"Projet de design & gamification pour découvrir les villes"},
      generalConf: {
        small: {
            media:'max-width: 800px',
            sizes:'',
            srcSetSize: '336w',
        },
        large: {
            media:'min-width: 801px',
            sizes:'',
            srcSetSize: '537w',
        },
        default: {
            animeNameAttr:'slide-bottom',
            animeTimeAttr:.2,
        }
    },
        images:[{
            small: '/images/estorie/e-stories-accueil.png',
            smallx2:'/images/estorie/e-stories-accueil.png',
            large: '/images/estorie/e-stories-accueil.png',
            largex2:'/images/estorie/e-stories-accueil.png',
            default:'/images/estorie/e-stories-accueil.png',
            alt:''
        }],
      quote: "L'Histoire c'est génial, mais l'histoire par coeur c'est pas l'idéal... Loin des vieux livres du fond de la bibliothèque desertée les jours d'été, e-stories a pour vocation de faire arpenter rues et avenues à la découverte de faits insolites... mais historiques !",
      description: [
        {
          title: "Rôle", 
          text:["UX designer","UI designer"]
        },
        {
          title: "Date", 
          text:["2015/2016", "(projet scolaire - non terminé)"]
        },
        {
          title: "Outils", 
          text:["PS","AI", "dessin traditionnel", "wordpress"]
        },
        {
          title: "Platforme", 
          text:["App mobile", "site web responsive"]
        },
        {
          title: "Process", 
          text:["Brainstorming", "Wireframing", "User Experience","User Interface Design"]
        }
      ]
    },
    orange: {
      title: {name1: "projet ", name2:"Orange"},
      legend: {title: "Design UI/UX", date:"2015",  text:"Participation à la création de l'espace Orange Developer"},
      generalConf: {
        small: {
            media:'max-width: 800px',
            sizes:'',
            srcSetSize: '336w',
        },
        large: {
            media:'min-width: 801px',
            sizes:'',
            srcSetSize: '537w',
        },
        default: {
            animeNameAttr:'slide-bottom',
            animeTimeAttr:.2,
        }
    },
        images:[{
            small:   '/images/orange/orange-accueil.png',
            smallx2: '/images/orange/orange-accueil.png',
            large:   '/images/orange/orange-accueil.png',
            largex2: '/images/orange/orange-accueil.png',
            default: '/images/orange/orange-accueil.png',
            alt:''
        }],
      quote: "Un travail d'équipe pour réaliser le dashboard d'Orange Developer afin d'implémenter et de suivre les APIs d'Orange.",
      description: [
        {
          title: "Rôle", 
          text:["UX designer","UI designer"]
        },
        {
          title: "Date", 
          text:["2014/2015"]
        },
        {
          title: "Outils", 
          text:["PS","AI", "Axure"]
        },
        {
          title: "Platforme", 
          text:["Site web"]
        },
        {
          title: "Process", 
          text:["Wireframing", "User Experience","User Interface Design"]
        }
      ]
    },
    meowbio: {
      title: {name1: "Meow ", name2:"Bio"},
      legend: {title: "Design UI/UX", date:"2015",  text:"Création du design d'un site e-commerce, proposant des produits bio pour les chats."},
      generalConf: {
        small: {
            media:'max-width: 800px',
            sizes:'',
            srcSetSize: '336w',
        },
        large: {
            media:'min-width: 801px',
            sizes:'',
            srcSetSize: '537w',
        },
        default: {
            animeNameAttr:'slide-bottom',
            animeTimeAttr:.2,
        }
    },
        images:[{
            small:  '/images/meowbio/meowbio-accueil.png',
            smallx2:'/images/meowbio/meowbio-accueil.png',
            large:  '/images/meowbio/meowbio-accueil.png',
            largex2:'/images/meowbio/meowbio-accueil.png',
            default:'/images/meowbio/meowbio-accueil.png',
            alt:''
        }],
      quote: "Design emotionnel : un compagnon plutôt expressif accompagne son nouveau maître dans les rayons virtuels. Il apparaît sur certaines pages, ne laissant parfois que pour seul indice un bout de ses oreilles...",
      description: [
        {
          title: "Rôle", 
          text:["UX designer","UI designer"]
        },
        {
          title: "Date", 
          text:["2015", "(projet scolaire)"]
        },
        {
          title: "Outils", 
          text:["PS","AI"]
        },
        {
          title: "Platforme", 
          text:["Site web"]
        },
        {
          title: "Process", 
          text:["Expression des besoins","Benchmark et Préconisations","Stratégie commerciale", "Wireframing","User Experience","User Interface Design", "Préconisations SEM"]
        }
      ]
    },
    illustrations: {
      title: {name1: "illu ", name2:"en vrac"},
      legend: {title: "Illustrations", date:"2016/2019",  text:"Création de diverses illustrations pour des clients, ou le plaisir !"},
      generalConf: {
        small: {
            media:'max-width: 800px',
            sizes:'',
            srcSetSize: '336w',
        },
        large: {
            media:'min-width: 801px',
            sizes:'',
            srcSetSize: '537w',
        },
        default: {
            animeNameAttr:'slide-bottom',
            animeTimeAttr:.2,
        }
    },
        images:[{
            small:  '/images/illu/illu-accueil.png',
            smallx2:'/images/illu/illu-accueil.png',
            large:  '/images/illu/illu-accueil.png',
            largex2:'/images/illu/illu-accueil.png',
            default:'/images/illu/illu-accueil.png',
            alt:''
        }],
      quote: "La création d'images accompagne mon métier de Webdesigner, je vous propose donc un petit aperçu des travaux effectués aussi bien pour le plaisir, l'entraînement, mais aussi suite à des commandes.",
      description: [
        {
          title: "Rôle", 
          text:["Illustrateur"]
        },
        {
          title: "Date", 
          text:["2016/2019"]
        },
        {
          title: "Outils", 
          text:["PS","AI", "Blender"]
        },
        {
          title: "Platforme", 
          text:["Livres", "Affiches"]
        },
        {
          title: "Process", 
          text:["Brainstorming","Dessin traditionnel","Digital Painting", "Modeling"]
        }
      ]
    },
    entreprises: {
      title: {name1: "Wamiz ", name2:"ONPC"},
      legend: {title: "ONPC/Wamiz", date:"2012/2013 - 2015/2016",  text:"Intégration et 'Design'"},
      generalConf: {
        small: {
            media:'max-width: 800px',
            sizes:'',
            srcSetSize: '336w',
        },
        large: {
            media:'min-width: 801px',
            sizes:'',
            srcSetSize: '537w',
        },
        default: {
            animeNameAttr:'slide-bottom',
            animeTimeAttr:.2,
        }
    },
        images:[{
            small:  '/images/ONPCwamiz/accueil-ONPCwamiz.png',
            smallx2:'/images/ONPCwamiz/accueil-ONPCwamiz.png',
            large:  '/images/ONPCwamiz/accueil-ONPCwamiz.png',
            largex2:'/images/ONPCwamiz/accueil-ONPCwamiz.png',
            default:'/images/ONPCwamiz/accueil-ONPCwamiz.png',
            alt:''
        }],
      quote: "Diverses réalisations effectuées lors de mes alternances. Un peu de design et de l'intégration via CMS.",
      description: [
        {
          title: "Rôle", 
          text:["UI designer", "Intégrateur"]
        },
        {
          title: "Date", 
          text:["2012/2013", "2015/2016"]
        },
        {
          title: "Outils", 
          text:["PS","AI", "HTML", "CSS"]
        },
        {
          title: "Platforme", 
          text:["Site web", "Réseaux Sociaux", "CMS Oxatis"]
        },
        {
          title: "Missions", 
          text:["User Interface Design","Intégration","Créations / refontes / màj.", "Newsletters", "bannières"]
        }
      ]
    },
    freelance: {
      title: {name1: "Projets ", name2:"Freelance"},
      legend: {title: "Freelance", date:"2017/2019",  text:"Intégration, illustrations et Web Design"},
      generalConf: {
        small: {
            media:'max-width: 800px',
            sizes:'',
            srcSetSize: '336w',
        },
        large: {
            media:'min-width: 801px',
            sizes:'',
            srcSetSize: '537w',
        },
        default: {
            animeNameAttr:'slide-bottom',
            animeTimeAttr:.2,
        }
    },
        images:[{
            small:  '/images/Projet-free/free-accueil.png',
            smallx2:'/images/Projet-free/free-accueil.png',
            large:  '/images/Projet-free/free-accueil.png',
            largex2:'/images/Projet-free/free-accueil.png',
            default:'/images/Projet-free/free-accueil.png',
            alt:''
        }],
      quote: "Un petit peu de tout ! Wireframe, UI, illustration, intégration, création d'icônes...",
      description: [
        {
          title: "Rôle", 
          text:["UI designer", "Intégrateur"]
        },
        {
          title: "Date", 
          text:["2017/2019"]
        },
        {
          title: "Outils", 
          text:["PS / AI", "HTML/CSS(SAAS)/JS", "InDesign", "Sketch", "WP e-commerce"]
        },
        {
          title: "Platforme", 
          text:["Site web", "Responsive", "Print"]
        },
        {
          title: "Missions", 
          text:["User Interface Design","Intégration","Créations d'icônes", "Création print", "bannières", "Illustrations", "Logos & identités visuelles"]
        }
      ]
    },
  }

    export default WORKSHEADERDESC;
