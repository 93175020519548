const ESTORIESWORK = {
    wireframes : {
      title : "Wireframes",
      img: {
        generalConf: {
            small: {
                media:'max-width: 800px',
                sizes:'',
                srcSetSize: '170w',
            },
            large: {
                media:'min-width: 801px',
                sizes:'',
                srcSetSize: '250w',
            },
            default: {
                animeNameAttr:'slide-bottom',
                animeTimeAttr:.2,
            }
        },
        images:[{
            small:   '/images/estorie/Wireframes-estories/estorie1@2x.png',
            smallx2: '/images/estorie/Wireframes-estories/estorie1@2x.png',
            large:   '/images/estorie/Wireframes-estories/estorie1@2x.png',
            largex2: '/images/estorie/Wireframes-estories/estorie1@2x.png',
            default: '/images/estorie/Wireframes-estories/estorie1@2x.png',
            alt: '',
        },{
            small:   '/images/estorie/Wireframes-estories/estorie2@2x.png',
            smallx2: '/images/estorie/Wireframes-estories/estorie2@2x.png',
            large:   '/images/estorie/Wireframes-estories/estorie2@2x.png',
            largex2: '/images/estorie/Wireframes-estories/estorie2@2x.png',
            default: '/images/estorie/Wireframes-estories/estorie2@2x.png',
            alt: ''
        },{
            small:   '/images/estorie/Wireframes-estories/estorie3@2x.png',
            smallx2: '/images/estorie/Wireframes-estories/estorie3@2x.png',
            large:   '/images/estorie/Wireframes-estories/estorie3@2x.png',
            largex2: '/images/estorie/Wireframes-estories/estorie3@2x.png',
            default: '/images/estorie/Wireframes-estories/estorie3@2x.png',
            alt: ''
        },{
            small:   '/images/estorie/Wireframes-estories/estorie4@2x.png',
            smallx2: '/images/estorie/Wireframes-estories/estorie4@2x.png',
            large:   '/images/estorie/Wireframes-estories/estorie4@2x.png',
            largex2: '/images/estorie/Wireframes-estories/estorie4@2x.png',
            default: '/images/estorie/Wireframes-estories/estorie4@2x.png',
            alt: ''
        },{
            small:   '/images/estorie/Wireframes-estories/estorie5@2x.png',
            smallx2: '/images/estorie/Wireframes-estories/estorie5@2x.png',
            large:   '/images/estorie/Wireframes-estories/estorie5@2x.png',
            largex2: '/images/estorie/Wireframes-estories/estorie5@2x.png',
            default: '/images/estorie/Wireframes-estories/estorie5@2x.png',
            alt: ''
        },{
            small:   '/images/estorie/Wireframes-estories/estorie6@2x.png',
            smallx2: '/images/estorie/Wireframes-estories/estorie6@2x.png',
            large:   '/images/estorie/Wireframes-estories/estorie6@2x.png',
            largex2: '/images/estorie/Wireframes-estories/estorie6@2x.png',
            default: '/images/estorie/Wireframes-estories/estorie6@2x.png',
            alt: ''
        },{
            small:   '/images/estorie/Wireframes-estories/estorie7@2x.png',
            smallx2: '/images/estorie/Wireframes-estories/estorie7@2x.png',
            large:   '/images/estorie/Wireframes-estories/estorie7@2x.png',
            largex2: '/images/estorie/Wireframes-estories/estorie7@2x.png',
            default: '/images/estorie/Wireframes-estories/estorie7@2x.png',
            alt: ''
        },{
            small:   '/images/estorie/Wireframes-estories/estorie8@2x.png',
            smallx2: '/images/estorie/Wireframes-estories/estorie8@2x.png',
            large:   '/images/estorie/Wireframes-estories/estorie8@2x.png',
            largex2: '/images/estorie/Wireframes-estories/estorie8@2x.png',
            default: '/images/estorie/Wireframes-estories/estorie8@2x.png',
            alt: ''
        }
    ]
    }
    },
    icones : {
        title : "Icônes (recherches)",
        img: {
            generalConf: {
                small: {
                    media:'max-width: 800px',
                    sizes:'',
                    srcSetSize: '170w',
                },
                large: {
                    media:'min-width: 801px',
                    sizes:'',
                    srcSetSize: '250w',
                },
                default: {
                    animeNameAttr:'slide-bottom',
                    animeTimeAttr:.2,
                }
            },
            images:[{
                small:   '/images/estorie/estorie-perso-empty.png',
                smallx2: '/images/estorie/estorie-perso-empty.png',
                large:   '/images/estorie/estorie-perso-empty.png',
                largex2: '/images/estorie/estorie-perso-empty.png',
                default: '/images/estorie/estorie-perso-empty.png',
                alt: '',
            },{
                small:   '/images/estorie/icones-estories.png',
                smallx2: '/images/estorie/icones-estories.png',
                large:   '/images/estorie/icones-estories.png',
                largex2: '/images/estorie/icones-estories.png',
                default: '/images/estorie/icones-estories.png',
                alt: ''
            },{
                small:   '/images/estorie/estorie-perso-empty.png',
                smallx2: '/images/estorie/estorie-perso-empty.png',
                large:   '/images/estorie/estorie-perso-empty.png',
                largex2: '/images/estorie/estorie-perso-empty.png',
                default: '/images/estorie/estorie-perso-empty.png',
                alt: ''
            }
        ]
    }
    },
    gamification: {
        title : 'Gamification & évolution',
        img: {
            generalConf: {
                small: {
                    media:'max-width: 800px',
                    sizes:'',
                    srcSetSize: '175w',
                },
                large: {
                    media:'min-width: 801px',
                    sizes:'',
                    srcSetSize: '360w',
                },
                default: {
                    animeNameAttr:'slide-bottom',
                    animeTimeAttr:.2,
                }
            },
            images:[{
                small:   '/images/estorie/e-storie-game/estorie-game-1@2x.png',
                smallx2: '/images/estorie/e-storie-game/estorie-game-1@2x.png',
                large:   '/images/estorie/e-storie-game/estorie-game-1@2x.png',
                largex2: '/images/estorie/e-storie-game/estorie-game-1@2x.png',
                default: '/images/estorie/e-storie-game/estorie-game-1@2x.png',
                alt: '',
            },{
                small:   '/images/estorie/e-storie-game/estorie-game-2@2x.png',
                smallx2: '/images/estorie/e-storie-game/estorie-game-2@2x.png',
                large:   '/images/estorie/e-storie-game/estorie-game-2@2x.png',
                largex2: '/images/estorie/e-storie-game/estorie-game-2@2x.png',
                default: '/images/estorie/e-storie-game/estorie-game-2@2x.png',
                alt: ''
            },{
                small:   '/images/estorie/e-storie-game/estorie-game-3@2x.png',
                smallx2: '/images/estorie/e-storie-game/estorie-game-3@2x.png',
                large:   '/images/estorie/e-storie-game/estorie-game-3@2x.png',
                largex2: '/images/estorie/e-storie-game/estorie-game-3@2x.png',
                default: '/images/estorie/e-storie-game/estorie-game-3@2x.png',
                alt: '',
            }
        ]
    }
    }
}

export default ESTORIESWORK;