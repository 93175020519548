import React from 'react';
import PropTypes from 'prop-types';


const animations = [
    {name : "slide-top"},
    {name : "slide-bottom"},
    {name : "slide-left"},
    {name : "slide-right"},
    {name: "zoom"},
    {name: "ring-animation"}
]

class Intersection extends React.Component {
    constructor(props){
        super(props)
        this.refAnimate = React.createRef();
        this.options = {
            root: null,
            rootMargin: "0px",
            threshold: [.5, .9]
          };
        this.observer = new IntersectionObserver(this.handleIntersect, this.options);
    }

    initAnime(){
        const allAnime = Array.from(document.querySelectorAll("[data-animate]"))
        const allAnimeInside = Array.from(document.querySelectorAll("[data-animate-inside]"))
        allAnime.forEach((anime)=> {
            const attr = anime.getAttribute("data-animate");
            animations.forEach((value) => {
                if(attr === value.name) {
                    anime.classList.add(`${value.name}-init`);
                }
            })
        })
        if(allAnimeInside) {
            allAnimeInside.forEach((anime)=> {
                const attr = anime.getAttribute("data-animate-inside");
                animations.forEach((value) => {
                    if(attr === value.name) {
                        anime.classList.add(`${value.name}-init`);
                    }
                })
            })
        }
    }

    componentDidMount(){
        this.initAnime()
        this.observer.observe(this.refAnimate.current);
    }

    componentWillUnmount(){
        this.observer.unobserve(this.refAnimate.current);
    }

    handleIntersect(entries) {
        entries.forEach((entry) => {
            const allAnime = Array.from(entry.target.querySelectorAll("[data-animate]"))
            const allAnimeInside = Array.from(entry.target.querySelectorAll("[data-animate-inside]"))
            if (entry.intersectionRatio > .5) {
              // if is visible on screen
                allAnime.forEach((anime)=> {
                    const attr = anime.getAttribute("data-animate");
                    const delay = anime.getAttribute("data-animated-delay");
                    const time = anime.getAttribute("data-animated-time");
                    animations.forEach((value) => {
                        if(attr === value.name) {
                            anime.classList.remove(`${value.name}-init`);
                            anime.classList.add(value.name);
                        }
                        if(delay) {
                            anime.classList.add(`animation-delay`);
                        }
                        if(time) {
                            anime.style.animationDelay=`${time}`;
                            anime.style.transitionDelay=`${time}`;
                        }
                    })
                })
            } else {
              // if is invisible on screen
            }
            if(allAnimeInside) {
                if (entry.intersectionRatio > .9) {
                // if is visible on screen
                    allAnimeInside.forEach((anime)=> {
                        const attr = anime.getAttribute("data-animate-inside");
                        const delay = anime.getAttribute("data-animated-delay");
                        animations.forEach((value) => {
                            if(attr === value.name) {
                                anime.classList.remove(`${value.name}-init`);
                                anime.classList.add(value.name);
                            }
                            if(delay) {
                                anime.classList.add(`animation-delay`);
                            }
                        })
                    })
                } else {
                // if is invisible on screen
              }
            }
        });
      }

    render() {
    const {classDiv} = this.props

        return (
            <div className={classDiv} ref={this.refAnimate}>
                {this.props.children}
            </div>
        )
    }
}

Intersection.defaultProps = {
    classDiv: 'default-observer'
};

Intersection.propTypes = {
    idDiv: PropTypes.string,
    classDiv: PropTypes.string
  };

export default Intersection