const WORKLOGOSRC = {
    scientifil : {
        display: true,
        generalConf: {
            small: {
                media:'max-width: 800px',
                sizes:'',
                srcSetSize: '336w',
            },
            large: {
                media:'min-width: 801px',
                sizes:'',
                srcSetSize: '537w',
            },
            default: {
                animeNameAttr:'slide-bottom',
                animeTimeAttr:.2,
            }
        },
        images:[{
            small: '/images/scientifil/logo-scientifil.png',
            smallx2:'/images/scientifil/logo-scientifil.png',
            large: '/images/scientifil/logo-scientifil.png',
            largex2:'/images/scientifil/logo-scientifil.png',
            default:'/images/scientifil/logo-scientifil.png',
            alt:''
        }
        ]
    },
    downtrigger : {
        display: true,
        generalConf: {
            small: {
                media:'max-width: 800px',
                sizes:'',
                srcSetSize: '336w',
            },
            large: {
                media:'min-width: 801px',
                sizes:'',
                srcSetSize: '537w',
            },
            default: {
                animeNameAttr:'slide-bottom',
                animeTimeAttr:.2,
            }
        },
        images:[{
            small: '/images/down_trigger/downtrigger-1.png',
            smallx2: '/images/down_trigger/downtrigger-1.png',
            large: '/images/down_trigger/downtrigger-1.png',
            largex2: '/images/down_trigger/downtrigger-1.png',
            default: '/images/down_trigger/downtrigger-1.png',
            alt:''
        }
        ]
    },
    estories : {
        display: true,
        generalConf: {
            small: {
                media:'max-width: 800px',
                sizes:'',
                srcSetSize: '336w',
            },
            large: {
                media:'min-width: 801px',
                sizes:'',
                srcSetSize: '537w',
            },
            default: {
                animeNameAttr:'slide-bottom',
                animeTimeAttr:.2,
            }
        },
        images:[{
            small: '/images/estorie/e-stories-logo.png',
            smallx2: '/images/estorie/e-stories-logo.png',
            large: '/images/estorie/e-stories-logo.png',
            largex2: '/images/estorie/e-stories-logo.png',
            default: '/images/estorie/e-stories-logo.png',
            alt:'e-stories logo'
        }
        ]
    },
    orange : {
        display: false,
        generalConf: {
            small: {
                media:'max-width: 800px',
                sizes:'',
                srcSetSize: '336w',
            },
            large: {
                media:'min-width: 801px',
                sizes:'',
                srcSetSize: '537w',
            },
            default: {
                animeNameAttr:'slide-bottom',
                animeTimeAttr:.2,
            }
        },
        images:[{
            small:   '',
            smallx2: '',
            large:   '',
            largex2: '',
            default: '',
            alt: ''
        }
        ]
    },
    meowbio : {
        display: true,
        generalConf: {
            small: {
                media:'max-width: 800px',
                sizes:'',
                srcSetSize: '773w',
            },
            large: {
                media:'min-width: 801px',
                sizes:'',
                srcSetSize: '1546w',
            },
            default: {
                animeNameAttr:'slide-bottom',
                animeTimeAttr:.2,
            }
        },
        images:[{
            small:  '/images/meowbio/logo-meow-large.png',
            smallx2:'/images/meowbio/logo-meow-large.png',
            large:  '/images/meowbio/logo-meow-large.png',
            largex2:'/images/meowbio/logo-meow-xlarge.png',
            default:'/images/meowbio/logo-meow-large.png',
            alt:''
        }
        ]
    },
    illustrations : {
        display: false,
        generalConf: {
            small: {
                media:'max-width: 800px',
                sizes:'',
                srcSetSize: '773w',
            },
            large: {
                media:'min-width: 801px',
                sizes:'',
                srcSetSize: '1546w',
            },
            default: {
                animeNameAttr:'slide-bottom',
                animeTimeAttr:.2,
            }
        },
        images:[{
            small:  '',
            smallx2:'',
            large:  '',
            largex2:'',
            default:'',
            alt:''
        }
        ]
    },
    entreprises : {
        display: false,
        generalConf: {
            small: {
                media:'max-width: 800px',
                sizes:'',
                srcSetSize: '773w',
            },
            large: {
                media:'min-width: 801px',
                sizes:'',
                srcSetSize: '1546w',
            },
            default: {
                animeNameAttr:'slide-bottom',
                animeTimeAttr:.2,
            }
        },
        images:[{
            small:  '',
            smallx2:'',
            large:  '',
            largex2:'',
            default:'',
            alt:''
        }
        ]
    },
    freelance : {
        display: false,
        generalConf: {
            small: {
                media:'max-width: 800px',
                sizes:'',
                srcSetSize: '773w',
            },
            large: {
                media:'min-width: 801px',
                sizes:'',
                srcSetSize: '1546w',
            },
            default: {
                animeNameAttr:'slide-bottom',
                animeTimeAttr:.2,
            }
        },
        images:[{
            small:  '',
            smallx2:'',
            large:  '',
            largex2:'',
            default:'',
            alt:''
        }
        ]
    },
}

export default WORKLOGOSRC;