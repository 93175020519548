import React from 'react'
import { SmoothScroll } from '../SmoothScroll/SmoothScroll';
// import PropTypes from 'prop-types';

class ToTop extends React.Component {
    constructor(props){
        super(props)
        this.handleClick = this.handleClick.bind(this)
        this.handleScroll = this.handleScroll.bind(this)
        this.refToTop = React.createRef();
        this.state = {
            isdisplayed: false,
            prevScrollY : 0
        };
    }

    componentDidMount(){
        window.addEventListener('scroll', this.handleScroll)
    }

    handleClick(e){
        SmoothScroll(e, 'app')
    }

    handleScroll(){
        if (window.scrollY > 100 && this.state.prevScrollY < window.scrollY) {
            this.setState({
                isdisplayed: true
            })
            }else {
                this.setState({
                    isdisplayed: false
                })
            }
        this.setState({
            prevScrollY: window.scrollY
        })
      }

    render(){
        const {isdisplayed} = this.state

        return (
            <div className='toTop' id='toTop'>
            {isdisplayed && 
                <div ref={this.refToTop} onClick={this.handleClick} className='toTop__wrapper'>
                    <i className='icon-arrow toTop__wrapper__icon'></i>
                </div>
            }
            </div>
        )
    }
}

// ToTop.defaultProps = {
// };

// ToTop.propTypes = {
// };


export default ToTop;