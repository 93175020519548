import React from 'react';
import { BrowserRouter, Route, Switch, Redirect} from "react-router-dom";
import Accueil from './pages/Accueil/Accueil';
import ErrorsGen from './pages/ErrorsGen/ErrorsGen';
import Overlay from './component/General/Overlay/Overlay';
import Footer from './component/General/Footer/Footer';
import Totop from './component/General/ToTop/ToTop';
import ThemeContext from './ThemeContext'
import MenuContext from './MenuContext'
import Contact from './component/General/Contact/Contact'
import WorksGeneral from './pages/Works/general/worksGeneral'
import ScientifilPart3 from './pages/Works/Scientifil/ScientifilPart3/ScientifilPart3';
import DowntriggerPart3 from './pages/Works/downtrigger/downtriggerPart3/downtriggerPart3'
import EstoriesPart3 from './pages/Works/estories/estoriesPart3/EstoriesPart3'
import OrangePart3 from './pages/Works/orange/orangePart3/OrangePart3'
import MeowbioPart3 from './pages/Works/meowbio/meowbioPart3/MeowbioPart3'
import IllustrationsPart3 from './pages/Works/illustrations/illustrationsPart3/IllustrationsPart3'
import EntreprisesPart3 from './pages/Works/entreprises/entreprisesPart3/EntreprisesPart3'
import FreelancePart3 from './pages/Works/freelance/freelancePart3/FreelancePart3'

import GENERALSLIDERCONTENT from './content/general/generalSliderContent';
import GENERALCONTACTFORM from './content/general/generalContactForm';
import GENERALFOOTER from './content/general/generalFooter'

class App extends React.Component {
  constructor(props){
    super(props)
    this.handleClickTheme = () => {
      this.setState(state => ({
        isThemeDark: !state.isThemeDark
      }))
    }
    this.updateToggleMenu=() => {
      this.setState(state => ({
        isToggleOn: !state.isToggleOn
    }));
  }

    this.state = {
      isToggleOn: false,
      isThemeDark: false,
      handleClickTheme: this.handleClickTheme,
      updateToggleMenu: this.updateToggleMenu
    }
  }

  componentDidMount() {
    const element = document.getElementById("loading-page");
    element.parentNode.removeChild(element);
 }

  render() {
    const {isThemeDark, handleClickTheme, updateToggleMenu, isToggleOn} = this.state
    return(
      <ThemeContext.Provider value={{theme: isThemeDark, funcTheme: handleClickTheme}}>
        <MenuContext.Provider value={{toggle: isToggleOn, funcMenu: updateToggleMenu}}>
        <div className={`App ${isThemeDark ? 'theme--dark' : 'theme--light'}`} id='app'>
        <BrowserRouter>
          <Switch>
              <Route exact path='/' render={(props) => <Accueil {...props} generalSliderContent={GENERALSLIDERCONTENT}/>}/>
              <Route exact path='/realisations-scientifil' render={(props) => <WorksGeneral key={`scientifil`} {...props} generalSliderContent={GENERALSLIDERCONTENT} workName="scientifil"> <ScientifilPart3></ScientifilPart3></WorksGeneral>}></Route>
              <Route exact path='/realisations-downtrigger' render={(props) => <WorksGeneral key={`downtrigger`} {...props} generalSliderContent={GENERALSLIDERCONTENT} workName="downtrigger"> <DowntriggerPart3></DowntriggerPart3> </WorksGeneral>}></Route>
              <Route exact path='/realisations-estories' render={(props) => <WorksGeneral key={`estories`} {...props} generalSliderContent={GENERALSLIDERCONTENT} workName="estories"> <EstoriesPart3></EstoriesPart3> </WorksGeneral>}></Route>
              <Route exact path='/realisations-orange' render={(props) => <WorksGeneral key={`orange`} {...props} generalSliderContent={GENERALSLIDERCONTENT} workName="orange"><OrangePart3></OrangePart3></WorksGeneral>}></Route>
              <Route exact path='/realisations-meowbio' render={(props) => <WorksGeneral key={`meowbio`} {...props} generalSliderContent={GENERALSLIDERCONTENT} workName="meowbio"><MeowbioPart3></MeowbioPart3></WorksGeneral>}></Route>
              <Route exact path='/realisations-illu' render={(props) => <WorksGeneral key={`illustrations`} {...props} generalSliderContent={GENERALSLIDERCONTENT} workName="illustrations"><IllustrationsPart3></IllustrationsPart3></WorksGeneral>}></Route>
              <Route exact path='/realisations-free' render={(props) => <WorksGeneral key={`freelance`} {...props} generalSliderContent={GENERALSLIDERCONTENT} workName="freelance"><FreelancePart3></FreelancePart3></WorksGeneral>}></Route>
              <Route exact path='/realisations-entreprises' render={(props) => <WorksGeneral key={`work`} {...props} generalSliderContent={GENERALSLIDERCONTENT} workName="entreprises"><EntreprisesPart3></EntreprisesPart3></WorksGeneral>}></Route>
              {/* <Route exact path='/404' render={(props) => <ErrorsGen  {...props} generalSliderContent={GENERALSLIDERCONTENT}/>}/> */}
              <Route component={ErrorsGen} />
               <Redirect to="/404" />
          </Switch>
          <Contact contactFormProp={GENERALCONTACTFORM}></Contact>
          <Footer footerProp={GENERALFOOTER}></Footer>
          <Totop></Totop>
          <Overlay></Overlay>
        </BrowserRouter>
        </div>
        </MenuContext.Provider>
        </ThemeContext.Provider>
    )
  }
}

export default App;
