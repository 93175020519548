
import React from 'react';
import PropTypes from 'prop-types';
import Intersection from '../../../component/General/Intersection/Intersection';

const ImgSrcset = ((props) => {
    if (props.noAnime) {
        return (
        <picture className={`default-picture__wrapper__content__picture ${props.classLocation2 ? props.classLocation2+'__picture' : ''} ${props.classLocation}__picture`}>
        <source media={`(${props.conf.small.media})`} sizes={`${props.conf.small.size}`}
        srcSet={`${props.item.small} ${props.conf.small.srcSetSize}, ${props.item.smallx2} 2x`}></source>
        <source media={`(${props.conf.large.media})`} sizes={`${props.conf.large.size}`}
        srcSet={`${props.item.large} ${props.conf.large.srcSetSize}, ${props.item.largex2} 2x`}></source>
        <img alt={props.item.alt} src={props.item.default}/>
        </picture>
        )
    } else {
        return (
            <Intersection classDiv={`default-picture__wrapper__content ${props.classLocation} ${props.classLocation2}`}>
            <picture className={`default-picture__wrapper__content__picture ${props.classLocation2 ? props.classLocation2+'__picture' : ''} ${props.classLocation}__picture`} data-animate={props.conf.default.animeNameAttr } data-animated-time={`${props.indexNum * props.conf.default.animeTimeAttr}s`}>
                <source media={`(${props.conf.small.media})`} sizes={`${props.conf.small.size}`}
                srcSet={`${props.item.small} ${props.conf.small.srcSetSize}, ${props.item.smallx2} 2x`}></source>
                <source media={`(${props.conf.large.media})`} sizes={`${props.conf.large.size}`}
                srcSet={`${props.item.large} ${props.conf.large.srcSetSize}, ${props.item.largex2} 2x`}></source>
                <img data-animate={props.specifiqueAnime ? props.specifiqueAnime : props.conf.default.animeNameAttr } data-animated-time={`${props.indexNum * props.conf.default.animeTimeAttr}s`} alt={props.item.alt} src={props.item.default}/>
            </picture>
         </Intersection>
        )
    }
})

ImgSrcset.defaultProps = {
    noAnime: false,
    classLocation:'default-img__wrapper__content',
    specifiqueAnime: '',
    classLocation2:'',
    indexNum: 0,
    conf: {
        small: {
            media:'max-width: 800px',
            sizes:'',
            srcSetSize: '1x',
        },
        large: {
            media:'min-width: 801px',
            sizes:'',
            srcSetSize: '1x',
        },
        default: {
            animeNameAttr:'slide-bottom',
            animeTimeAttr:'0',
        }
    },
    item:[{
        small: 'images/scientifil/scentifil-screen-1.png',
        smallx2:'images/scientifil/scentifil-screen-1.png',
        large: 'images/scientifil/scentifil-screen-1.png',
        largex2:'images/scientifil/scentifil-screen-1.png',
        default:'images/scientifil/scentifil-screen-1.png',
        alt:''
    }]
}

PropTypes.ImgSrcset = {
    noAnime: PropTypes.bool,
    indexNum:PropTypes.number,
    specifiqueAnime: PropTypes.string,
    conf:PropTypes.shape({
        small: {
            media:PropTypes.string,
            sizes:PropTypes.string,
            srcSetSize: PropTypes.string,
        },
        large: {
            media:PropTypes.string,
            sizes:PropTypes.string,
            srcSetSize: PropTypes.string,
        },
        default: {
            animeNameAttr:PropTypes.string,
            animeTimeAttr:PropTypes.number
        }
    }),
    item:PropTypes.shape({
            small: {
                srcSet: PropTypes.string,
                srcSetx2: PropTypes.string
            },
            large: {
                srcSet: PropTypes.string,
                srcSetx2:PropTypes.string
            },
            default: {
                alt:PropTypes.string,
                src:PropTypes.string
            }
        }
    )
}

export default ImgSrcset

