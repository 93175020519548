import React from 'react';
// import PropTypes from 'prop-types';
import Button from '../Button/Button'
import Intersection from '../Intersection/Intersection'

const Cv = ({cvProp}) => {
        return (
        <Intersection classDiv="CV">
            <div className="CV__content" data-animate="slide-bottom">
                <h3 className="CV__content__text">{cvProp.txt}</h3>
                <Button isPrimary={true} target={true} hrefLink={cvProp.linkPDF} isAnchor={false} isButton={false} classButtonName="CV__content__link" idButtonName="button-cv" inText={cvProp.callToAction}></Button>
            </div>
        </Intersection>
        
)}


export default Cv

// Cv.defaultProps = {

// };

// Cv.propTypes = {

// };

