import React from 'react';
import PropTypes from 'prop-types'


const CLAVIER = [
    ["A","Z","E","R","T","Y","U","I","O","P"],
    ["Q","S","D", "F","G","H","J","K","L","M"],
    ["W","X","C","V","B","N"]]

const Clavier = ({onClick, checked, boardTitle}) => 
(
    <div className="pendu__wrapper__clavier">
        <p>{boardTitle}</p>
        {CLAVIER.map((element, index) => {
            return (
                <div key={index} className="pendu__wrapper__clavier__ligne">
                    {element.map((letter, index) => {
                        return <p className={`pendu__wrapper__clavier__ligne__letter${checked.includes(letter) ? '--hidden': '--visible'}`} key={index} onClick={(e)=>onClick(e)}>{letter}</p>
                    })
                }
                </div>
            )
        })}

    </div>
)

Clavier.propTypes ={
    onClick: PropTypes.func.isRequired,
    checked: PropTypes.array.isRequired
}

export default Clavier