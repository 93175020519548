import React from 'react';
import ImgSrcset from '../../../../component/General/imgSrcset/imgSrcset'
import Intersection from '../../../../component/General/Intersection/Intersection'

import FREELANCEWORK from '../../../../content/works/freelance/freelanceWorkPart3'

const FreelancePart3 = () => {
    return (
        <section className={`freelance-img`} id={`freelance-img`}>
             <Intersection>
                 <div className={`freelance-img__wrapper`}>
                     <div className={`freelance-img__wrapper__content`}>
                         <ImgSrcset
                             noAnime={true}
                             conf={FREELANCEWORK.work.img.generalConf} 
                             specifiqueAnime='-'
                             item={FREELANCEWORK.work.img.images[0]} 
                             classLocation={`freelance-img__wrapper__content__picture`} />
                     </div>
                 </div>
             </Intersection>
         </section>
    )
}

export default FreelancePart3;