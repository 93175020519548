const PENDUGAME = {
    title : 'Vous jouez au pendu...',
    subTitle : 'Score',
    boardTitle: 'Votre Clavier virtuel :',
    tabScoreRes: {
        player: 'Joueur',
        score: 'Score'
    },
    configGame: {
        words: [
            "RIEN", 
            "LESS IS MORE", 
            "WEBDESIGN", 
            "LEARN", 
            "GET ELEMENT BY ID", 
            "NOT FOUND", 
            "HARDER BETTER FASTER STRONGER", 
            "OOPS I DID IT AGAIN", 
            "QUE DIRIEZ-VOUS D'UNE PARTIE DE GWYNT", 
            "Y A QUOI LA-DEDANS KING KONG"
        ],
        nbTurn: 15,
        replayButText: 'Try Again',
        result: {
            loose: {
                score: 'Sans commentaires... =)',
                isFind: 'Perdu',
                word: 'le mot était :'
            },
            win: {
                isFind: 'Mot trouvé',
                text: "Toutes mes félicitations, tu as juste le droit de rejouer..."
            }
        },
        playerName: 'vous',
        sysPts: {
            good:{
                pts: 10,
                txt: 'pts si bonne lettre'
            },
            bad: {
                pts: 5,
                txt: 'pts si mauvaise lettre'
            },
            already: {
                pts: 10,
                txt: 'pts si on clique de nouveau sur la même lettre présente dans la phrase (Cliquez pas n\'importe ou)'
            },
            alreadyNot: {
                pts: 15,
                txt: 'pts si on clique sur une lettre grisée même pas présente (Vous le faites exprès ?)'
            }
        }
    }
}

export default PENDUGAME