import React from 'react';
import PropTypes from 'prop-types';
import ThemeButtonIllu from '../ThemeButton/ThemeButtonIllu'

class ThemeButton extends React.Component {
    // constructor(props){
    // super(props)

    // }
    render() {
        const {classLocation} = this.props
        return (
            <div className={`default-theme-button ${classLocation}`}>
                <div className={`default-theme-button__button ${classLocation}__button`} >
                    <ThemeButtonIllu classLocation={`${classLocation}__button__illu`} ></ThemeButtonIllu>
                </div>
            </div>
        )
    }
}

 ThemeButton.defaultProps = {
     classLocation: 'default-theme-button'
 };

ThemeButton.propTypes = {
    classLocation: PropTypes.string,
};

export default ThemeButton;