const SCIENTIFILWORKORAGNES = {
    foie : {
      title : "le foie",
      text : "Le foie produit une substance : la bile. <br> Elle facilite la digestion des graisses. <br> Le foie trie aussi ce qui doit passer dans le sang."
    },
    vesBi : {
        title : "La vésicule biliaire",
        text : "La vésicule biliaire est un organe qui stocke la bile jusqu'à ce que le corps l'utilise pour la digestion." 
      },
    pancreas:{
      title : "Le Pancréas",
      text : "Le Pancréas fabrique une substance digestive (le suc pancréatique) qu’il déverse dans l’intestin grêle."
    },
    salive:{
      title : "La salive",
      text : "La salive permet de dissoudre les aliments, qui se transforment en boulettes. <br> La salive est produite par les glandes salivaires, situées sous la langue et derrière la gorge."
    }
}

export default SCIENTIFILWORKORAGNES;