import React from 'react'
import PropTypes from 'prop-types'


const ResultsPlayers = ({entries, scoreTitle}) => (
    <table className="pendu__wrapper__results__player">
      <tbody>
        <tr className="pendu__wrapper__results__player__title">
            <th className="pendu__wrapper__results__player__title__joueur">{scoreTitle.player}</th>
            <th className="pendu__wrapper__results__player__title__score">{scoreTitle.score}</th>
        </tr>
        {entries.map(({id, score, player}) => (
            <tr key={id}>
            <td className="pendu__wrapper__results__player__name">{player}</td>
            <td className="pendu__wrapper__results__player__score">{score}</td>
            </tr>
            )
        )}
      </tbody>
    </table>
  )

  ResultsPlayers.propTypes = {
    entries: PropTypes.arrayOf(
        PropTypes.shape({
            id:PropTypes.number.isRequired,
            // score:PropTypes.number.isRequired,
            player:PropTypes.string.isRequired,
        })
    ).isRequired
}
  
  export default ResultsPlayers