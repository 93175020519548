

const WORKSITEMSLIDERPIC = {
    scientifil : {
        display: true,
        generalConf: {
            small: {
                media:'max-width: 800px',
                sizes:'',
                srcSetSize: '140w',
            },
            large: {
                media:'min-width: 801px',
                sizes:'',
                srcSetSize: '270w',
            },
            default: {
                animeNameAttr:'slide-bottom',
                animeTimeAttr:0,
                slidesVisible: 3,
                slideToScroll:1,
                refSlide: 'scientifil'
            }
        },
        images:[{
            small: '/images/scientifil/phil/phil-6-small.png',
            smallx2:'/images/scientifil/phil/phil-6-smallx2.png',
            large: '/images/scientifil/phil/phil-6-large.png',
            largex2:'/images/scientifil/phil/phil-6-largex2.png',
            default:'/images/scientifil/phil/phil-6-large.png',
            alt:''
        },{
            small: '/images/scientifil/phil/phil-1-small.png',
            smallx2:'/images/scientifil/phil/phil-1-smallx2.png',
            large: '/images/scientifil/phil/phil-1-large.png',
            largex2:'/images/scientifil/phil/phil-1-largex2.png',
            default:'/images/scientifil/phil/phil-1-large.png',
            alt:''
        },{
            small: '/images/scientifil/phil/phil-2-small.png',
            smallx2:'/images/scientifil/phil/phil-2-smallx2.png',
            large: '/images/scientifil/phil/phil-2-large.png',
            largex2:'/images/scientifil/phil/phil-2-largex2.png',
            default:'/images/scientifil/phil/phil-2-large.png',
            alt:''
        },{
            small: '/images/scientifil/phil/phil-3-small.png',
            smallx2:'/images/scientifil/phil/phil-3-smallx2.png',
            large: '/images/scientifil/phil/phil-3-large.png',
            largex2:'/images/scientifil/phil/phil-3-largex2.png',
            default:'/images/scientifil/phil/phil-3-large.png',
            alt:''
        },{
            small: '/images/scientifil/phil/phil-4-small.png',
            smallx2:'/images/scientifil/phil/phil-4-smallx2.png',
            large: '/images/scientifil/phil/phil-4-large.png',
            largex2:'/images/scientifil/phil/phil-4-largex2.png',
            default:'/images/scientifil/phil/phil-4-large.png',
            alt:''
        },{
            small: '/images/scientifil/phil/phil-5-small.png',
            smallx2:'/images/scientifil/phil/phil-5-smallx2.png',
            large: '/images/scientifil/phil/phil-5-large.png',
            largex2:'/images/scientifil/phil/phil-5-largex2.png',
            default:'/images/scientifil/phil/phil-5-large.png',
            alt:''
        }
        ]
    },
    downtrigger : {
        display: true,
        generalConf: {
            small: {
                media:'max-width: 800px',
                sizes:'',
                srcSetSize: '140w',
            },
            large: {
                media:'min-width: 801px',
                sizes:'',
                srcSetSize: '270w',
            },
            default: {
                animeNameAttr:'slide-bottom',
                animeTimeAttr:0,
                slidesVisible: 2,
                slideToScroll:1,
                refSlide: 'downtrigger'
            }
        },
        images:[{
            small: '/images/down_trigger/downtrigger-progress-1.png',
            smallx2:'/images/down_trigger/downtrigger-progress-1.png',
            large: '/images/down_trigger/downtrigger-progress-1.png',
            largex2:'/images/down_trigger/downtrigger-progress-1.png',
            default:'/images/down_trigger/downtrigger-progress-1.png',
            alt:''
        },{
            small: '/images/down_trigger/downtrigger-progress-2.png',
            smallx2:'/images/down_trigger/downtrigger-progress-2.png',
            large: '/images/down_trigger/downtrigger-progress-2.png',
            largex2:'/images/down_trigger/downtrigger-progress-2.png',
            default:'/images/down_trigger/downtrigger-progress-2.png',
            alt:''
        },{
            small: '/images/down_trigger/downtrigger-progress-3.png',
            smallx2:'/images/down_trigger/downtrigger-progress-3.png',
            large: '/images/down_trigger/downtrigger-progress-3.png',
            largex2:'/images/down_trigger/downtrigger-progress-3.png',
            default:'/images/down_trigger/downtrigger-progress-3.png',
            alt:''
        },{
            small: '/images/down_trigger/downtrigger-progress-4.png',
            smallx2:'/images/down_trigger/downtrigger-progress-4.png',
            large: '/images/down_trigger/downtrigger-progress-4.png',
            largex2:'/images/down_trigger/downtrigger-progress-4.png',
            default:'/images/down_trigger/downtrigger-progress-4.png',
            alt:''
        },{
            small: '/images/down_trigger/downtrigger-progress-5.png',
            smallx2:'/images/down_trigger/downtrigger-progress-5.png',
            large: '/images/down_trigger/downtrigger-progress-5.png',
            largex2:'/images/down_trigger/downtrigger-progress-5.png',
            default:'/images/down_trigger/downtrigger-progress-5.png',
            alt:''
        }
        ]
    },
    estories : {
        display: true,
        generalConf: {
            small: {
                media:'max-width: 800px',
                sizes:'',
                srcSetSize: '140w',
            },
            large: {
                media:'min-width: 801px',
                sizes:'',
                srcSetSize: '270w',
            },
            default: {
                animeNameAttr:'slide-bottom',
                animeTimeAttr:0,
                slidesVisible: 1,
                slideToScroll:1,
                refSlide: 'downtrigger'
            }
        },
        images:[{
            small: '/images/estorie/sliderthumb-estories/personnagesall-perso-large.png',
            smallx2:'/images/estorie/sliderthumb-estories/personnagesall-perso-large.png',
            large: '/images/estorie/sliderthumb-estories/personnagesall-perso-large.png',
            largex2: '/images/estorie/sliderthumb-estories/personnagesall-perso-xlarge.png',
            default:'/images/estorie/sliderthumb-estories/personnagesall-perso-large.png',
            alt:''
        },{
            small:  '/images/estorie/sliderthumb-estories/personnagesmedusa-1-large.png',
            smallx2:'/images/estorie/sliderthumb-estories/personnagesmedusa-1-large.png',
            large:  '/images/estorie/sliderthumb-estories/personnagesmedusa-1-large.png',
            largex2:'/images/estorie/sliderthumb-estories/personnagesmedusa-1-xlarge.png',
            default:'/images/estorie/sliderthumb-estories/personnagesmedusa-1-large.png',
            alt:''
        },{
            small:  '/images/estorie/sliderthumb-estories/personnagesmedusa-2-large.png',
            smallx2:'/images/estorie/sliderthumb-estories/personnagesmedusa-2-large.png',
            large:  '/images/estorie/sliderthumb-estories/personnagesmedusa-2-large.png',
            largex2:'/images/estorie/sliderthumb-estories/personnagesmedusa-2-xlarge.png',
            default:'/images/estorie/sliderthumb-estories/personnagesmedusa-2-large.png',
            alt:''
        },{
            small:  '/images/estorie/sliderthumb-estories/personnageswendigo-2-large_1.png',
            smallx2:'/images/estorie/sliderthumb-estories/personnageswendigo-2-large_1.png',
            large:  '/images/estorie/sliderthumb-estories/personnageswendigo-2-large_1.png',
            largex2:'/images/estorie/sliderthumb-estories/personnageswendigo-2-xlarge_1.png',
            default:'/images/estorie/sliderthumb-estories/personnageswendigo-2-large_1.png',
            alt:''
        },{
            small:  '/images/estorie/sliderthumb-estories/personnageswendigo-2-large_2.png',
            smallx2:'/images/estorie/sliderthumb-estories/personnageswendigo-2-large_2.png',
            large:  '/images/estorie/sliderthumb-estories/personnageswendigo-2-large_2.png',
            largex2:'/images/estorie/sliderthumb-estories/personnageswendigo-2-xlarge_2.png',
            default:'/images/estorie/sliderthumb-estories/personnageswendigo-2-large_2.png',
            alt:''
        },{
            small:  '/images/estorie/sliderthumb-estories/personnagesnekkomata-1-large.png',
            smallx2:'/images/estorie/sliderthumb-estories/personnagesnekkomata-1-large.png',
            large:  '/images/estorie/sliderthumb-estories/personnagesnekkomata-1-large.png',
            largex2:'/images/estorie/sliderthumb-estories/personnagesnekkomata-1-xlarge.png',
            default:'/images/estorie/sliderthumb-estories/personnagesnekkomata-1-large.png',
            alt:''
        },{
            small:  '/images/estorie/sliderthumb-estories/personnagesnekkomata-2-large.png',
            smallx2:'/images/estorie/sliderthumb-estories/personnagesnekkomata-2-large.png',
            large:  '/images/estorie/sliderthumb-estories/personnagesnekkomata-2-large.png',
            largex2:'/images/estorie/sliderthumb-estories/personnagesnekkomata-2-xlarge.png',
            default:'/images/estorie/sliderthumb-estories/personnagesnekkomata-2-large.png',
            alt:''
        }
        ]
    },
    orange : {
        display: true,
        generalConf: {
            small: {
                media:'max-width: 800px',
                sizes:'',
                srcSetSize: '140w',
            },
            large: {
                media:'min-width: 801px',
                sizes:'',
                srcSetSize: '270w',
            },
            default: {
                animeNameAttr:'slide-bottom',
                animeTimeAttr:0,
                slidesVisible: 1,
                slideToScroll:1,
                refSlide: 'orange'
            }
        },
        images:[{
            small:  '/images/orange/add-api/orange-add-api-screen-large-1.png',
            smallx2:'/images/orange/add-api/orange-add-api-screen-large-1.png',
            large:  '/images/orange/add-api/orange-add-api-screen-large-1.png',
            largex2:'/images/orange/add-api/orange-add-api-screen-xlarge-1.png',
            default:'/images/orange/add-api/orange-add-api-screen-large-1.png',
            alt:''
        },{
            small:  '/images/orange/add-api/orange-add-api-screen-large-2.png',
            smallx2:'/images/orange/add-api/orange-add-api-screen-large-2.png',
            large:  '/images/orange/add-api/orange-add-api-screen-large-2.png',
            largex2:'/images/orange/add-api/orange-add-api-screen-xlarge-2.png',
            default:'/images/orange/add-api/orange-add-api-screen-large-2.png',
            alt:''
        },{
            small:  '/images/orange/add-api/orange-add-api-screen-large-3.png',
            smallx2:'/images/orange/add-api/orange-add-api-screen-large-3.png',
            large:  '/images/orange/add-api/orange-add-api-screen-large-3.png',
            largex2:'/images/orange/add-api/orange-add-api-screen-xlarge-3.png',
            default:'/images/orange/add-api/orange-add-api-screen-large-3.png',
            alt:''
        },{
            small:  '/images/orange/add-api/orange-add-api-screen-large-4.png',
            smallx2:'/images/orange/add-api/orange-add-api-screen-large-4.png',
            large:  '/images/orange/add-api/orange-add-api-screen-large-4.png',
            largex2:'/images/orange/add-api/orange-add-api-screen-xlarge-4.png',
            default:'/images/orange/add-api/orange-add-api-screen-large-4.png',
            alt:''
        },{
            small:  '/images/orange/add-api/orange-add-api-screen-large-5.png',
            smallx2:'/images/orange/add-api/orange-add-api-screen-large-5.png',
            large:  '/images/orange/add-api/orange-add-api-screen-large-5.png',
            largex2:'/images/orange/add-api/orange-add-api-screen-xlarge-5.png',
            default:'/images/orange/add-api/orange-add-api-screen-large-5.png',
            alt:''
        },
    ]
    },
    meowbio : {
        display: true,
        generalConf: {
            small: {
                media:'max-width: 800px',
                sizes:'',
                srcSetSize: '650w',
            },
            large: {
                media:'min-width: 801px',
                sizes:'',
                srcSetSize: '1300w',
            },
            default: {
                animeNameAttr:'slide-bottom',
                animeTimeAttr:0,
                slidesVisible: 1,
                slideToScroll:1,
                refSlide: 'meowbio'
            }
        },
        images:[{
            small:  '/images/meowbio/popup/meowbio-popup-1-large.png',
            smallx2:'/images/meowbio/popup/meowbio-popup-1-large.png',
            large:  '/images/meowbio/popup/meowbio-popup-1-large.png',
            largex2:'/images/meowbio/popup/meowbio-popup-1-xlarge.png',
            default:'/images/meowbio/popup/meowbio-popup-1-large.png',
            alt:''
        },{
            small:  '/images/meowbio/popup/meowbio-popup-2-large.png',
            smallx2:'/images/meowbio/popup/meowbio-popup-2-large.png',
            large:  '/images/meowbio/popup/meowbio-popup-2-large.png',
            largex2:'/images/meowbio/popup/meowbio-popup-2-xlarge.png',
            default:'/images/meowbio/popup/meowbio-popup-2-large.png',
            alt:''
        },{
            small:  '/images/meowbio/popup/meowbio-popup-3-large.png',
            smallx2:'/images/meowbio/popup/meowbio-popup-3-large.png',
            large:  '/images/meowbio/popup/meowbio-popup-3-large.png',
            largex2:'/images/meowbio/popup/meowbio-popup-3-xlarge.png',
            default:'/images/meowbio/popup/meowbio-popup-3-large.png',
            alt:''
        }
    ]
    },
    illustrations : {
        display: false,
        generalConf: {
            small: {
                media:'max-width: 800px',
                sizes:'',
                srcSetSize: '650w',
            },
            large: {
                media:'min-width: 801px',
                sizes:'',
                srcSetSize: '1300w',
            },
            default: {
                animeNameAttr:'slide-bottom',
                animeTimeAttr:0,
                slidesVisible: 1,
                slideToScroll:1,
                refSlide: 'meowbio'
            }
        },
        images:[{
            small:  '',
            smallx2:'',
            large:  '',
            largex2:'',
            default:'',
            alt:''
        }
    ]
    },
    entreprises : {
        display: false,
        generalConf: {
            small: {
                media:'max-width: 800px',
                sizes:'',
                srcSetSize: '650w',
            },
            large: {
                media:'min-width: 801px',
                sizes:'',
                srcSetSize: '1300w',
            },
            default: {
                animeNameAttr:'slide-bottom',
                animeTimeAttr:0,
                slidesVisible: 1,
                slideToScroll:1,
                refSlide: 'meowbio'
            }
        },
        images:[{
            small:  '',
            smallx2:'',
            large:  '',
            largex2:'',
            default:'',
            alt:''
        }
    ]
    },
    freelance : {
        display: false,
        generalConf: {
            small: {
                media:'max-width: 800px',
                sizes:'',
                srcSetSize: '650w',
            },
            large: {
                media:'min-width: 801px',
                sizes:'',
                srcSetSize: '1300w',
            },
            default: {
                animeNameAttr:'slide-bottom',
                animeTimeAttr:0,
                slidesVisible: 1,
                slideToScroll:1,
                refSlide: 'meowbio'
            }
        },
        images:[{
            small:  '',
            smallx2:'',
            large:  '',
            largex2:'',
            default:'',
            alt:''
        }
    ]
    }
}

export default WORKSITEMSLIDERPIC;

