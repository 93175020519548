const DOWNTRIGGERWORK = {
    album : {
      title : "Album Final",
      img: {
        generalConf: {
            small: {
                media:'max-width: 800px',
                sizes:'',
                srcSetSize: '336w',
            },
            large: {
                media:'min-width: 801px',
                sizes:'',
                srcSetSize: '537w',
            },
            default: {
                animeNameAttr:'slide-bottom',
                animeTimeAttr:.2,
            }
        },
        images:[{
            small: '/images/down_trigger/presentation-downtrigger-cd.jpg',
            smallx2: '/images/down_trigger/presentation-downtrigger-cd.jpg',
            large: '/images/down_trigger/presentation-downtrigger-cd.jpg',
            largex2: '/images/down_trigger/presentation-downtrigger-cd.jpg',
            default: '/images/down_trigger/presentation-downtrigger-cd.jpg',
            alt:'/images/down_trigger/presentation-downtrigger-cd.jpg'
        }]
    }
    },
    video : {
        title : "Vidéo promotion",
        video : "/images/down_trigger/DownTigger-clip-mp4.mp4" 
    }
}

export default DOWNTRIGGERWORK;