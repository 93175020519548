import React from 'react';
import Slider from '../../component/General/Slider/Slider';
import Header from '../../component/General/Header/Header';
import Error from '../../component/General/404-error/Error'
import Pendu from '../../component/General/Pendu/Pendu'

import ERRORMENULINKS from '../../content/error/errorMenuLinks'
import GENERALSLIDERCONTENT from '../../content/general/generalSliderContent';



class ErrorsGen extends React.Component {
    constructor(props){
        super(props)
        this.refSlideError = React.createRef();
    }

    render(){
        return (
            <div>
                <Header classLocation="header-error" menuLink={ERRORMENULINKS} displayfirst={false}></Header>
                <Error></Error>
                <Pendu></Pendu>
                <Slider idName='works' generalSliderContent={GENERALSLIDERCONTENT.liWorks} refSlide={this.refSlideError} attrwork='scientifil' titleLevel='h3' sliderTitle={GENERALSLIDERCONTENT.titlePage} sliderNameClass="works__slider"></Slider>
            </div>
        )
    }
}

export default ErrorsGen;