const ORANGEWORK = {
    screen : {
      title : "Exemple d'écrans",
      img: {
        generalConf: {
            small: {
                media:'max-width: 800px',
                sizes:'',
                srcSetSize: '450w',
            },
            large: {
                media:'min-width: 801px',
                sizes:'',
                srcSetSize: '1200w',
            },
            default: {
                animeNameAttr:'slide-bottom',
                animeTimeAttr:.2,
            }
        },
        images:[{
            small:   '/images/orange/orange-screen-api.png',
            smallx2: '/images/orange/orange-screen-api.png',
            large:   '/images/orange/orange-screen-api.png',
            largex2: '/images/orange/orange-screen-api.png',
            default: '/images/orange/orange-screen-api.png',
            alt: '',
        },{
            small:   '/images/orange/orange-screen-apidashboard.png',
            smallx2: '/images/orange/orange-screen-apidashboard.png',
            large:   '/images/orange/orange-screen-apidashboard.png',
            largex2: '/images/orange/orange-screen-apidashboard.png',
            default: '/images/orange/orange-screen-apidashboard.png',
            alt: ''
        }
    ]
    }
    },
    errors : {
        title : "Gestion des erreurs",
        img: {
            generalConf: {
                small: {
                    media:'max-width: 800px',
                    sizes:'',
                    srcSetSize: '450w',
                },
                large: {
                    media:'min-width: 801px',
                    sizes:'',
                    srcSetSize: '800w',
                },
                default: {
                    animeNameAttr:'slide-bottom',
                    animeTimeAttr:.2,
                }
            },
            images:[{
                small:   '/images/orange/orange-screen-3-error.gif',
                smallx2: '/images/orange/orange-screen-3-error.gif',
                large:   '/images/orange/orange-screen-3-error.gif',
                largex2: '/images/orange/orange-screen-3-error.gif',
                default: '/images/orange/orange-screen-3-error.gif',
                alt: '',
            }
        ]
    }
    }
}

export default ORANGEWORK;