import React from 'react';
import PropTypes from 'prop-types'

class Input extends React.Component {
    render() {
        const {id, patternReg, placeholder, locationName, required, type, label, value, error, minLen, maxLen} = this.props 
        return (
            <div className={`inputText ${locationName}`}>
                <label aria-label={label} htmlFor={id}>
                    <input autoComplete="true" {...(minLen ? {minLength: minLen} : {})} {...(maxLen ? {maxLength: maxLen} : {})} {...(patternReg ? {pattern: patternReg} : {})} onChange={this.props.handleChange} required={required} type={type} id={id} name={id} placeholder={placeholder} className={`inputText__${id} ${locationName}__${id}`} value={value}></input>
                    {error && <div className={`inputText__error ${locationName}__error`}>{error}</div>}
                </label>
            </div>
        )
    }
}

Input.propTypes = {
    id: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    locationName: PropTypes.string,
    required: PropTypes.bool,
    type: PropTypes.string,
    label: PropTypes.string.isRequired,
    error: PropTypes.string,
    value: PropTypes.string,
    patternReg: PropTypes.string,
    minLen: PropTypes.number,
    maxLen: PropTypes.number,
}

Input.defaultProps = {
    placeholder: "Ecrivez ici ",
    required : true,
    locationName: 'inputText',
    type: 'text',
    error: "Champ incorrect",
    patternReg: '',
    label: "input",
    maxLen: 0,
    minLen: 0
};

export default Input;
