import React from 'react';
import PropTypes from 'prop-types';
import Intersection from '../Intersection/Intersection'
import ImgSrcset from '../../General/imgSrcset/imgSrcset'

class Slider extends React.Component {
    constructor(props) {
        super(props)
        this.changeSlideBullet = this.changeSlideBullet.bind(this)
        this.slidesToScroll = this.slidesToScroll.bind(this)
        this.slidesVisible = this.slidesVisible.bind(this)
        this.OnWindowResize = this.OnWindowResize.bind(this)
        this.setStyle = this.setStyle.bind(this)
        this.slideRight = this.slideRight.bind(this)
        this.slideLeft = this.slideLeft.bind(this)
        this.startDrag = this.startDrag.bind(this)
        this.drag = this.drag.bind(this)
        this.endDrag = this.endDrag.bind(this)
        this.state = {
            displayArrow : {left: false, right: true},
            isMobile:false,
            slidesToScroll:  window.innerWidth < 800 ? 1 : this.props.slideToScroll,
            slidesVisible: window.innerWidth < 800 ? 1 : this.props.slidesVisible, 
            indexactive: 0,
            touchesX: 0,
            lastTranslate: 0,
            items: 0,
        };
    }

    setStyle(items){
        // creer le ration des elements : nombre d'element total / le nombre a afficher (multiplier par 100 pour le pourcentage)
        const container = this.props.refSlideThumb.current.querySelector("[data-slidethumb]")
        let ratio = items.length / this.state.slidesVisible
        container.style.width = (ratio * 100) + '%'
        // console.log((this.state.slidesVisible / ratio * 100))

        items.forEach(item => item.style.width = ((1 / this.state.slidesVisible / ratio * 100) +'%'))
    }

    slidesToScroll(){
        return this.state.isMobile ? 1 : this.props.slideToScroll
    }

    slidesVisible(){
        return this.state.isMobile ? 1 : this.props.slidesVisible
      }

    OnWindowResize(){
        let mobile = window.innerWidth < 800;
        if (mobile !== this.state.isMobile){
            this.setState({
                isMobile: mobile
          })
        }
        const slideToScrollNum = this.slidesToScroll()
        const slideVisibleNum = this.slidesVisible()
        this.setState({
            slidesToScroll: slideToScrollNum,
            slidesVisible: slideVisibleNum
        })
        this.setStyle(this.state.items)
    }

    componentDidMount(){
        const slide = this.props.refSlideThumb.current
        const arrayItems = Array.from(slide.querySelector("[data-slidethumb]").querySelectorAll('li'))
        this.setState({
            items: arrayItems
        })
        window.addEventListener('resize', this.OnWindowResize);
        this.setStyle(arrayItems)
    }

    componentWillUnmount(){
        window.removeEventListener('resize', this.OnWindowResize);
    }


    startDrag(e){
        if(e.touches.length === 1) {
            e = e.touches[0];
        }
        this.setState({ touchesX: e.screenX});
    }
    drag(e){
        let point = e.touches ? e.touches[0] : e
        this.setState({ lastTranslate: point.screenX - this.state.touchesX});
    }
    endDrag(e){
        if(this.state.lastTranslate < -10){
            this.slideRight()
            this.setState({ lastTranslate:0});

        }else if(this.state.lastTranslate > 10){
            this.slideLeft()
            this.setState({ lastTranslate:0});
        }else {
            return
        }
    }
    changeSlideBullet(index){
        const slide = this.props.refSlideThumb.current
        const arrayItems = Array.from(slide.querySelector("[data-slidethumb]").querySelectorAll('li'))
        const container = this.props.refSlideThumb.current.querySelector("[data-slidethumb]")
        if(index >= (arrayItems.length - this.state.slidesVisible) / this.state.slidesToScroll){ 
            this.setState({
                displayArrow : {left: true, right: false},
            });
        } else if (index === 0) {
            this.setState({
                displayArrow : {left: false, right: true},
            });
        } else {
            this.setState({
                displayArrow : {left: true, right: true},
            });
        }
        if(index >= arrayItems.length -  this.state.slidesVisible + 1){
            this.setState({ indexactive: 0
            });
            index = 0
        } else if(index < 0){
            this.setState({ indexactive: arrayItems.length - this.state.slidesVisible,
            });
            index= arrayItems.length - this.state.slidesVisible
        }else {
            this.setState({ indexactive: index
        });
        }
        let translateX = -100 / this.state.items.length * index * this.state.slidesToScroll
        container.style.transform = 'translate3d('+ translateX +'%,0,0)'

    }

    slideLeft(){
        this.setState({ indexactive: this.state.indexactive-1});
        this.changeSlideBullet(this.state.indexactive-1)
    }
    slideRight(){
        this.setState({ indexactive: this.state.indexactive+1});
        this.changeSlideBullet(this.state.indexactive+1)
    }

    render(){
        const {sliderThumbNameClass, refSlideThumb, workItemSliderPic, idName} = this.props;
        const {indexactive, items, slidesToScroll, displayArrow, slidesVisible} = this.state;
        let tabBullet = []
        for(let i=slidesVisible-slidesToScroll; i<items.length;  i=i+slidesToScroll) {
            tabBullet.push(i)
        }

        return(
            <Intersection>
                <div id={idName} ref={refSlideThumb} className={`default-sliderthumb ${sliderThumbNameClass}`}>
                    <div data-animate="slide-bottom" className={`default-sliderthumb__wrapper ${sliderThumbNameClass}__wrapper`} onTouchEnd={this.endDrag} onTouchStart={this.startDrag} onTouchMove={this.drag}>
                        <ul data-slidethumb="true" id='tabSlide' className={`default-sliderthumb__wrapper__items ${sliderThumbNameClass}__wrapper__items`}>
                            {workItemSliderPic.images.map((item, index) => {
                                return (<li key={index}  className={`default-sliderthumb__wrapper__items__item${indexactive === index ? "--active" : ""} ${sliderThumbNameClass}__wrapper__items__item${indexactive === index ? "--active" : ""}`}>
                                   <ImgSrcset indexNum={index} conf={workItemSliderPic.generalConf} item={item} classLocation={`${sliderThumbNameClass}__wrapper__items__item`} />
                                    {/* <img src={item[`img${index+1}`]} alt=''></img> */}
                                </li>)
                            })}
                        </ul>
                        <div className={`default-sliderthumb__wrapper__arrows ${sliderThumbNameClass}__wrapper__arrows`}>
                            {displayArrow.left && <div onClick={this.slideLeft} className={`default-sliderthumb__wrapper__arrows__left ${sliderThumbNameClass}__wrapper__arrows__left`}>{"<"}</div>}
                            {displayArrow.right && <div onClick={this.slideRight} className={`default-sliderthumb__wrapper__arrows__right ${sliderThumbNameClass}__wrapper__arrows__right`}>{">"}</div> }
                        </div>
                        <nav className={`default-sliderthumb__wrapper__slider-nav ${sliderThumbNameClass}__wrapper__slider-nav`}>
                            {tabBullet.map((item, index) => {
                                return (<div key={index} onClick={()=> {this.changeSlideBullet(index)}} data-bullet-name={`bullet-${index+1}`} className={`default-sliderthumb__wrapper__slider-nav__bullet${indexactive === index ? "--active" : ""} ${sliderThumbNameClass}__wrapper__slider-nav__bullet${indexactive === index ? "--active" : ""}`}></div>)
                            })}
                        </nav>
                    </div>
                </div>
            </Intersection>
        )
    }
}

export default Slider;

Slider.defaultProps = {
    sliderThumbNameClass: 'default-sliderthumb',
    idName:'default-sliderthumb',
    attrwork:'',
    slideToScroll: 1,
    slidesVisible: 3
};

Slider.propTypes = {
    sliderThumbNameClass: PropTypes.string,
    idName: PropTypes.string,
    attrwork: PropTypes.string,
    workItemSliderPic: PropTypes.object.isRequired,
    refSlideThumb: PropTypes.object,
    slideToScroll: PropTypes.number,
    slidesVisible: PropTypes.number
    // refSlideThumb: PropTypes.oneOfType([
        // PropTypes.func, 
        // PropTypes.shape({ current: PropTypes.instanceOf(Slider) })])
  };
