import React from 'react';
import PropTypes from 'prop-types';
import Slider from '../../../component/General/Slider/Slider';
import SliderThumb from '../../../component/General/SliderThumb/SliderThumb';
import Header from '../../../component/General/Header/Header';
import HeaderWork from '../../../component/Works/HeaderWork/HeaderWork';
import PanelWorks from '../../../component/Works/PanelWorks/PanelWorks'
// import ScientifilPart3 from './ScientifilPart3/ScientifilPart3'
import LogoBlock from '../../../component/Works/LogoBlock/LogoBlock'

import WORKSIMGPANEL from '../../../content/works/worksImgPanel'
import WORKLOGOSRC from '../../../content/works/worksLogoSrc'
import WOKSDESCTEXTLOGO from '../../../content/works/worksDescTextLogo'
import WORKSITEMSLIDERPIC from '../../../content/works/worksItemSliderPic'
import WORKSMENULINKS from '../../../content/works/worksMenuLinks'
import WORKSHEADERDESC from '../../../content/works/worksHeaderDesc'

class WorksGeneral extends React.Component {
    constructor(props){
        super(props)
        this.refSlideWork = React.createRef();
        this.refSlideThumb = React.createRef();
    }


    render(){
        const {workName, generalSliderContent} = this.props
        return (
            <div id={`${workName}`}>
                <Header classLocation={`header-${workName}`} menuLink={WORKSMENULINKS[workName]} displayfirst={false}></Header>
                <HeaderWork workName={workName} classLocation={`header-work-${workName}`} workHeaderDesc={WORKSHEADERDESC[workName]}></HeaderWork>
                {WORKSIMGPANEL[workName].display &&
                    <PanelWorks imgPanel={WORKSIMGPANEL[workName]} locationName={`${workName}-panel`}></PanelWorks>
                }
                {WOKSDESCTEXTLOGO[workName].displayGen && 
                    <LogoBlock locationName={`${workName}-logo`} title={`Logotype ${WOKSDESCTEXTLOGO[workName].displayTxt ? '& couleurs' : ''}`} imgLogo={WORKLOGOSRC[workName]} descriptionLogo={WOKSDESCTEXTLOGO[workName]}></LogoBlock>
                }
                {WORKSITEMSLIDERPIC[workName].display &&
                    <SliderThumb 
                        slideToScroll={WORKSITEMSLIDERPIC[workName].generalConf.default.slidesToScroll} 
                        slidesVisible={WORKSITEMSLIDERPIC[workName].generalConf.default.slidesVisible} 
                        workItemSliderPic={WORKSITEMSLIDERPIC[workName]} 
                        sliderThumbNameClass={`sliderthumb-${workName}-mascotte`} 
                        refSlideThumb={this.refSlideThumb} 
                        idName={`sliderthumb__${workName}-mascotte`}>
                    </SliderThumb>
                }
                {this.props.children}
                <Slider idName='works' generalSliderContent={generalSliderContent.liWorks} refSlide={this.refSlideWork} attrwork='scientifil' titleLevel='h3' sliderTitle={generalSliderContent.titlePage} sliderNameClass="works__slider"></Slider>
            </div>
        )
    }
}

WorksGeneral.defaultProps = {
};

WorksGeneral.propTypes = {
    workName: PropTypes.string.isRequired,
    generalSliderContent: PropTypes.object.isRequired
};

export default WorksGeneral;
